/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateNoteInput = {
    userId: string;
    noteId: string;
    mediaId?: string | null;
    content?: string | null;
    hash?: string | null;
    public?: boolean | null;
    draft?: boolean | null;
    password?: string | null;
    summary?: string | null;
    title?: string | null;
    trash?: boolean | null;
    created?: string | null;
    updated?: string | null;
    noteCode?: string | null;
    file?: string | null;
    folderId?: string | null;
    flashcardSetId?: string | null;
    importType?: ImportType | null;
    subject?: string | null;
    verified?: boolean | null;
    classPublic?: boolean | null;
    schoolId?: string | null;
    grade?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    topic?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
    isAI?: boolean | null;
    aiTool?: string | null;
    deadline?: number | null;
};

export enum ImportType {
    WEB_SCRAPING = "WEB_SCRAPING",
    IMAGE = "IMAGE",
    PDF = "PDF",
    PPT = "PPT",
    WORD = "WORD",
    TXT = "TXT",
    DRIVE_PDF = "DRIVE_PDF",
    DRIVE_PPT = "DRIVE_PPT",
    DRIVE_WORD = "DRIVE_WORD",
    DRIVE_TXT = "DRIVE_TXT",
}

export type EmojiIconInput = {
    emoji?: string | null;
    skinTone?: string | null;
};

export type Note = {
    __typename?: "Note";
    userId: string;
    noteId: string;
    content?: string | null;
    hash?: string | null;
    public?: boolean | null;
    draft?: boolean | null;
    password?: string | null;
    summary?: string | null;
    title?: string | null;
    trash?: boolean | null;
    created?: string | null;
    updated?: string | null;
    noteCode?: string | null;
    file?: string | null;
    folderId?: string | null;
    flashcardSetId?: string | null;
    mediaId?: string | null;
    importType?: ImportType | null;
    subject?: string | null;
    views?: number | null;
    verified?: boolean | null;
    classPublic?: boolean | null;
    schoolId?: string | null;
    grade?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    topic?: string | null;
    icon?: EmojiIcon | null;
    rating?: number | null;
    ratingCount?: number | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
    lock?: boolean | null;
    partial?: boolean | null;
    xpTracked?: string | null;
    isAI?: boolean | null;
    aiTool?: string | null;
    autocompleteSettings?: AutocompleteSettings | null;
    neverPublic?: boolean | null;
    deadline?: number | null;
};

export type EmojiIcon = {
    __typename?: "EmojiIcon";
    emoji?: string | null;
    skinTone?: string | null;
};

export type AutocompleteSettings = {
    __typename?: "AutocompleteSettings";
    language?: Language | null;
    disabled?: boolean | null;
    btnDisabled?: boolean | null;
    speed?: number | null;
    length?: string | null;
    grade?: string | null;
    format?: string | null;
    style?: string | null;
};

export enum Language {
    SPANISH = "SPANISH",
    ENGLISH = "ENGLISH",
    FRENCH = "FRENCH",
    GERMAN = "GERMAN",
    ITALIAN = "ITALIAN",
    LATIN = "LATIN",
    RUSSIAN = "RUSSIAN",
    CHEMISTRY = "CHEMISTRY",
    CHINESE = "CHINESE",
    CHINESE_TRADITIONAL = "CHINESE_TRADITIONAL",
    CHINESE_PINYIN = "CHINESE_PINYIN",
    MATH = "MATH",
    JAPANESE = "JAPANESE",
    JAPANESE_ROMAJI = "JAPANESE_ROMAJI",
    KOREAN = "KOREAN",
    IRISH = "IRISH",
    DUTCH = "DUTCH",
    VIETNAMESE = "VIETNAMESE",
    AFRIKAANS = "AFRIKAANS",
    AKAN = "AKAN",
    AKKADIAN = "AKKADIAN",
    ALBANIAN = "ALBANIAN",
    AMHARIC = "AMHARIC",
    ANGLO_SAXON = "ANGLO_SAXON",
    ARABIC = "ARABIC",
    ARMENIAN = "ARMENIAN",
    AZERBAIJAN = "AZERBAIJAN",
    BASQUE = "BASQUE",
    BELARUSIAN = "BELARUSIAN",
    BENGALI = "BENGALI",
    BIHARI = "BIHARI",
    BOSNIAN = "BOSNIAN",
    BRETON = "BRETON",
    BULGARIAN = "BULGARIAN",
    BURMESE = "BURMESE",
    CATALAN = "CATALAN",
    CEBUANO = "CEBUANO",
    CHAMORRA = "CHAMORRA",
    CHEROKEE = "CHEROKEE",
    CHOCTAW = "CHOCTAW",
    COPTIC = "COPTIC",
    CORSICAN = "CORSICAN",
    CROATIAN = "CROATIAN",
    CZECH = "CZECH",
    DANISH = "DANISH",
    DENE = "DENE",
    DHIVEHI = "DHIVEHI",
    DHOLUO = "DHOLUO",
    ESPERANTO = "ESPERANTO",
    ESTONIAN = "ESTONIAN",
    FAROESE = "FAROESE",
    FILIPINO = "FILIPINO",
    FINNISH = "FINNISH",
    FINMISH = "FINMISH",
    FULA = "FULA",
    GAELIC = "GAELIC",
    GALICIAN = "GALICIAN",
    GEORGIAN = "GEORGIAN",
    GOTHIC = "GOTHIC",
    GREEK = "GREEK",
    GUARANI = "GUARANI",
    GUJARATI = "GUJARATI",
    HAIDA = "HAIDA",
    HAITIAN_CREOLE = "HAITIAN_CREOLE",
    HAUSA = "HAUSA",
    HAWAIIAN = "HAWAIIAN",
    HEBREW = "HEBREW",
    HINDI = "HINDI",
    HMONG = "HMONG",
    HUNGARIAN = "HUNGARIAN",
    ICELANDIC = "ICELANDIC",
    IGBO = "IGBO",
    ILONGGO = "ILONGGO",
    INDONESIAN = "INDONESIAN",
    INTERNATIONAL_PHONETIC_ALPHABET = "INTERNATIONAL_PHONETIC_ALPHABET",
    INUKTITUT = "INUKTITUT",
    JAVANESE = "JAVANESE",
    JOLA_FONYI = "JOLA_FONYI",
    KANNADA = "KANNADA",
    KAZAKH = "KAZAKH",
    KHMER = "KHMER",
    KIKONGA = "KIKONGA",
    KINYARWANDA = "KINYARWANDA",
    KIOWA = "KIOWA",
    KONKOW = "KONKOW",
    KURDISH = "KURDISH",
    KYRGYZ = "KYRGYZ",
    LAKOTA = "LAKOTA",
    LAO = "LAO",
    LATVIAN = "LATVIAN",
    LENAPE = "LENAPE",
    LINGALA = "LINGALA",
    LITHUANIAN = "LITHUANIAN",
    LUBA_KASAI = "LUBA_KASAI",
    LUXEMBOURGISH = "LUXEMBOURGISH",
    MACEDONIAN = "MACEDONIAN",
    MALAGASY = "MALAGASY",
    MALAY = "MALAY",
    MALAYALAM = "MALAYALAM",
    MALTESE = "MALTESE",
    MANDINKA = "MANDINKA",
    MAORI = "MAORI",
    MAORI_COOK_ISLAND = "MAORI_COOK_ISLAND",
    MARATHI = "MARATHI",
    MARSHALLESE = "MARSHALLESE",
    MOHAWK = "MOHAWK",
    MONGOLIAN = "MONGOLIAN",
    NAHUATL = "NAHUATL",
    NAVAJO = "NAVAJO",
    NEPALI = "NEPALI",
    NORWEGIAN = "NORWEGIAN",
    OCCITAN = "OCCITAN",
    OJIBWE = "OJIBWE",
    ORIYA = "ORIYA",
    OROMO = "OROMO",
    OTHER = "OTHER",
    PALI = "PALI",
    PASHTO = "PASHTO",
    PERSIAN = "PERSIAN",
    POLISH = "POLISH",
    PORTUGUESE = "PORTUGUESE",
    PUNJABI = "PUNJABI",
    QUECHUA = "QUECHUA",
    ROMANSH = "ROMANSH",
    ROMANIAN = "ROMANIAN",
    SAMOAN = "SAMOAN",
    SANSKRIT = "SANSKRIT",
    SENECA = "SENECA",
    SERBIAN = "SERBIAN",
    SGAW_KAREN = "SGAW_KAREN",
    SHAN = "SHAN",
    SINDHI = "SINDHI",
    SINHALESE = "SINHALESE",
    SLOVAK = "SLOVAK",
    SLOVENIAN = "SLOVENIAN",
    SOMALI = "SOMALI",
    SUNDANESE = "SUNDANESE",
    SWAHILI = "SWAHILI",
    SWEDISH = "SWEDISH",
    SYRIAC = "SYRIAC",
    TAGALOG = "TAGALOG",
    TAJIK = "TAJIK",
    TAMIL = "TAMIL",
    TATAR = "TATAR",
    TELUGU = "TELUGU",
    TETUM = "TETUM",
    THAI = "THAI",
    TIBETAN = "TIBETAN",
    TIGRINYA = "TIGRINYA",
    TOHONA_O_ODHAM = "TOHONA_O_ODHAM",
    TONGA = "TONGA",
    TRIKI = "TRIKI",
    TURKISH = "TURKISH",
    TUVAN = "TUVAN",
    UIGHUR = "UIGHUR",
    UKRAINIAN = "UKRAINIAN",
    URDU = "URDU",
    UZBEK = "UZBEK",
    WELSH = "WELSH",
    FRISIAN = "FRISIAN",
    WINNEBAGO = "WINNEBAGO",
    WOLOF = "WOLOF",
    XHOSA = "XHOSA",
    YIDDISH = "YIDDISH",
    YORUBA = "YORUBA",
    ZULU = "ZULU",
}

export type NoteContext = {
    __typename?: "NoteContext";
    item: Note;
    user: UserDetails;
    badges: Array<BadgeProgress>;
    dailyActivity: DailyActivity;
    level?: number | null;
};

export type UserDetails = {
    __typename?: "UserDetails";
    Email: string;
    org?: string | null;
    accountType?: AccountType | null;
    ID?: string | null;
    elID?: string | null;
    clID?: string | null;
    isSSOChecked?: boolean | null;
    timeZone?: string | null;
    referral?: string | null;
    Name?: string | null;
    pictureUrl?: string | null;
    username?: string | null;
    views?: number | null;
    birthday?: string | null;
    phone?: string | null;
    hideSensitiveInfo?: boolean | null;
    noteSidebarWidth?: number | null;
    created?: number | null;
    lastLogIn?: number | null;
    lastNotified?: number | null;
    answerSide?: FlashcardSide | null;
    numFollowers?: number | null;
    numFollowing?: number | null;
    ref_v2?: string | null;
    refFrom_v2?: string | null;
    refList_v2?: Array<string> | null;
    verified?: boolean | null;
    bio?: string | null;
    socials?: Socials | null;
    cover?: string | null;
    schoolId?: string | null;
    grade?: string | null;
    rating?: number | null;
    ratingCount?: number | null;
    customerId?: string | null;
    subscriptions?: Array<StripeConnection> | null;
    alerts?: string | null;
    subscriptionType?: SubscriptionType | null;
    stripeVerifyTime?: number | null;
    stripeTrialTime?: number | null;
    cancelPromoTime?: number | null;
    deleteReq?: boolean | null;
    ai?: UserAIStats | null;
    profileColor?: string | null;
    privacyAck?: boolean | null;
    blogger?: boolean | null;
    examHistory?: string | null;
    usernameChange?: number | null;
    signInType?: SignInType | null;
    invites?: number | null;
    classes?: ClassInfo | null;
    migKey?: string | null;
    signInTypes?: Array<SignInType> | null;
    orgPlanType?: SubscriptionType | null;
    orgPlanCreated?: number | null;
    lastOrgPlanUpdated?: number | null;
    favTools?: Array<string> | null;
    lastStreakDate?: string | null;
    xpSyncDate?: string | null;
    xp?: number | null;
    level?: number | null;
    streak?: number | null;
    longPauseEnd?: string | null;
    coins?: number | null;
    featuredBadges?: Array<string> | null;
    records?: GamifyRecords | null;
    inventory?: GamifyInventory | null;
    gameBlock?: string | null;
    lastAdWatch?: string | null;
    lastTimeZoneChange?: number | null;
    notifSettings?: NotificationSettings | null;
    stoppedNotifications?: boolean | null;
    inactiveUser?: boolean | null;
    promoCodes?: Array<PromoCode> | null;
    sentPromotions?: string | null;
    autocompleteSettings?: AutocompleteSettings | null;
    orgRequests?: Array<UpgradeEvent> | null;
    orgReqTime?: string | null;
    emailsSent?: number | null;
    lastSent?: number | null;
    isSidebarExpanded?: boolean | null;
};

export enum AccountType {
    Teacher = "Teacher",
    Student = "Student",
    ORG_Admin = "ORG_Admin",
}

export enum FlashcardSide {
    TERM = "TERM",
    DEFINITION = "DEFINITION",
    BOTH = "BOTH",
}

export type Socials = {
    __typename?: "Socials";
    ig?: string | null;
    twitter?: string | null;
    tiktok?: string | null;
    in?: string | null;
    link?: string | null;
    spotify?: string | null;
};

export type StripeConnection = {
    __typename?: "StripeConnection";
    subscriptionId: string;
    customerId: string;
    priceId: string;
    productId: string;
    created?: number | null;
    current_period_end?: number | null;
    cancel_at_period_end?: boolean | null;
    resumes_at?: number | null;
    interval?: string | null;
    status?: string | null;
    platform?: PlatformEnum | null;
};

export enum PlatformEnum {
    WEB = "WEB",
    IOS = "IOS",
    ANDROID = "ANDROID",
    PAYPAL = "PAYPAL",
}

export enum SubscriptionType {
    BASIC = "BASIC",
    SUPPORTER = "SUPPORTER",
    PRO = "PRO",
    LIMITLESS = "LIMITLESS",
    TEACHER_PRO = "TEACHER_PRO",
    TEACHER_LIMITLESS = "TEACHER_LIMITLESS",
}

export type UserAIStats = {
    __typename?: "UserAIStats";
    curPrompts?: number | null;
    curPeriodEnd?: number | null;
    curTokens?: number | null;
    videos?: number | null;
    vNotes?: number | null;
    vSets?: number | null;
    mins?: number | null;
    pdfs?: number | null;
    pNotes?: number | null;
    pSets?: number | null;
    pages?: number | null;
    nTests?: number | null;
    nSets?: number | null;
    chats?: number | null;
    explain?: number | null;
    frq?: number | null;
    cost?: number | null;
    mSets?: number | null;
    mNotes?: number | null;
    tools?: number | null;
    assignments?: number | null;
    scans?: number | null;
    aiV?: number | null;
    history?: Array<UserAIStatsHistory | null> | null;
};

export type UserAIStatsHistory = {
    __typename?: "UserAIStatsHistory";
    periodEnd?: number | null;
    prompts?: number | null;
    tokens?: number | null;
    videos?: number | null;
    vNotes?: number | null;
    vSets?: number | null;
    mins?: number | null;
    pdfs?: number | null;
    pNotes?: number | null;
    pSets?: number | null;
    pages?: number | null;
    nTests?: number | null;
    nSets?: number | null;
    chats?: number | null;
    explain?: number | null;
    frq?: number | null;
    cost?: number | null;
    mSets?: number | null;
    mNotes?: number | null;
    tools?: number | null;
    assignments?: number | null;
    scans?: number | null;
    aiV?: number | null;
};

export enum SignInType {
    GOOGLE = "GOOGLE",
    MICROSOFT = "MICROSOFT",
    FACEBOOK = "FACEBOOK",
    APPLE = "APPLE",
    EMAIL = "EMAIL",
    EDLINK = "EDLINK",
    CLEVER = "CLEVER",
    CLASSLINK = "CLASSLINK",
}

export type ClassInfo = {
    __typename?: "ClassInfo";
    enrolled: Array<ClassEnrollment>;
    teacher: Array<string>;
    invited: Array<ClassEnrollment>;
};

export type ClassEnrollment = {
    __typename?: "ClassEnrollment";
    teacherId: string;
    classId: string;
    role: ClassRole;
    sections: Array<string>;
};

export enum ClassRole {
    OWNER = "OWNER",
    CO_OWNER = "CO_OWNER",
    CONTRIBUTOR = "CONTRIBUTOR",
    VIEWER = "VIEWER",
    STUDENT = "STUDENT",
}

export type GamifyRecords = {
    __typename?: "GamifyRecords";
    xpEarned: string;
    longestStreak: string;
    badgesEarned: string;
    coinsEarned: string;
};

export type GamifyInventory = {
    __typename?: "GamifyInventory";
    freezes: number;
    revivals: number;
    pauses: number;
    icon: StoreProductEnum;
    features: Array<StoreProductEnum>;
    gamifySubExpiry?: number | null;
    gamifySubStoreProduct?: StoreProductEnum | null;
};

export enum StoreProductEnum {
    FOLDER_COLORS = "FOLDER_COLORS",
    PROFILE_COLOR = "PROFILE_COLOR",
    ICON_DEFAULT = "ICON_DEFAULT",
    ICON_SUNGLASSES = "ICON_SUNGLASSES",
    ICON_WINKING = "ICON_WINKING",
    ICON_SARCASTIC = "ICON_SARCASTIC",
    ICON_FLIRTY = "ICON_FLIRTY",
    ICON_DISAPPOINTED = "ICON_DISAPPOINTED",
    ICON_TIRED = "ICON_TIRED",
    ICON_SUIT = "ICON_SUIT",
    ICON_SCREAM = "ICON_SCREAM",
    ICON_MINDBLOWN = "ICON_MINDBLOWN",
    ICON_MENACE = "ICON_MENACE",
    ICON_STRESSED = "ICON_STRESSED",
    ICON_PARTY = "ICON_PARTY",
    ICON_HALLOWEEN = "ICON_HALLOWEEN",
    ICON_TEDLASSO = "ICON_TEDLASSO",
    ICON_FOOTBALL = "ICON_FOOTBALL",
    STREAK_FREEZE = "STREAK_FREEZE",
    STREAK_REVIVAL = "STREAK_REVIVAL",
    LONG_PAUSE = "LONG_PAUSE",
    AD_FREE_DAY = "AD_FREE_DAY",
    AD_FREE_WEEK = "AD_FREE_WEEK",
    AD_FREE_MONTH = "AD_FREE_MONTH",
    KNOWT_PLUS_TWO_MONTHS = "KNOWT_PLUS_TWO_MONTHS",
    KNOWT_LIMITLESS_ONE_YEAR = "KNOWT_LIMITLESS_ONE_YEAR",
    TOTE_BAG = "TOTE_BAG",
    T_SHIRT = "T_SHIRT",
    CREW_NECK = "CREW_NECK",
    HOODIE = "HOODIE",
    CAP = "CAP",
    TUMBLER = "TUMBLER",
    STICKERS = "STICKERS",
}

export type NotificationSettings = {
    __typename?: "NotificationSettings";
    emailSettings: BaseNotificationSettings;
    pushSettings: BaseNotificationSettings;
    gamifySettings?: GamificationSettings | null;
};

export type BaseNotificationSettings = {
    __typename?: "BaseNotificationSettings";
    disabled: Array<NotificationCategory>;
    tokens?: Array<string> | null;
    reminderTime?: string | null;
};

export enum NotificationCategory {
    STUDY_REMINDER = "STUDY_REMINDER",
    STREAK_REMINDER = "STREAK_REMINDER",
    PRACTICE_NOTIFICATION = "PRACTICE_NOTIFICATION",
    LEVEL_AND_PROGRESS = "LEVEL_AND_PROGRESS",
    INACTIVITY_NOTIFICATION = "INACTIVITY_NOTIFICATION",
    STORE_PROMOTION = "STORE_PROMOTION",
    CLASS_UPDATES = "CLASS_UPDATES",
    NEW_FOLLOWER = "NEW_FOLLOWER",
    FRIEND_ACTIVITY = "FRIEND_ACTIVITY",
    REFERRAL_UPDATES = "REFERRAL_UPDATES",
    MARKETING = "MARKETING",
}

export type GamificationSettings = {
    __typename?: "GamificationSettings";
    level: boolean;
    streak: boolean;
    badges: boolean;
};

export type PromoCode = {
    __typename?: "PromoCode";
    code: string;
    category: string;
};

export enum UpgradeEvent {
    GENERIC = "GENERIC",
    FOLDER_COLOR = "FOLDER_COLOR",
    FOLDER_EMOJI = "FOLDER_EMOJI",
    PASSWORD_PROTECT = "PASSWORD_PROTECT",
    PERSONALIZE_PROFILE = "PERSONALIZE_PROFILE",
    USERNAME_CHANGE = "USERNAME_CHANGE",
    REMOVE_ADS = "REMOVE_ADS",
    CHAT_WITH_AI = "CHAT_WITH_AI",
    AUDIO = "AUDIO",
    CUSTOM_MC = "CUSTOM_MC",
    CUSTOM_HINT = "CUSTOM_HINT",
    CUSTOM_FLASHCARD_AUDIO = "CUSTOM_FLASHCARD_AUDIO",
    MAX_VIDEO_AI = "MAX_VIDEO_AI",
    MAX_LECTURE_AI = "MAX_LECTURE_AI",
    MAX_PDF_AI = "MAX_PDF_AI",
    MAX_AI_TOOLS = "MAX_AI_TOOLS",
    AUTOCOMPLETE = "AUTOCOMPLETE",
    MAX_SCANS = "MAX_SCANS",
    MAX_VIDEO_GENERATIONS = "MAX_VIDEO_GENERATIONS",
    MAX_NOTE_FLASHCARDS = "MAX_NOTE_FLASHCARDS",
    MAX_NOTE_TESTS = "MAX_NOTE_TESTS",
    MAX_PROMPT = "MAX_PROMPT",
    MAX_EXPLAIN = "MAX_EXPLAIN",
    MAX_FRQ = "MAX_FRQ",
    MAX_CLASSES = "MAX_CLASSES",
    STUDENT_PROGRESS = "STUDENT_PROGRESS",
    MULTI_TEACHERS = "MULTI_TEACHERS",
    STUDY_RESTRICTIONS = "STUDY_RESTRICTIONS",
    REQUEST_UPGRADE = "REQUEST_UPGRADE",
    ASSIGNMENTS = "ASSIGNMENTS",
}

export type BadgeProgress = {
    __typename?: "BadgeProgress";
    userId: string;
    badge: BadgeEnum;
    current: number;
    claimed: Array<number>;
    unclaimed: Array<number>;
    earnedAt: Array<string>;
    newlyEarned?: boolean | null;
};

export enum BadgeEnum {
    FLASHCARDSET_CREATION = "FLASHCARDSET_CREATION",
    NOTE_CREATION = "NOTE_CREATION",
    VIDEO_UPLOAD = "VIDEO_UPLOAD",
    PDF_UPLOAD = "PDF_UPLOAD",
    QUIZLET_IMPORT = "QUIZLET_IMPORT",
    BRAINSTORM_ASSISTANT = "BRAINSTORM_ASSISTANT",
    FLASHCARDS_FROM_NOTE = "FLASHCARDS_FROM_NOTE",
    CHAT_WITH_AI = "CHAT_WITH_AI",
    EXPLAIN_WRONG_ANSWER = "EXPLAIN_WRONG_ANSWER",
    LEARN_MODE_ROUNDS = "LEARN_MODE_ROUNDS",
    SPACED_REPETITION_ROUNDS = "SPACED_REPETITION_ROUNDS",
    PRACTICE_TESTS = "PRACTICE_TESTS",
    MATCH_MODE = "MATCH_MODE",
    TESTS_FROM_NOTE = "TESTS_FROM_NOTE",
    MASTERY_OF_SET = "MASTERY_OF_SET",
    RATINGS_GIVEN = "RATINGS_GIVEN",
    HIGH_RATINGS_RECEIVED = "HIGH_RATINGS_RECEIVED",
    USERS_REFERRED = "USERS_REFERRED",
    FOLLOWERS_REACHED = "FOLLOWERS_REACHED",
    FOLLOWING_REACHED = "FOLLOWING_REACHED",
    PUBLIC_NOTES = "PUBLIC_NOTES",
    ITEM_VIEWS = "ITEM_VIEWS",
    CLASSES_CREATED = "CLASSES_CREATED",
    INVITED_STUDENTS = "INVITED_STUDENTS",
    JOINED_CLASSES = "JOINED_CLASSES",
    STUDENT_OF_THE_WEEK = "STUDENT_OF_THE_WEEK",
    STREAK_REACHED = "STREAK_REACHED",
}

export type DailyActivity = {
    __typename?: "DailyActivity";
    userId: string;
    activityDate: string;
    xp: number;
    items: Array<DailyActivityItem>;
    streakAction?: ActionEnum | null;
    badgesEarned: number;
    coinsEarned: number;
    asyncActions: Array<string>;
    actionMap?: string | null;
};

export type DailyActivityItem = {
    __typename?: "DailyActivityItem";
    itemId: string;
    xp: number;
    type: ItemType;
};

export enum ItemType {
    NOTE = "NOTE",
    FLASHCARDSET = "FLASHCARDSET",
    ASSIGNMENT = "ASSIGNMENT",
    FOLDER = "FOLDER",
    CLASS = "CLASS",
    USER = "USER",
    MEDIA = "MEDIA",
    VERIFIED_SCHOOL = "VERIFIED_SCHOOL",
    EXAM_MCQ = "EXAM_MCQ",
    EXAM_FRQ = "EXAM_FRQ",
    TEXTBOOK = "TEXTBOOK",
    CHAPTER = "CHAPTER",
    SECTION = "SECTION",
    SCHOOL = "SCHOOL",
    COURSE = "COURSE",
    BADGE_METADATA = "BADGE_METADATA",
    STORE_PRODUCT_METADATA = "STORE_PRODUCT_METADATA",
}

export enum ActionEnum {
    CREATE_NOTE = "CREATE_NOTE",
    CREATE_FLASHCARDSET = "CREATE_FLASHCARDSET",
    MAKE_NOTE_PUBLIC = "MAKE_NOTE_PUBLIC",
    GIVE_RATING = "GIVE_RATING",
    RECEIVE_HIGH_RATING = "RECEIVE_HIGH_RATING",
    FINISH_LEARN_MODE_ROUND = "FINISH_LEARN_MODE_ROUND",
    FINISH_PRACTICE_TEST = "FINISH_PRACTICE_TEST",
    FINISH_SPACED_REPITION_ROUND = "FINISH_SPACED_REPITION_ROUND",
    FINISH_MATCHING_GAME = "FINISH_MATCHING_GAME",
    FULLY_MASTER_SET = "FULLY_MASTER_SET",
    STREAK_MILESTONE = "STREAK_MILESTONE",
    TIER_1_QUEST = "TIER_1_QUEST",
    TIER_2_QUEST = "TIER_2_QUEST",
    TIER_3_QUEST = "TIER_3_QUEST",
    TIER_4_QUEST = "TIER_4_QUEST",
    TIER_5_QUEST = "TIER_5_QUEST",
    BRAINSTORM_ASSISTANT = "BRAINSTORM_ASSISTANT",
    FLASHCARD_FROM_NOTES = "FLASHCARD_FROM_NOTES",
    TEST_FROM_NOTE = "TEST_FROM_NOTE",
    NOTES_FROM_VIDEO = "NOTES_FROM_VIDEO",
    FLASHCARD_FROM_VIDEO = "FLASHCARD_FROM_VIDEO",
    NOTES_FROM_PDF = "NOTES_FROM_PDF",
    FLASHCARD_FROM_PDF = "FLASHCARD_FROM_PDF",
    CHAT_WITH_AI = "CHAT_WITH_AI",
    CREATE_CLASS = "CREATE_CLASS",
    INVITE_STUDENT = "INVITE_STUDENT",
    JOIN_CLASS = "JOIN_CLASS",
    SEND_ADMIN_EMAIL = "SEND_ADMIN_EMAIL",
    FILL_DPA_FORM = "FILL_DPA_FORM",
    EXAM_DATE_PASSED = "EXAM_DATE_PASSED",
    STREAK_FREEZE = "STREAK_FREEZE",
    LONG_PAUSE = "LONG_PAUSE",
    CANCEL_LONG_PAUSE = "CANCEL_LONG_PAUSE",
    STREAK_REVIVAL = "STREAK_REVIVAL",
    CANCEL_STREAK = "CANCEL_STREAK",
    NO_ACTION = "NO_ACTION",
    REFER_USER = "REFER_USER",
    REFERRED_BY_USER = "REFERRED_BY_USER",
    FOLLOW_USER = "FOLLOW_USER",
    FOLLOWED_BY_USER = "FOLLOWED_BY_USER",
    VIEW_ITEM = "VIEW_ITEM",
    WATCH_AD = "WATCH_AD",
    UPLOAD_VIDEO = "UPLOAD_VIDEO",
    UPLOAD_PDF = "UPLOAD_PDF",
    OPEN_NOTE = "OPEN_NOTE",
    OPEN_MEDIA = "OPEN_MEDIA",
    EXPLAIN_WRONG_ANSWER = "EXPLAIN_WRONG_ANSWER",
    ADDED_TAGS = "ADDED_TAGS",
    VIEW_PLANS = "VIEW_PLANS",
}

export type DeleteNoteInput = {
    userId: string;
    noteId: string;
};

export type UpdateNoteInput = {
    userId: string;
    noteId: string;
    content?: string | null;
    hash?: string | null;
    public?: boolean | null;
    draft?: boolean | null;
    password?: string | null;
    summary?: string | null;
    title?: string | null;
    trash?: boolean | null;
    created?: string | null;
    updated?: string | null;
    noteCode?: string | null;
    file?: string | null;
    mediaId?: string | null;
    folderId?: string | null;
    flashcardSetId?: string | null;
    importType?: ImportType | null;
    subject?: string | null;
    verified?: boolean | null;
    classPublic?: boolean | null;
    schoolId?: string | null;
    grade?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    topic?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
    isAI?: boolean | null;
    aiTool?: string | null;
    autocompleteSettings?: AutocompleteSettingsInput | null;
    deadline?: number | null;
};

export type AutocompleteSettingsInput = {
    language?: Language | null;
    disabled?: boolean | null;
    btnDisabled?: boolean | null;
    speed?: number | null;
    length?: string | null;
    grade?: string | null;
    format?: string | null;
    style?: string | null;
};

export type GenerateMediaTranscriptionInput = {
    mediaId: string;
};

export type Media = {
    __typename?: "Media";
    userId: string;
    mediaId: string;
    created: string;
    updated: string;
    title?: string | null;
    description?: string | null;
    password?: string | null;
    public: boolean;
    noteId?: string | null;
    flashcardSetId?: string | null;
    folderId?: string | null;
    trash?: boolean | null;
    type: MediaType;
    fileType: FileType;
    pages?: number | null;
    length?: number | null;
    bucket: string;
    embedUrl?: string | null;
    embedCommands?: Array<string | null> | null;
    subject?: string | null;
    topic?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    icon?: EmojiIcon | null;
    rating?: number | null;
    ratingCount?: number | null;
    verified?: boolean | null;
    transcript?: TranscriptEnum | null;
    schoolId?: string | null;
    grade?: string | null;
    views?: number | null;
    jwt?: string | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
    partial?: boolean | null;
    xpTracked?: string | null;
    isAI?: boolean | null;
};

export enum MediaType {
    AUDIO = "AUDIO",
    VIDEO = "VIDEO",
    PDF = "PDF",
    DOCX = "DOCX",
    PPTX = "PPTX",
    XLSX = "XLSX",
    IMAGE = "IMAGE",
}

export enum FileType {
    MP3 = "MP3",
    MP4 = "MP4",
    MKV = "MKV",
    MOV = "MOV",
    WAV = "WAV",
    WEBM = "WEBM",
    PDF = "PDF",
    M4A = "M4A",
    DOCX = "DOCX",
    DOC = "DOC",
    PPTX = "PPTX",
    PPT = "PPT",
    XLSX = "XLSX",
    XLS = "XLS",
}

export enum TranscriptEnum {
    FULL = "FULL",
    FREE = "FREE",
    MIN = "MIN",
    NONE = "NONE",
}

export type CreateMediaWithTranscriptionInput = {
    transcript: Array<TranscriptSentenceInput | null>;
    length: number;
    chapters?: Array<MediaChapterInput | null> | null;
    userId: string;
    mediaId: string;
    created: string;
    updated: string;
    title?: string | null;
    description?: string | null;
    password?: string | null;
    public: boolean;
    noteId?: string | null;
    flashcardSetId?: string | null;
    folderId?: string | null;
    trash?: boolean | null;
    type: MediaType;
    fileType: FileType;
    bucket: string;
    embedUrl?: string | null;
    embedCommands?: Array<string | null> | null;
    subject?: string | null;
    topic?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    rating?: number | null;
    ratingCount?: number | null;
    verified?: boolean | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export type TranscriptSentenceInput = {
    content?: string | null;
    start?: number | null;
    end?: number | null;
    speaker?: string | null;
};

export type MediaChapterInput = {
    title: string;
    start: number;
    end: number;
};

export type CreateMediaFromYoutubeInput = {
    url: string;
};

export type ImportFromLinkInput = {
    link: string;
};

export type DuplicateMediaInput = {
    baseMediaId: string;
    mediaId: string;
    folderId?: string | null;
};

export type UpdateMediaInput = {
    userId: string;
    mediaId: string;
    created?: string | null;
    updated?: string | null;
    title?: string | null;
    description?: string | null;
    password?: string | null;
    public?: boolean | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    folderId?: string | null;
    trash?: boolean | null;
    type?: MediaType | null;
    fileType?: FileType | null;
    bucket?: string | null;
    embedUrl?: string | null;
    embedCommands?: Array<string | null> | null;
    subject?: string | null;
    topic?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    rating?: number | null;
    ratingCount?: number | null;
    transcript?: TranscriptEnum | null;
    schoolId?: string | null;
    grade?: string | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
    isAI?: boolean | null;
};

export type DeleteMediaInput = {
    userId: string;
    mediaId: string;
};

export type CreateFlashcardSetInput = {
    userId: string;
    flashcardSetId: string;
    mediaId?: string | null;
    sort?: number | null;
    noteId?: string | null;
    position?: number | null;
    public?: boolean | null;
    password?: string | null;
    trash?: boolean | null;
    title?: string | null;
    description?: string | null;
    draft?: boolean | null;
    size?: number | null;
    starred?: Array<string | null> | null;
    studyFrom?: FlashcardStudyFrom | null;
    created?: number | null;
    updated?: number | null;
    quizletUrl?: string | null;
    answerSide?: FlashcardSide | null;
    flashcards: Array<FlashcardInput | null>;
    termLanguage?: Language | null;
    definitionLanguage?: Language | null;
    relatedNotes?: Array<string | null> | null;
    order?: Array<number | null> | null;
    flashcardSetCode?: string | null;
    classPublic?: boolean | null;
    subject?: string | null;
    verified?: boolean | null;
    schoolId?: string | null;
    grade?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    topic?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    folderId?: string | null;
    rating?: number | null;
    ratingCount?: number | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
    isAI?: boolean | null;
    deadline?: number | null;
};

export enum FlashcardStudyFrom {
    ALL = "ALL",
    STARRED = "STARRED",
}

export type FlashcardInput = {
    flashcardId: string;
    flashcardSetId?: string | null;
    userId?: string | null;
    term?: string | null;
    termAudio?: string | null;
    definition?: string | null;
    definitionAudio?: string | null;
    distractorIds?: Array<string | null> | null;
    questionType?: QuestionType | null;
    isCorrect?: boolean | null;
    disabled?: boolean | null;
    draft?: boolean | null;
    trash?: boolean | null;
    image?: string | null;
    secondaryImage?: string | null;
    edited?: boolean | null;
    created?: string | null;
    updated?: string | null;
    twoSided?: boolean | null;
    schedule?: string | null;
    quality?: number | null;
    termRecordedAudio?: string | null;
    definitionRecordedAudio?: string | null;
    mcOptions?: Array<string | null> | null;
    definitionCustomHint?: string | null;
    termCustomHint?: string | null;
};

export enum QuestionType {
    MULTI = "MULTI",
    FILL_BLANK = "FILL_BLANK",
    MATCHING = "MATCHING",
    WRITING = "WRITING",
    NONE = "NONE",
    HIST_ORDERING = "HIST_ORDERING",
    MULTI_BLANK = "MULTI_BLANK",
    TF = "TF",
}

export type FlashcardSet = {
    __typename?: "FlashcardSet";
    userId: string;
    flashcardSetId: string;
    sort?: number | null;
    noteId?: string | null;
    mediaId?: string | null;
    position?: number | null;
    title?: string | null;
    description?: string | null;
    public?: boolean | null;
    password?: string | null;
    trash?: boolean | null;
    draft?: boolean | null;
    starred?: Array<string | null> | null;
    size?: number | null;
    studyFrom?: FlashcardStudyFrom | null;
    created?: number | null;
    updated?: number | null;
    quizletUrl?: string | null;
    answerSide?: FlashcardSide | null;
    shuffled?: boolean | null;
    flashcards?: Array<Flashcard | null> | null;
    termLanguage?: Language | null;
    definitionLanguage?: Language | null;
    relatedNotes?: Array<string | null> | null;
    order?: Array<number | null> | null;
    flashcardSetCode?: string | null;
    views?: number | null;
    classPublic?: boolean | null;
    subject?: string | null;
    verified?: boolean | null;
    schoolId?: string | null;
    grade?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    topic?: string | null;
    icon?: EmojiIcon | null;
    folderId?: string | null;
    rating?: number | null;
    ratingCount?: number | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
    lock?: boolean | null;
    partial?: boolean | null;
    xpTracked?: string | null;
    studySettings?: FlashcardStudySettings | null;
    isAI?: boolean | null;
    autocompleteSettings?: AutocompleteSettings | null;
    deadline?: number | null;
};

export type Flashcard = {
    __typename?: "Flashcard";
    flashcardId: string;
    flashcardSetId?: string | null;
    userId?: string | null;
    term?: string | null;
    termAudio?: string | null;
    definition?: string | null;
    definitionAudio?: string | null;
    distractorIds?: Array<string | null> | null;
    questionType?: QuestionType | null;
    isCorrect?: boolean | null;
    draft?: boolean | null;
    trash?: boolean | null;
    disabled?: boolean | null;
    image?: string | null;
    secondaryImage?: string | null;
    edited?: boolean | null;
    created?: string | null;
    updated?: string | null;
    twoSided?: boolean | null;
    schedule?: string | null;
    quality?: number | null;
    termRecordedAudio?: string | null;
    definitionRecordedAudio?: string | null;
    mcOptions?: Array<string | null> | null;
    termCustomHint?: string | null;
    definitionCustomHint?: string | null;
};

export type FlashcardStudySettings = {
    __typename?: "FlashcardStudySettings";
    blockedStudyModes: Array<StudySessionType>;
    settings: FlashcardsSetLockedStudySettings;
};

export enum StudySessionType {
    LEARN = "LEARN",
    MATCH = "MATCH",
    SPACED = "SPACED",
    REVIEW = "REVIEW",
    TEST = "TEST",
}

export type FlashcardsSetLockedStudySettings = {
    __typename?: "FlashcardsSetLockedStudySettings";
    LEARN?: LearnLockedSettings | null;
    TEST?: TestLockedSettings | null;
    SPACED?: SpacedLockedSettings | null;
    MATCH?: MatchLockedSettings | null;
    REVIEW?: ReviewLockedSettings | null;
};

export type LearnLockedSettings = {
    __typename?: "LearnLockedSettings";
    locked: Array<string>;
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    reType: boolean;
    length?: number | null;
    override?: boolean | null;
};

export type TestLockedSettings = {
    __typename?: "TestLockedSettings";
    locked: Array<string>;
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    length: number;
};

export type SpacedLockedSettings = {
    __typename?: "SpacedLockedSettings";
    locked: Array<string>;
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    cardsPerSession: number;
    intervals?: Array<number> | null;
};

export type MatchLockedSettings = {
    __typename?: "MatchLockedSettings";
    locked: Array<string>;
    grid: boolean;
    starred: boolean;
    length: number;
};

export type ReviewLockedSettings = {
    __typename?: "ReviewLockedSettings";
    locked: Array<string>;
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    position?: number | null;
    shuffled: boolean;
    fuzzy: boolean;
    reType: boolean;
};

export type FlashcardSetContext = {
    __typename?: "FlashcardSetContext";
    item: FlashcardSet;
    user: UserDetails;
    badges: Array<BadgeProgress>;
    dailyActivity: DailyActivity;
    level?: number | null;
};

export type UpdateFlashcardSetInput = {
    userId: string;
    flashcardSetId: string;
    sort?: number | null;
    mediaId?: string | null;
    noteId?: string | null;
    position?: number | null;
    title?: string | null;
    description?: string | null;
    public?: boolean | null;
    password?: string | null;
    trash?: boolean | null;
    draft?: boolean | null;
    size?: number | null;
    starred?: Array<string | null> | null;
    studyFrom?: FlashcardStudyFrom | null;
    created?: number | null;
    updated?: number | null;
    answerSide?: FlashcardSide | null;
    flashcards?: Array<FlashcardInput | null> | null;
    termLanguage?: Language | null;
    definitionLanguage?: Language | null;
    relatedNotes?: Array<string | null> | null;
    order?: Array<number | null> | null;
    flashcardSetCode?: string | null;
    classPublic?: boolean | null;
    subject?: string | null;
    verified?: boolean | null;
    schoolId?: string | null;
    grade?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    tags?: Array<string | null> | null;
    topic?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    folderId?: string | null;
    rating?: number | null;
    ratingCount?: number | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
    studySettings?: FlashcardStudySettingsInput | null;
    isAI?: boolean | null;
    autocompleteSettings?: AutocompleteSettingsInput | null;
    deadline?: number | null;
};

export type FlashcardStudySettingsInput = {
    blockedStudyModes: Array<StudySessionType>;
    settings: FlashcardsSetLockedStudySettingsInput;
};

export type FlashcardsSetLockedStudySettingsInput = {
    LEARN?: LearnLockedSettingsInput | null;
    TEST?: TestLockedSettingsInput | null;
    SPACED?: SpacedLockedSettingsInput | null;
    MATCH?: MatchLockedSettingsInput | null;
    REVIEW?: ReviewLockedSettingsInput | null;
};

export type LearnLockedSettingsInput = {
    locked: Array<string>;
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    reType: boolean;
    length?: number | null;
    override?: boolean | null;
};

export type TestLockedSettingsInput = {
    locked: Array<string>;
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    length: number;
};

export type SpacedLockedSettingsInput = {
    locked: Array<string>;
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    cardsPerSession: number;
    intervals?: Array<number> | null;
};

export type MatchLockedSettingsInput = {
    locked: Array<string>;
    grid: boolean;
    starred: boolean;
    length: number;
};

export type ReviewLockedSettingsInput = {
    locked: Array<string>;
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    position?: number | null;
    shuffled: boolean;
    fuzzy: boolean;
    reType: boolean;
};

export type DeleteFlashcardSetInput = {
    userId: string;
    flashcardSetId: string;
};

export type FlashcardListInput = {
    userId: string;
    items?: Array<FlashcardInput | null> | null;
};

export type FlashcardConnection = {
    __typename?: "FlashcardConnection";
    items: Array<Flashcard>;
    nextToken?: string | null;
};

export type NotificationInput = {
    notificationId: string;
};

export type GenericReturnType = {
    __typename?: "GenericReturnType";
    status?: number | null;
    body?: string | null;
};

export type UserDetailsInput = {
    accountType?: AccountType | null;
    userId?: string | null;
    timeZone?: string | null;
    referral?: string | null;
    Name?: string | null;
    hideSensitiveInfo?: boolean | null;
    answerSide?: FlashcardSide | null;
    pictureUrl?: string | null;
    birthday?: string | null;
    phone?: string | null;
    noteSidebarWidth?: number | null;
    refFrom_v2?: string | null;
    verified?: boolean | null;
    bio?: string | null;
    socials?: SocialsInput | null;
    cover?: string | null;
    grade?: string | null;
    alerts?: string | null;
    schoolId?: string | null;
    deleteReq?: boolean | null;
    profileColor?: string | null;
    privacyAck?: boolean | null;
    examHistory?: string | null;
    invites?: number | null;
    stripeTrialTime?: number | null;
    cancelPromoTime?: number | null;
    featuredBadges?: Array<string> | null;
    notifSettings?: NotificationSettingsInput | null;
    autocompleteSettings?: AutocompleteSettingsInput | null;
    favTools?: Array<string> | null;
    isSidebarExpanded?: boolean | null;
};

export type SocialsInput = {
    ig?: string | null;
    twitter?: string | null;
    tiktok?: string | null;
    in?: string | null;
    link?: string | null;
    spotify?: string | null;
};

export type NotificationSettingsInput = {
    emailSettings: BaseNotificationSettingsInput;
    pushSettings: BaseNotificationSettingsInput;
    gamifySettings?: GamificationSettingsInput | null;
};

export type BaseNotificationSettingsInput = {
    disabled: Array<NotificationCategory>;
    tokens?: Array<string> | null;
    reminderTime?: string | null;
};

export type GamificationSettingsInput = {
    level: boolean;
    streak: boolean;
    badges: boolean;
};

export type UserDetailsContext = {
    __typename?: "UserDetailsContext";
    item: UserDetails;
    user: UserDetails;
    badges: Array<BadgeProgress>;
    dailyActivity: DailyActivity;
    level?: number | null;
};

export type FollowInput = {
    userId: string;
};

export type Follower = {
    __typename?: "Follower";
    userId?: string | null;
    followerId?: string | null;
};

export type FollowerContext = {
    __typename?: "FollowerContext";
    item: Follower;
    user: UserDetails;
    badges: Array<BadgeProgress>;
    dailyActivity: DailyActivity;
    level?: number | null;
};

export type UnfollowInput = {
    userId: string;
};

export type RemoveFollowerInput = {
    userId: string;
};

export type UpdateUsernameInput = {
    username: string;
};

export type TemporaryViewInput = {
    viewedItems: Array<TemporaryViewItemInput>;
};

export type TemporaryViewItemInput = {
    itemId: string;
    itemType: ItemType;
    itemOwnerId: string;
};

export type TemporaryViewOutput = {
    __typename?: "TemporaryViewOutput";
    viewedItems: Array<TemporaryView>;
};

export type TemporaryView = {
    __typename?: "TemporaryView";
    itemId: string;
    itemType: ItemType;
    count: number;
    itemOwnerId: string;
    viewTime: number;
};

export type CreateBookmarkInput = {
    ID: string;
    type: ItemType;
};

export type DeleteBookmarkInput = {
    ID: string;
    type: ItemType;
};

export type CreateFolderInput = {
    userId: string;
    folderId: string;
    parentId?: string | null;
    name?: string | null;
    description?: string | null;
    created?: string | null;
    updated?: string | null;
    notesCount?: number | null;
    trash?: boolean | null;
    public?: boolean | null;
    password?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export type Folder = {
    __typename?: "Folder";
    userId: string;
    folderId: string;
    parentId?: string | null;
    name?: string | null;
    color?: string | null;
    description?: string | null;
    created?: string | null;
    updated?: string | null;
    notesCount?: number | null;
    trash?: boolean | null;
    public?: boolean | null;
    password?: string | null;
    icon?: EmojiIcon | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
    partial?: boolean | null;
};

export type UpdateFolderInput = {
    userId: string;
    folderId: string;
    parentId?: string | null;
    name?: string | null;
    description?: string | null;
    created?: string | null;
    updated?: string | null;
    notesCount?: number | null;
    trash?: boolean | null;
    public?: boolean | null;
    password?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    classId?: string | null;
    addedAt?: string | null;
    sections?: Array<string> | null;
};

export type DeleteFolderInput = {
    userId: string;
    folderId: string;
};

export type CreateRatingInput = {
    noteId: string;
    userId: string;
    noteOwnerId: string;
    message?: string | null;
    rating: number;
    type: ItemType;
};

export type RatingV2Context = {
    __typename?: "RatingV2Context";
    item: Rating;
    user: UserDetails;
    badges: Array<BadgeProgress>;
    dailyActivity: DailyActivity;
    level?: number | null;
};

export type Rating = {
    __typename?: "Rating";
    noteId: string;
    userId: string;
    noteOwnerId: string;
    message?: string | null;
    rating?: number | null;
    type?: ItemType | null;
};

export type UpdateRatingInput = {
    noteId: string;
    userId: string;
    noteOwnerId?: string | null;
    message?: string | null;
    rating?: number | null;
};

export type FlashcardSetViewerInput = {
    userId: string;
    flashcardSetId: string;
    position?: number | null;
    starred?: Array<string | null> | null;
    length?: number | null;
    studyFrom?: FlashcardStudyFrom | null;
    studiedFlashcards?: Array<string | null> | null;
    order?: Array<string | null> | null;
    shuffled?: boolean | null;
    lastViewedFlashcard?: string | null;
    answerSide?: FlashcardSide | null;
    reviewMode?: FlashcardReviewMode | null;
};

export enum FlashcardReviewMode {
    STANDARD = "STANDARD",
    WRITING = "WRITING",
}

export type FlashcardSetViewer = {
    __typename?: "FlashcardSetViewer";
    userId: string;
    flashcardSetId: string;
    position?: number | null;
    starred?: Array<string | null> | null;
    length?: number | null;
    studyFrom?: FlashcardStudyFrom | null;
    studiedFlashcards?: Array<string | null> | null;
    order?: Array<string | null> | null;
    shuffled?: boolean | null;
    lastViewedFlashcard?: string | null;
    answerSide?: FlashcardSide | null;
    reviewMode?: FlashcardReviewMode | null;
};

export type GetFlashcardSetInput = {
    flashcardSetId: string;
    password?: string | null;
};

export type UserIdInput = {
    userId: string;
};

export type StripeConnectionInput = {
    customerId: string;
};

export type DeleteAccountRequestInput = {
    userId: string;
    category?: string | null;
    followUp?: string | null;
    reason?: string | null;
    deleteReq?: boolean | null;
};

export type TextToSpeechInput = {
    text: string;
    flashcardSetId?: string | null;
    flashcardId?: string | null;
    side?: FlashcardSide | null;
    voice?: VoiceInput | null;
};

export type VoiceInput = {
    language: Language;
    name?: string | null;
    speakingRate?: number | null;
    pitch?: number | null;
};

export type LanguageResponse = {
    __typename?: "LanguageResponse";
    language?: Language | null;
    confidence?: number | null;
};

export type TextToSpeechResponse = {
    __typename?: "TextToSpeechResponse";
    url: string;
};

export type PasswordInput = {
    password: string;
    type: ItemType;
    id: string;
};

export type Password = {
    __typename?: "Password";
    password: string;
    type: ItemType;
    id: string;
};

export type EditChapterInput = {
    mediaId: string;
    bucket: string;
    idx: number;
    title?: string | null;
};

export type BlogInput = {
    blogId: string;
    title: string;
    slug: string;
    content: string;
    summary?: string | null;
    created: string;
    updated: string;
    tags?: string | null;
    coverImage?: BlogCoverImageInput | null;
    metadata?: BlogSEOMetadataInput | null;
    draft?: boolean | null;
    trash?: boolean | null;
    pinned?: number | null;
};

export type BlogCoverImageInput = {
    url?: string | null;
    width?: number | null;
    height?: number | null;
};

export type BlogSEOMetadataInput = {
    title?: string | null;
    description?: string | null;
};

export type Blog = {
    __typename?: "Blog";
    userId: string;
    blogId: string;
    title: string;
    slug: string;
    content: string;
    summary?: string | null;
    created: string;
    updated: string;
    tags?: string | null;
    coverImage?: BlogCoverImage | null;
    metadata?: BlogSEOMetadata | null;
    draft?: boolean | null;
    trash?: boolean | null;
    type?: string | null;
    pinned?: number | null;
};

export type BlogCoverImage = {
    __typename?: "BlogCoverImage";
    url?: string | null;
    width?: number | null;
    height?: number | null;
};

export type BlogSEOMetadata = {
    __typename?: "BlogSEOMetadata";
    title?: string | null;
    description?: string | null;
};

export type FlashcardSetStudySessionInput = {
    flashcardSetId: string;
    examDate?: string | null;
    type: StudySessionType;
    settings?: StudySessionSettingsInput | null;
    studyFrom?: StudySessionProgressEnum | null;
    isFirstRound?: boolean | null;
};

export type StudySessionSettingsInput = {
    LEARN?: LearnStudySessionSettingInput | null;
    TEST?: TestStudySessionSettingInput | null;
    SPACED?: SpacedStudySessionSettingInput | null;
    MATCH?: MatchStudySessionSettingInput | null;
    REVIEW?: ReviewStudySessionSettingInput | null;
};

export type LearnStudySessionSettingInput = {
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    reType: boolean;
    length?: number | null;
    override?: boolean | null;
};

export type TestStudySessionSettingInput = {
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    length: number;
};

export type SpacedStudySessionSettingInput = {
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    cardsPerSession: number;
    intervals?: Array<number> | null;
};

export type MatchStudySessionSettingInput = {
    grid: boolean;
    starred: boolean;
    length: number;
};

export type ReviewStudySessionSettingInput = {
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    reType: boolean;
    sorting?: boolean | null;
};

export enum StudySessionProgressEnum {
    NEW = "NEW",
    LEARNING = "LEARNING",
    REVIEWING = "REVIEWING",
    MASTERED = "MASTERED",
}

export type FlashcardSetStudySessionConnection = {
    __typename?: "FlashcardSetStudySessionConnection";
    studySession: StudySession;
    questions: Array<FlashcardSetStudySessionQuestion>;
};

export type StudySession = {
    __typename?: "StudySession";
    userId: string;
    itemId: string;
    type: ItemType;
    created: string;
    updated: string;
    examDate?: string | null;
    unmigrated?: boolean | null;
    newCards: number;
    nextDue: string;
    nextNewDue: string;
    settings: StudySessionSettings;
    progress: StudySessionProgress;
    practiceTests?: Array<PracticeTestProgress> | null;
    syncTime: string;
    lastView?: string | null;
    title?: string | null;
};

export type StudySessionSettings = {
    __typename?: "StudySessionSettings";
    LEARN?: LearnStudySessionSetting | null;
    TEST?: TestStudySessionSetting | null;
    SPACED?: SpacedStudySessionSetting | null;
    MATCH?: MatchStudySessionSetting | null;
    REVIEW?: ReviewStudySessionSetting | null;
};

export type LearnStudySessionSetting = {
    __typename?: "LearnStudySessionSetting";
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    reType: boolean;
    length?: number | null;
    override?: boolean | null;
};

export type TestStudySessionSetting = {
    __typename?: "TestStudySessionSetting";
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    length: number;
};

export type SpacedStudySessionSetting = {
    __typename?: "SpacedStudySessionSetting";
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    cardsPerSession: number;
    intervals?: Array<number> | null;
    override?: boolean | null;
};

export type MatchStudySessionSetting = {
    __typename?: "MatchStudySessionSetting";
    grid: boolean;
    starred: boolean;
    length: number;
};

export type ReviewStudySessionSetting = {
    __typename?: "ReviewStudySessionSetting";
    questionTypes: Array<QuestionType>;
    answerSide: FlashcardSide;
    starred: boolean;
    shuffled: boolean;
    fuzzy: boolean;
    reType: boolean;
    sorting?: boolean | null;
};

export type StudySessionProgress = {
    __typename?: "StudySessionProgress";
    NEW: number;
    LEARNING: number;
    REVIEWING: number;
    MASTERED: number;
};

export type PracticeTestProgress = {
    __typename?: "PracticeTestProgress";
    timeTaken: number;
    timestamp: number;
    score: string;
};

export type FlashcardSetStudySessionQuestion = {
    __typename?: "FlashcardSetStudySessionQuestion";
    flashcardId: string;
    distractors: Array<string>;
    questionType: QuestionType;
    answerSide: FlashcardSide;
    buttons?: Array<number> | null;
    tfIsCorrect: boolean;
    progress: StudySessionProgressEnum;
    spacing?: SpacingIntervals | null;
};

export type SpacingIntervals = {
    __typename?: "SpacingIntervals";
    AGAIN: number;
    HARD: number;
    GOOD: number;
    EASY: number;
};

export type GradeFlashcardSetStudySessionInput = {
    flashcardSetId: string;
    questions: Array<GradeFlashcardSetStudySessionQuestionInput>;
    isFirstRound?: boolean | null;
};

export type GradeFlashcardSetStudySessionQuestionInput = {
    flashcardId: string;
    selectedFlashcardId?: string | null;
    side: FlashcardSide;
    timestamp: number;
    timeTaken: number;
    mode: StudySessionType;
    questionType: QuestionType;
    button?: SpacedRepetitionButton | null;
    isCorrect: boolean;
    answer: string;
    sort?: FlashcardSortingEnum | null;
};

export enum SpacedRepetitionButton {
    AGAIN = "AGAIN",
    HARD = "HARD",
    GOOD = "GOOD",
    EASY = "EASY",
}

export enum FlashcardSortingEnum {
    KNOW = "KNOW",
    D_KNOW = "D_KNOW",
}

export type FlashcardSetStudySessionRoundV2Context = {
    __typename?: "FlashcardSetStudySessionRoundV2Context";
    item: FlashcardSetStudySessionRound;
    user: UserDetails;
    dailyActivity: DailyActivity;
    badges: Array<BadgeProgress>;
    level?: number | null;
};

export type FlashcardSetStudySessionRound = {
    __typename?: "FlashcardSetStudySessionRound";
    studySession: StudySession;
    studyStates: Array<FlashcardStudyState>;
};

export type FlashcardStudyState = {
    __typename?: "FlashcardStudyState";
    userId: string;
    flashcardId: string;
    flashcardSetId: string;
    created: string;
    updated: string;
    distractors: Array<string>;
    mastery: number;
    lastStudied: string;
    blank?: boolean | null;
    sort?: FlashcardSortingEnum | null;
    nextDue: string;
    difficulty?: number | null;
    interval: number;
    eFactor: number;
    progress: StudySessionProgressEnum;
    history: Array<FlashcardStudyStateHistory>;
};

export type FlashcardStudyStateHistory = {
    __typename?: "FlashcardStudyStateHistory";
    timestamp: number;
    timeTaken: number;
    mode: StudySessionType;
    questionType: QuestionType;
    selectedFlashcardId?: string | null;
    side: FlashcardSide;
    button?: SpacedRepetitionButton | null;
    isCorrect: boolean;
    answer: string;
};

export type ResetStudySessionSortingInput = {
    flashcardSetId: string;
    onlyForStillLearning?: boolean | null;
};

export type FlashcardStudyStateConnection = {
    __typename?: "FlashcardStudyStateConnection";
    items: Array<FlashcardStudyState>;
    nextToken?: string | null;
};

export type ClearFlashcardSetStudySessionInput = {
    itemId: string;
};

export type GradeNoteTestInput = {
    noteId: string;
    score: string;
    timestamp: number;
    timeTaken: number;
    numQuestions: number;
};

export type SubmitAICompletionInput = {
    timestamp?: string | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    flashcardId?: string | null;
    itemId?: string | null;
    itemType?: ItemType | null;
    id?: string | null;
    input: string;
    messages?: Array<AIMessageInput | null> | null;
    output: string;
    inputTokens?: number | null;
    outputTokens?: number | null;
    subject?: string | null;
    topic?: string | null;
    type?: AICompletionType | null;
    timeTaken?: number | null;
};

export type AIMessageInput = {
    role?: string | null;
    content?: string | null;
};

export enum AICompletionType {
    PDF_SUMMARIZER = "PDF_SUMMARIZER",
    VIDEO_SUMMARIZER = "VIDEO_SUMMARIZER",
    LECTURE_NOTE_TAKER = "LECTURE_NOTE_TAKER",
    PPT_SUMMARIZER = "PPT_SUMMARIZER",
    NOTE_TEST = "NOTE_TEST",
    FORMAT_TRANSCRIPT = "FORMAT_TRANSCRIPT",
    EXPLAIN_WHY_IM_WRONG = "EXPLAIN_WHY_IM_WRONG",
    EXPLAIN_EXAM_FRQ_ANSWER = "EXPLAIN_EXAM_FRQ_ANSWER",
    MEDIA_NOTES = "MEDIA_NOTES",
    MEDIA_FLASHCARDS = "MEDIA_FLASHCARDS",
    NOTE_FLASHCARDS = "NOTE_FLASHCARDS",
    FLASHCARD_CHAT = "FLASHCARD_CHAT",
    NOTE_CHAT = "NOTE_CHAT",
    FLASHCARD_AUTOCOMPLETE = "FLASHCARD_AUTOCOMPLETE",
    NOTE_AUTOCOMPLETE = "NOTE_AUTOCOMPLETE",
    SCAN_AND_SOLVE = "SCAN_AND_SOLVE",
    AP_HISTORY_MCQ_GENERIC = "AP_HISTORY_MCQ_GENERIC",
    VIDEO_GENERATION = "VIDEO_GENERATION",
    GENERATE_SIMILAR_QUESTIONS = "GENERATE_SIMILAR_QUESTIONS",
    AP_MATH_MCQ_GENERIC = "AP_MATH_MCQ_GENERIC",
    AP_SCIENCE_MCQ_GENERIC = "AP_SCIENCE_MCQ_GENERIC",
    AP_HUG_MCQ_GENERIC = "AP_HUG_MCQ_GENERIC",
    AP_ECON_MCQ_GENERIC = "AP_ECON_MCQ_GENERIC",
    AP_PSYCH_MCQ_GENERIC = "AP_PSYCH_MCQ_GENERIC",
    APCSA_MCQ_GENERIC = "APCSA_MCQ_GENERIC",
    AP_SCIENCE_FRQ_GENERIC = "AP_SCIENCE_FRQ_GENERIC",
    AP_ECON_FRQ_GENERIC = "AP_ECON_FRQ_GENERIC",
    AP_MATH_FRQ_GENERIC = "AP_MATH_FRQ_GENERIC",
    AP_CSA_FRQ_GENERIC = "AP_CSA_FRQ_GENERIC",
    LESSON_PLAN = "LESSON_PLAN",
    WRITING_FEEDBACK = "WRITING_FEEDBACK",
    TEXT_PROOFREADER = "TEXT_PROOFREADER",
    RUBRIC_GENERATOR = "RUBRIC_GENERATOR",
    READING_TEXT = "READING_TEXT",
    WORKSHEET_GENERATOR = "WORKSHEET_GENERATOR",
    TEXT_LEVELER = "TEXT_LEVELER",
    PROFESSIONAL_EMAIL = "PROFESSIONAL_EMAIL",
    REPORT_CARD_COMMENTS = "REPORT_CARD_COMMENTS",
    TEACHER_TOOL_FIELDS_HELPER = "TEACHER_TOOL_FIELDS_HELPER",
    TEXT_PARAPHRASER = "TEXT_PARAPHRASER",
    MAKE_IT_RELEVANT = "MAKE_IT_RELEVANT",
    CLASS_NEWSLETTER_GENERATOR = "CLASS_NEWSLETTER_GENERATOR",
    VOCABULARY_LIST_GENERATOR = "VOCABULARY_LIST_GENERATOR",
    DISCUSSION_PROMPT_GENERATOR = "DISCUSSION_PROMPT_GENERATOR",
    WRITING_PROMPT_GENERATOR = "WRITING_PROMPT_GENERATOR",
    MATH_WORD_PROBLEM_GENERATOR = "MATH_WORD_PROBLEM_GENERATOR",
    SCIENCE_LAB_GENERATOR = "SCIENCE_LAB_GENERATOR",
    MULTIPLE_CHOICE_QUIZ_GENERATOR = "MULTIPLE_CHOICE_QUIZ_GENERATOR",
    DEBATE_SCENARIO_GENERATOR = "DEBATE_SCENARIO_GENERATOR",
    ICE_BREAKER_ACTIVITIES = "ICE_BREAKER_ACTIVITIES",
    CONTENT_SUMMARIZER = "CONTENT_SUMMARIZER",
    JEOPARDY_GAME_GENERATOR = "JEOPARDY_GAME_GENERATOR",
    DECODABLE_TEXT_GENERATOR = "DECODABLE_TEXT_GENERATOR",
    STANDARDS_UNPACKER = "STANDARDS_UNPACKER",
    MATH_SPIRAL_REVIEW_GENERATOR = "MATH_SPIRAL_REVIEW_GENERATOR",
    JOKE_GENERATOR = "JOKE_GENERATOR",
    TEXT_TRANSLATOR = "TEXT_TRANSLATOR",
    STORY_WORD_PROBLEMS_GENERATOR = "STORY_WORD_PROBLEMS_GENERATOR",
    BIP_GENERATOR = "BIP_GENERATOR",
    TIME_BASED_ACTIVITY_GENERATOR = "TIME_BASED_ACTIVITY_GENERATOR",
    RECOMMENDATION_LETTER_GENERATOR = "RECOMMENDATION_LETTER_GENERATOR",
    PERFORMANCE_TASK_GENERATOR = "PERFORMANCE_TASK_GENERATOR",
    PLC_AGENDA_GENERATOR = "PLC_AGENDA_GENERATOR",
    SYLLABUS_GENERATOR = "SYLLABUS_GENERATOR",
    SONG_GENERATOR = "SONG_GENERATOR",
    ASSIGNMENT_RUBRIC_GENERATOR = "ASSIGNMENT_RUBRIC_GENERATOR",
    ASSIGNMENT_PRIOR_KNOWLEDGE_GENERATOR = "ASSIGNMENT_PRIOR_KNOWLEDGE_GENERATOR",
    ASSIGNMENT_GRADER = "ASSIGNMENT_GRADER",
    ASSIGNMENT_FEEDBACK_GENERATOR = "ASSIGNMENT_FEEDBACK_GENERATOR",
    ASSIGNMENT_CHAT = "ASSIGNMENT_CHAT",
    ASSIGNMENT_MISC_INITIAL_SETTINGS = "ASSIGNMENT_MISC_INITIAL_SETTINGS",
    ASSIGNMENT_RULES_GENERATOR = "ASSIGNMENT_RULES_GENERATOR",
    ASSIGNMENT_TITLE_DESC_GENERATOR = "ASSIGNMENT_TITLE_DESC_GENERATOR",
    ASSIGNMENT_STANDARDS_GENERATOR = "ASSIGNMENT_STANDARDS_GENERATOR",
}

export type AICompletion = {
    __typename?: "AICompletion";
    userId: string;
    timestamp?: string | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    flashcardId?: string | null;
    itemId?: string | null;
    itemType?: ItemType | null;
    id?: string | null;
    input?: string | null;
    messages?: Array<AIMessage | null> | null;
    output: string;
    inputTokens?: number | null;
    outputTokens?: number | null;
    subject?: string | null;
    topic?: string | null;
    type?: AICompletionType | null;
    timeTaken?: number | null;
    params?: string | null;
};

export type AIMessage = {
    __typename?: "AIMessage";
    role?: string | null;
    content?: string | null;
};

export type UpdateEmailInput = {
    newEmail: string;
    password: string;
};

export type ManuallyVerifyUserInput = {
    username: string;
};

export type CreateClassInput = {
    userId: string;
    sections: Array<ClassSectionInput>;
    members: Array<ClassMemberInput>;
    pinned: Array<string>;
    created: string;
    updated: string;
    name: string;
    description?: string | null;
    timezone?: string | null;
    schoolId?: string | null;
    grade?: string | null;
    subject?: string | null;
    topic?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    icon?: EmojiIconInput | null;
    color: string;
    cover?: string | null;
    googleClassId?: string | null;
};

export type ClassSectionInput = {
    name: string;
    id: string;
    color: string;
    timings?: Array<ClassTimingsInput | null> | null;
};

export type ClassTimingsInput = {
    start?: string | null;
    end?: string | null;
    days?: Array<string | null> | null;
};

export type ClassMemberInput = {
    userId: string;
    role: ClassRole;
    sections: Array<string>;
    pending?: boolean | null;
};

export type Class = {
    __typename?: "Class";
    userId: string;
    classId: string;
    sections: Array<ClassSection>;
    members: Array<ClassMemberWithDetails>;
    memberCount?: number | null;
    pinned: Array<string>;
    created: string;
    updated: string;
    name: string;
    description?: string | null;
    timings?: Array<ClassTimings> | null;
    timezone?: string | null;
    org?: string | null;
    schoolId?: string | null;
    grade?: string | null;
    subject?: string | null;
    topic?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    icon?: EmojiIcon | null;
    color: string;
    cover?: string | null;
    googleClassId?: string | null;
    fileCount?: number | null;
    partial?: boolean | null;
    flashcardStudySettings?: FlashcardStudySettings | null;
};

export type ClassSection = {
    __typename?: "ClassSection";
    name: string;
    id: string;
    color: string;
    timings?: Array<ClassTimings | null> | null;
};

export type ClassTimings = {
    __typename?: "ClassTimings";
    start?: string | null;
    end?: string | null;
    days?: Array<string | null> | null;
};

export type ClassMemberWithDetails = {
    __typename?: "ClassMemberWithDetails";
    userId: string;
    role: ClassRole;
    sections: Array<string>;
    pending?: boolean | null;
    Name?: string | null;
    pictureUrl?: string | null;
    username?: string | null;
    dpa?: boolean | null;
    lastLogIn?: number | null;
};

export type ClassContext = {
    __typename?: "ClassContext";
    item: Class;
    user: UserDetails;
    badges: Array<BadgeProgress>;
    dailyActivity: DailyActivity;
    level?: number | null;
};

export type UpdateClassInput = {
    classId: string;
    sections?: Array<ClassSectionInput> | null;
    members?: Array<ClassMemberInput> | null;
    pinned?: Array<string> | null;
    updated: string;
    name?: string | null;
    description?: string | null;
    timezone?: string | null;
    schoolId?: string | null;
    grade?: string | null;
    subject?: string | null;
    topic?: string | null;
    exam_v2?: string | null;
    examUnit?: string | null;
    examSection?: string | null;
    icon?: EmojiIconInput | null;
    color?: string | null;
    cover?: string | null;
    googleClassId?: string | null;
    fileCount?: number | null;
    flashcardStudySettings?: FlashcardStudySettingsInput | null;
};

export type DeleteClassInput = {
    classId: string;
};

export type AddMediaS3UserTagInput = {
    bucket: string;
    mediaId: string;
    startProcessing?: boolean | null;
};

export type JoinClassInput = {
    classId: string;
    sections: Array<string>;
};

export type LeaveClassInput = {
    classId: string;
};

export type CreateEmbeddingsInput = {
    input: string;
};

export type CreateExamMCQSessionInput = {
    exam: string;
    examUnits: Array<string>;
    difficulties: Array<ExamDifficultyInput>;
    length: number;
    personalization?: boolean | null;
};

export enum ExamDifficultyInput {
    EASY = "EASY",
    MEDIUM = "MEDIUM",
    HARD = "HARD",
    EXTREME = "EXTREME",
    RANDOM = "RANDOM",
}

export type ExamMCQConnection = {
    __typename?: "ExamMCQConnection";
    items: Array<ExamMCQGroup>;
    nextToken?: string | null;
};

export type ExamMCQGroup = {
    __typename?: "ExamMCQGroup";
    questions: Array<ExamMCQ>;
    stimuli: Array<ExamStimulus>;
};

export type ExamMCQ = {
    __typename?: "ExamMCQ";
    exam: string;
    questionId: string;
    difficulty: ExamDifficulty;
    choices: Array<string>;
    question: string;
    reasons: Array<string>;
    rating: number;
    ratingCount: number;
    examUnit: string;
    examSection?: string | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    subject?: string | null;
    topic?: string | null;
    created: string;
    updated: string;
    url?: string | null;
    prompt: ExamPromptType;
    numCorrect: number;
    numQuestions: number;
    stimuli?: Array<string | null> | null;
    stimulusGroupId?: string | null;
    questionGroupId?: string | null;
    replaced?: boolean | null;
    calc?: number | null;
};

export enum ExamDifficulty {
    EASY = "EASY",
    MEDIUM = "MEDIUM",
    HARD = "HARD",
    EXTREME = "EXTREME",
}

export enum ExamPromptType {
    CONCEPTUAL = "CONCEPTUAL",
    CONCEPTUAL_HISTORY = "CONCEPTUAL_HISTORY",
    EXAMPLE = "EXAMPLE",
    ALL_ARE_TRUE_EXCEPT = "ALL_ARE_TRUE_EXCEPT",
    CAUSE = "CAUSE",
    EFFECT = "EFFECT",
    TRENDS = "TRENDS",
    CHANGE_IN_HISTORY = "CHANGE_IN_HISTORY",
    COMPARE_AND_CONTRAST = "COMPARE_AND_CONTRAST",
    ARGUMENT_FOR_SUPPORTER = "ARGUMENT_FOR_SUPPORTER",
    SUPPORTER_FOR_ARGUMENT = "SUPPORTER_FOR_ARGUMENT",
    SUPPORTING_EVIDENCE = "SUPPORTING_EVIDENCE",
    CONFLICTING_EVIDENCE = "CONFLICTING_EVIDENCE",
    STIMULUS_SPEECH = "STIMULUS_SPEECH",
    STIMULUS_TEXT = "STIMULUS_TEXT",
    STIMULUS_HISTORIAN = "STIMULUS_HISTORIAN",
    EQUATION = "EQUATION",
    EXPERIMENT = "EXPERIMENT",
    REAL_WORLD = "REAL_WORLD",
    EVIDENCE_FOR_CONCLUSION = "EVIDENCE_FOR_CONCLUSION",
    CONCLUSION_FOR_EVIDENCE = "CONCLUSION_FOR_EVIDENCE",
    RANKING = "RANKING",
    EXP_WRONG_1 = "EXP_WRONG_1",
    EXP_WRONG_2 = "EXP_WRONG_2",
    EVENT_EFFECT = "EVENT_EFFECT",
    EVENT_CAUSE = "EVENT_CAUSE",
    COUNTRY_CHOICE = "COUNTRY_CHOICE",
    CHART_CHANGE = "CHART_CHANGE",
    ECON_SIM = "ECON_SIM",
    ECON_DIF = "ECON_DIF",
    ECON_RELATIONSHIP = "ECON_RELATIONSHIP",
    ECON_EQUATION = "ECON_EQUATION",
    TWO_PRONGED = "TWO_PRONGED",
    ECON_EVENT_STIMULUS = "ECON_EVENT_STIMULUS",
    ECON_INDICATOR_STIMULUS = "ECON_INDICATOR_STIMULUS",
    SAQ_HISTORIAN_DISAGREE = "SAQ_HISTORIAN_DISAGREE",
    SAQ_CAUSE_AND_EFFECT = "SAQ_CAUSE_AND_EFFECT",
    SAQ_PRIMARY_SOURCE = "SAQ_PRIMARY_SOURCE",
    SAQ_CONTINUITY_AND_CHANGE = "SAQ_CONTINUITY_AND_CHANGE",
    SAQ_SIMILARITY_AND_DIFFERENCE = "SAQ_SIMILARITY_AND_DIFFERENCE",
    SAQ_CONTINUITY_AND_CHANGE_NO_STIMULUS = "SAQ_CONTINUITY_AND_CHANGE_NO_STIMULUS",
    LEQ_CAUSATION = "LEQ_CAUSATION",
    LEQ_RELATIVE_CAUSES = "LEQ_RELATIVE_CAUSES",
    LEQ_CHANGES_IN_HISTORY = "LEQ_CHANGES_IN_HISTORY",
    DBQ_CAUSATION = "DBQ_CAUSATION",
    DBQ_RELATIVE_CAUSES = "DBQ_RELATIVE_CAUSES",
    DBQ_CHANGES_IN_HISTORY = "DBQ_CHANGES_IN_HISTORY",
    SCOTUS_COMPARISON = "SCOTUS_COMPARISON",
    ARGUMENTATIVE_ESSAY_GOV = "ARGUMENTATIVE_ESSAY_GOV",
    ARGUMENTATIVE_ESSAY_COMP_GOV = "ARGUMENTATIVE_ESSAY_COMP_GOV",
    CONCEPT_APPLICATION_BARRIERS = "CONCEPT_APPLICATION_BARRIERS",
    CONCEPT_APPLICATION_CONSTITUTION = "CONCEPT_APPLICATION_CONSTITUTION",
    CONCEPT_APPLICATION_MISC = "CONCEPT_APPLICATION_MISC",
    CONCEPTUAL_ANALYSIS_TERMS = "CONCEPTUAL_ANALYSIS_TERMS",
    CONCEPTUAL_ANALYSIS_CONCEPTS = "CONCEPTUAL_ANALYSIS_CONCEPTS",
    COMPARATIVE_ANALYSIS = "COMPARATIVE_ANALYSIS",
    CONCEPT_APPLICATION_TIME = "CONCEPT_APPLICATION_TIME",
    CONCEPT_APPLICATION_PERSPECTIVES = "CONCEPT_APPLICATION_PERSPECTIVES",
    CONCEPT_APPLICATION_REFLECTION = "CONCEPT_APPLICATION_REFLECTION",
    RESEARCH_DESIGN = "RESEARCH_DESIGN",
    HUG_NO_STIMULUS = "HUG_NO_STIMULUS",
    DATA_STRUCTURES = "DATA_STRUCTURES",
    PROGRAMMING = "PROGRAMMING",
    CONVERSIONS = "CONVERSIONS",
    EVALUATIONS = "EVALUATIONS",
    IMPACTS = "IMPACTS",
    CONCEPT_RELATIONS = "CONCEPT_RELATIONS",
    CS_JOBS = "CS_JOBS",
    DATA_ANALYSIS = "DATA_ANALYSIS",
    EVALUATE_CODE = "EVALUATE_CODE",
    WHAT_IS_CONTAINED = "WHAT_IS_CONTAINED",
    OOP = "OOP",
    CODE_PURPOSE = "CODE_PURPOSE",
    EVAL_VALUE = "EVAL_VALUE",
    THREE_CHOICES = "THREE_CHOICES",
    BASIC_EQ = "BASIC_EQ",
    WHICH_EQ = "WHICH_EQ",
    HARD_EQ = "HARD_EQ",
    GRAPHING = "GRAPHING",
    TABLES = "TABLES",
    EXP_CHANGE = "EXP_CHANGE",
    STAT_EXP_ERROR_1 = "STAT_EXP_ERROR_1",
    STAT_EXP_ERROR_2 = "STAT_EXP_ERROR_2",
    WHICH_OF_THE_FOLLOWING = "WHICH_OF_THE_FOLLOWING",
    DEFINITION_IN_CONTEXT = "DEFINITION_IN_CONTEXT",
    SYNONYMS = "SYNONYMS",
    GRAMMAR_ERROR = "GRAMMAR_ERROR",
    REWRITE_SENTENCE = "REWRITE_SENTENCE",
    EVIDENCE_TO_SUPPORT = "EVIDENCE_TO_SUPPORT",
    STIMULUS_RHETORICAL_TERMS = "STIMULUS_RHETORICAL_TERMS",
    STIMULUS_POV_ARGUMENT = "STIMULUS_POV_ARGUMENT",
    STIMULUS_TONE = "STIMULUS_TONE",
    STIMULUS_FUNCTION_OF_WORDS = "STIMULUS_FUNCTION_OF_WORDS",
    STIMULUS_EFFECT_OF_PHRASE = "STIMULUS_EFFECT_OF_PHRASE",
    STIMULUS_TRANSITIONS = "STIMULUS_TRANSITIONS",
    STIMULUS_CLARIFY_ARGUMENTATION = "STIMULUS_CLARIFY_ARGUMENTATION",
    STIMULUS_REWRITE_SENTENCES = "STIMULUS_REWRITE_SENTENCES",
    STIMULUS_REFUTE_CLAIMS = "STIMULUS_REFUTE_CLAIMS",
    APHUG_CONCEPTUAL = "APHUG_CONCEPTUAL",
    APHUG_ELIMINATION = "APHUG_ELIMINATION",
    APHUG_EXAMPLE = "APHUG_EXAMPLE",
    APHUG_MODEL = "APHUG_MODEL",
    APHUG_CONCEPT_APPLICATION = "APHUG_CONCEPT_APPLICATION",
    APHUG_CAUSE_EFFECT = "APHUG_CAUSE_EFFECT",
    APHUG_COMPARISON = "APHUG_COMPARISON",
    APHUG_PROCESS_SEQUENCE = "APHUG_PROCESS_SEQUENCE",
    APHUG_SPATIAL_RELATIONSHIP = "APHUG_SPATIAL_RELATIONSHIP",
    APHUG_HYPOTHETICAL = "APHUG_HYPOTHETICAL",
    APHUG_PREDICTIVE_TREND = "APHUG_PREDICTIVE_TREND",
    APCSA_CODE_OUTPUT = "APCSA_CODE_OUTPUT",
    APCSA_MISSING_LINE = "APCSA_MISSING_LINE",
    APCSA_SYNTAX_ERROR = "APCSA_SYNTAX_ERROR",
    APCSA_EXPRESSION_EVAL = "APCSA_EXPRESSION_EVAL",
    APCSA_DEBUG_LOGIC = "APCSA_DEBUG_LOGIC",
    APCSA_LOOP_COUNT = "APCSA_LOOP_COUNT",
    APCSA_RECURSION = "APCSA_RECURSION",
    APCSA_ALGORITHM_EFFICIENCY = "APCSA_ALGORITHM_EFFICIENCY",
    APCSA_INHERITANCE = "APCSA_INHERITANCE",
    APCSA_SORTING = "APCSA_SORTING",
    APCSA_DATA_STRUCTURES = "APCSA_DATA_STRUCTURES",
    APCSA_CONCURRENCY = "APCSA_CONCURRENCY",
    ECON_SUPPLY_DEMAND_GRAPH = "ECON_SUPPLY_DEMAND_GRAPH",
    ECON_AGGREGATE_SUPPLY_DEMAND_GRAPH = "ECON_AGGREGATE_SUPPLY_DEMAND_GRAPH",
    ECON_PPF_GRAPH = "ECON_PPF_GRAPH",
    ECON_SURPLUS_GRAPH = "ECON_SURPLUS_GRAPH",
    ECON_PRICE_CONTROL_GRAPH = "ECON_PRICE_CONTROL_GRAPH",
    ECON_TAX_NO_EXTERNALITY_GRAPH = "ECON_TAX_NO_EXTERNALITY_GRAPH",
    ECON_TAX_NEGATIVE_EXTERNALITY_GRAPH = "ECON_TAX_NEGATIVE_EXTERNALITY_GRAPH",
    ECON_CONSUMER_SUBSIDY_POSITIVE_EXTERNALITY_GRAPH = "ECON_CONSUMER_SUBSIDY_POSITIVE_EXTERNALITY_GRAPH",
    ECON_PRODUCER_SUBSIDY_POSITIVE_EXTERNALITY_GRAPH = "ECON_PRODUCER_SUBSIDY_POSITIVE_EXTERNALITY_GRAPH",
    ECON_TWO_PRONGED_COMPARISON = "ECON_TWO_PRONGED_COMPARISON",
    ECON_GRAPH_TABLE_GENERAL = "ECON_GRAPH_TABLE_GENERAL",
    ECON_MONEY_MARKET_GRAPH = "ECON_MONEY_MARKET_GRAPH",
    ECON_INTERNATIONAL_TRADE_TARIFF_GRAPH = "ECON_INTERNATIONAL_TRADE_TARIFF_GRAPH",
    ECON_FIRM_COST_CURVE_GRAPH = "ECON_FIRM_COST_CURVE_GRAPH",
    ECON_MARKET_STRUCTURES_GRAPH = "ECON_MARKET_STRUCTURES_GRAPH",
    ECON_LORENZ_CURVE_GRAPH = "ECON_LORENZ_CURVE_GRAPH",
    ECON_LOANABLE_FUNDS_MARKET_GRAPH = "ECON_LOANABLE_FUNDS_MARKET_GRAPH",
    ECON_PHILLIPS_CURVE_GRAPH = "ECON_PHILLIPS_CURVE_GRAPH",
    ECON_FOREIGN_EXCHANGE_MARKET_GRAPH = "ECON_FOREIGN_EXCHANGE_MARKET_GRAPH",
    ECON_BUSINESS_CYCLE_GRAPH = "ECON_BUSINESS_CYCLE_GRAPH",
    SCIENCE_EXPERIMENTAL_DESIGN_EVALUATION_FRQ = "SCIENCE_EXPERIMENTAL_DESIGN_EVALUATION_FRQ",
    SCIENCE_EXPERIMENTAL_DATA_GRAPHING_FRQ = "SCIENCE_EXPERIMENTAL_DATA_GRAPHING_FRQ",
    SCIENCE_INVESTIGATION_ANALYSIS_FRQ = "SCIENCE_INVESTIGATION_ANALYSIS_FRQ",
    SCIENCE_CONCEPTUAL_ANALYSIS_FRQ = "SCIENCE_CONCEPTUAL_ANALYSIS_FRQ",
    SCIENCE_VISUAL_MODEL_ANALYSIS_FRQ = "SCIENCE_VISUAL_MODEL_ANALYSIS_FRQ",
    SCIENCE_DATA_ANALYSIS_FRQ = "SCIENCE_DATA_ANALYSIS_FRQ",
    SCIENCE_QUAL_QUANT_TRANSLATION_FRQ = "SCIENCE_QUAL_QUANT_TRANSLATION_FRQ",
    SCIENCE_EXPERIMENTAL_ERROR_ANALYSIS_FRQ = "SCIENCE_EXPERIMENTAL_ERROR_ANALYSIS_FRQ",
    SCIENCE_EVIDENCE_CONCLUSION_EVALUATION_FRQ = "SCIENCE_EVIDENCE_CONCLUSION_EVALUATION_FRQ",
    SCIENCE_MATHEMATICAL_APPLICATION_FRQ = "SCIENCE_MATHEMATICAL_APPLICATION_FRQ",
    SCIENCE_REAL_WORLD_ANALYSIS_FRQ = "SCIENCE_REAL_WORLD_ANALYSIS_FRQ",
    SCIENCE_CONCEPT_SYNTHESIS_FRQ = "SCIENCE_CONCEPT_SYNTHESIS_FRQ",
    SCIENCE_CLAIM_CRITICAL_ANALYSIS_FRQ = "SCIENCE_CLAIM_CRITICAL_ANALYSIS_FRQ",
    SCIENCE_EXPERIMENT_DESIGN_FRQ = "SCIENCE_EXPERIMENT_DESIGN_FRQ",
    AP_ECON_LONG_AGGREGATE_DEMAND_SUPPLY = "AP_ECON_LONG_AGGREGATE_DEMAND_SUPPLY",
    AP_ECON_SHORT_MONEY_MARKET_POLICY = "AP_ECON_SHORT_MONEY_MARKET_POLICY",
    AP_ECON_LONG_FISCAL_POLICY_TRADE = "AP_ECON_LONG_FISCAL_POLICY_TRADE",
    AP_ECON_LONG_GDP_INFLATION_INDICATORS = "AP_ECON_LONG_GDP_INFLATION_INDICATORS",
    AP_ECON_SHORT_UNEMPLOYMENT_LABOR_MARKET = "AP_ECON_SHORT_UNEMPLOYMENT_LABOR_MARKET",
    AP_ECON_SHORT_LOANABLE_FUNDS = "AP_ECON_SHORT_LOANABLE_FUNDS",
    AP_ECON_SHORT_BUSINESS_CYCLE = "AP_ECON_SHORT_BUSINESS_CYCLE",
    AP_ECON_LONG_MACRO_POLICY_RESPONSE = "AP_ECON_LONG_MACRO_POLICY_RESPONSE",
    AP_ECON_SHORT_SUPPLY_SHOCK = "AP_ECON_SHORT_SUPPLY_SHOCK",
    AP_ECON_SHORT_EXCHANGE_RATE_CAPITAL = "AP_ECON_SHORT_EXCHANGE_RATE_CAPITAL",
    AP_ECON_SHORT_COMPETITIVE_FIRM = "AP_ECON_SHORT_COMPETITIVE_FIRM",
    AP_ECON_SHORT_MARKET_DEADWEIGHT_LOSS = "AP_ECON_SHORT_MARKET_DEADWEIGHT_LOSS",
    AP_ECON_SHORT_ELASTICITY = "AP_ECON_SHORT_ELASTICITY",
    AP_ECON_SHORT_GAME_THEORY = "AP_ECON_SHORT_GAME_THEORY",
    AP_ECON_LONG_PRODUCTION_COST = "AP_ECON_LONG_PRODUCTION_COST",
    AP_ECON_SHORT_MONOPOLY_PRICE_DISCRIMINATION = "AP_ECON_SHORT_MONOPOLY_PRICE_DISCRIMINATION",
    AP_ECON_SHORT_EXTERNALITIES = "AP_ECON_SHORT_EXTERNALITIES",
    AP_ECON_SHORT_SURPLUS_EVALUATION = "AP_ECON_SHORT_SURPLUS_EVALUATION",
    AP_ECON_SHORT_PRICE_CONTROLS = "AP_ECON_SHORT_PRICE_CONTROLS",
    AP_ECON_SHORT_LONG_RUN_ADJUSTMENTS = "AP_ECON_SHORT_LONG_RUN_ADJUSTMENTS",
    AP_CALC_TABLE_FUNCTION_ANALYSIS = "AP_CALC_TABLE_FUNCTION_ANALYSIS",
    AP_CALC_PARTICLE_MOTION = "AP_CALC_PARTICLE_MOTION",
    AP_CALC_RATE_APPLICATIONS = "AP_CALC_RATE_APPLICATIONS",
    AP_CALC_AREA_VOLUME = "AP_CALC_AREA_VOLUME",
    AP_CALC_SERIES = "AP_CALC_SERIES",
    AP_CALC_FUNCTION_PROPERTIES = "AP_CALC_FUNCTION_PROPERTIES",
    AP_CALC_IMPLICIT_DIFFERENTIATION = "AP_CALC_IMPLICIT_DIFFERENTIATION",
    AP_CALC_PARAMETRIC_POLAR = "AP_CALC_PARAMETRIC_POLAR",
    AP_CALC_TAYLOR_SERIES = "AP_CALC_TAYLOR_SERIES",
    AP_CALC_OPTIMIZATION = "AP_CALC_OPTIMIZATION",
    AP_CALC_LIMITS_CONTINUITY = "AP_CALC_LIMITS_CONTINUITY",
    AP_CALC_INVERSES_TRANSFORMATIONS = "AP_CALC_INVERSES_TRANSFORMATIONS",
    AP_CALC_LOG_EXP_EQUATIONS = "AP_CALC_LOG_EXP_EQUATIONS",
    AP_CALC_FUNCTION_BEHAVIOR = "AP_CALC_FUNCTION_BEHAVIOR",
    AP_CALC_SEQUENCES_SERIES = "AP_CALC_SEQUENCES_SERIES",
    AP_PRECALC_SYSTEMS = "AP_PRECALC_SYSTEMS",
    AP_PRECALC_TRIG_ANALYSIS = "AP_PRECALC_TRIG_ANALYSIS",
    AP_PRECALC_PROBABILITY = "AP_PRECALC_PROBABILITY",
    AP_STAT_DESCRIPTIVE_ANALYSIS = "AP_STAT_DESCRIPTIVE_ANALYSIS",
    AP_STAT_REGRESSION_ANALYSIS = "AP_STAT_REGRESSION_ANALYSIS",
    AP_STAT_EXPERIMENTAL_DESIGN = "AP_STAT_EXPERIMENTAL_DESIGN",
    AP_STAT_PROBABILITY_MODELS = "AP_STAT_PROBABILITY_MODELS",
    AP_STAT_COMPARATIVE_INFERENCE = "AP_STAT_COMPARATIVE_INFERENCE",
    AP_STAT_SAMPLING_DISTRIBUTIONS = "AP_STAT_SAMPLING_DISTRIBUTIONS",
    AP_STAT_REGRESSION_INFERENCE = "AP_STAT_REGRESSION_INFERENCE",
    AP_STAT_CATEGORICAL_ANALYSIS = "AP_STAT_CATEGORICAL_ANALYSIS",
    AP_STAT_SINGLE_PARAMETER = "AP_STAT_SINGLE_PARAMETER",
    AP_STAT_INVESTIGATIVE_TASK = "AP_STAT_INVESTIGATIVE_TASK",
    AP_STAT_MULTIFACETED_ANALYSIS = "AP_STAT_MULTIFACETED_ANALYSIS",
    APCSA_MCS_ONE_MANY = "APCSA_MCS_ONE_MANY",
    APCSA_MCS_SEARCH_SORT = "APCSA_MCS_SEARCH_SORT",
    APCSA_MCS_HELPER_MAIN = "APCSA_MCS_HELPER_MAIN",
    APCSA_CLASS_STATIC = "APCSA_CLASS_STATIC",
    APCSA_CLASS_GET_SET = "APCSA_CLASS_GET_SET",
    APCSA_CLASS_ALGO = "APCSA_CLASS_ALGO",
    APCSA_CLASS_INHERITANCE = "APCSA_CLASS_INHERITANCE",
    APCSA_ARRAYS_DATA_PROCESSING = "APCSA_ARRAYS_DATA_PROCESSING",
    APCSA_ARRAYS_SORT_SEARCH = "APCSA_ARRAYS_SORT_SEARCH",
    APCSA_ARRAYS_ALGOS = "APCSA_ARRAYS_ALGOS",
    APCSA_TWOD_ARRAYS_TRAVERSAL = "APCSA_TWOD_ARRAYS_TRAVERSAL",
    APCSA_TWOD_ARRAYS_IMAGES = "APCSA_TWOD_ARRAYS_IMAGES",
    APCSA_TWOD_ARRAYS_SIMULATION = "APCSA_TWOD_ARRAYS_SIMULATION",
    APCSA_PATTERN_DETECTION = "APCSA_PATTERN_DETECTION",
}

export type ExamStimulus = {
    __typename?: "ExamStimulus";
    questionId: string;
    stimulusId: string;
    content: string;
    stimulusType: ExamStimulusType;
    schema?: string | null;
};

export enum ExamStimulusType {
    TEXT = "TEXT",
    CODE = "CODE",
    IMAGE = "IMAGE",
    GRAPH = "GRAPH",
    TABLE = "TABLE",
}

export type RateExamMCQInput = {
    questionId: string;
    rating: number;
};

export type RateExamFRQInput = {
    questionId: string;
    partIdx: number;
    rating: number;
};

export type ExamFRQ = {
    __typename?: "ExamFRQ";
    exam: string;
    questionId: string;
    difficulty: ExamDifficulty;
    question: string;
    rating: number;
    ratingCount: number;
    examUnit: string;
    examSection?: string | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    subject?: string | null;
    topic?: string | null;
    created: string;
    updated: string;
    url?: string | null;
    prompt: ExamPromptType;
    stimuli?: Array<string> | null;
    name: string;
    parts: Array<ExamFRQPart>;
    rubric: Array<ExamFRQRubricParts>;
    calc?: number | null;
    starred: boolean;
    frqType?: ExamFRQType | null;
};

export type ExamFRQPart = {
    __typename?: "ExamFRQPart";
    question: string;
    rating: number;
    ratingCount: number;
    stimuli?: Array<string> | null;
    prefill?: string | null;
};

export type ExamFRQRubricParts = {
    __typename?: "ExamFRQRubricParts";
    items: Array<ExamFRQRubricItems>;
};

export type ExamFRQRubricItems = {
    __typename?: "ExamFRQRubricItems";
    guidelines: string;
    points: number;
};

export enum ExamFRQType {
    SAQ = "SAQ",
    LEQ = "LEQ",
    DBQ = "DBQ",
    AP_SCIENCE_FRQ_GENERIC = "AP_SCIENCE_FRQ_GENERIC",
    AP_CALC_NO_CALC = "AP_CALC_NO_CALC",
    AP_CALC_WITH_CALC = "AP_CALC_WITH_CALC",
    AP_STATS_GENERIC = "AP_STATS_GENERIC",
    AP_STATS_INVESTIGATIVE = "AP_STATS_INVESTIGATIVE",
    AP_ECON_SHORT = "AP_ECON_SHORT",
    AP_ECON_LONG = "AP_ECON_LONG",
    CONCEPT_APPLICATION = "CONCEPT_APPLICATION",
    QUANTITATIVE_ANALYSIS = "QUANTITATIVE_ANALYSIS",
    SCOTUS_COMPARISON = "SCOTUS_COMPARISON",
    ARGUMENTATIVE_ESSAY = "ARGUMENTATIVE_ESSAY",
    HUG_NO_STIMULUS = "HUG_NO_STIMULUS",
    COMPARATIVE_ANALYSIS = "COMPARATIVE_ANALYSIS",
    APCSA_GENERIC = "APCSA_GENERIC",
    PSYCH_CONCEPT_APPLICATION = "PSYCH_CONCEPT_APPLICATION",
    PSYCH_RESEARCH_DESIGN = "PSYCH_RESEARCH_DESIGN",
    APCSA_MCS = "APCSA_MCS",
    APCSA_CLASS_DESIGN = "APCSA_CLASS_DESIGN",
    APCSA_ARRAYS = "APCSA_ARRAYS",
    APCSA_TWOD_ARRAYS = "APCSA_TWOD_ARRAYS",
}

export type SubmitExamMCQSessionInput = {
    exam: string;
    questions: Array<ExamMCQSubmission>;
};

export type ExamMCQSubmission = {
    questionId: string;
    isCorrect: boolean;
};

export type ExamProgress = {
    __typename?: "ExamProgress";
    userId: string;
    exam: string;
    numQuestions: number;
    numCorrect: number;
    mastery: number;
    numDownvotes?: number | null;
    unitProgress: Array<ExamUnitProgressMap>;
};

export type ExamUnitProgressMap = {
    __typename?: "ExamUnitProgressMap";
    examUnit: string;
    progress: ExamUnitProgress;
};

export type ExamUnitProgress = {
    __typename?: "ExamUnitProgress";
    numQuestions: number;
    numCorrect: number;
    mastery: number;
};

export type ReferOrganizationInput = {
    sent: boolean;
    type: string;
    email: string;
};

export type OrganizationContext = {
    __typename?: "OrganizationContext";
    item: Organization;
    user: UserDetails;
    badges: Array<BadgeProgress>;
    dailyActivity: DailyActivity;
    level?: number | null;
};

export type Organization = {
    __typename?: "Organization";
    org: string;
    schoolId: string;
    created: string;
    updated: string;
    name?: string | null;
    pictureUrl?: string | null;
    country?: string | null;
    state?: string | null;
    files?: Array<OrganizationFile> | null;
    students: number;
    teachers: number;
    syncTimestamp?: number | null;
    isDirty?: boolean | null;
    paywall?: OrganizationPaywallInfo | null;
    aliases?: Array<string> | null;
    stage: OrganizationStage;
    referrals: Array<OrganizationReferral>;
    dpa?: OrganizationDPA | null;
    admins?: Array<OrganizationAdmin> | null;
    pendingAdmins?: Array<PendingOrganizationAdmin> | null;
    settings?: OrganizationSettings | null;
    schoolsSettings?: Array<OrganizationSettings> | null;
    verifiedSchools?: boolean | null;
    subscriptions?: Array<OrganizationStripeConnection> | null;
    customerId?: string | null;
    provider?: EdLinkProvider | null;
    elDistrictId?: string | null;
    elIntegrationId?: string | null;
};

export type OrganizationFile = {
    __typename?: "OrganizationFile";
    url: string;
    name: string;
};

export type OrganizationPaywallInfo = {
    __typename?: "OrganizationPaywallInfo";
    count: number;
    lastSent: number;
};

export enum OrganizationStage {
    NONE = "NONE",
    EMAIL_SENT = "EMAIL_SENT",
    STUDENT_INVITED = "STUDENT_INVITED",
    DPA_PENDING = "DPA_PENDING",
    DPA_SIGNED = "DPA_SIGNED",
    SUBSCRIBED = "SUBSCRIBED",
}

export type OrganizationReferral = {
    __typename?: "OrganizationReferral";
    userId: string;
    created: string;
    sent: boolean;
    type: string;
    email: string;
};

export type OrganizationDPA = {
    __typename?: "OrganizationDPA";
    name?: string | null;
    email?: string | null;
    link?: string | null;
    type: DPAType;
};

export enum DPAType {
    USE = "USE",
    NONE = "NONE",
}

export type OrganizationAdmin = {
    __typename?: "OrganizationAdmin";
    userId: string;
    schoolId: string;
};

export type PendingOrganizationAdmin = {
    __typename?: "PendingOrganizationAdmin";
    Email: string;
    schoolId: string;
};

export type OrganizationSettings = {
    __typename?: "OrganizationSettings";
    schoolId?: string | null;
    sharing: OrganizationSharing;
    subsAllowed: boolean;
    ai?: boolean | null;
};

export enum OrganizationSharing {
    PRIVATE = "PRIVATE",
    PUBLIC_ORG = "PUBLIC_ORG",
    PUBLIC = "PUBLIC",
}

export type OrganizationStripeConnection = {
    __typename?: "OrganizationStripeConnection";
    schoolId: string;
    created: number;
    current_period_end: number;
    studentPlan: SubscriptionType;
    teacherPlan: SubscriptionType;
    studentsPaidFor: number;
    teachersPaidFor: number;
    paidStudents: number;
    paidTeachers: number;
    autoSubsStudent: boolean;
    autoSubsTeacher: boolean;
};

export enum EdLinkProvider {
    CLASSLINK = "CLASSLINK",
    SCHOOLOGY = "SCHOOLOGY",
    CLASSROOM = "CLASSROOM",
    TEAMS = "TEAMS",
    CLEVER = "CLEVER",
    CANVAS = "CANVAS",
    BRIGHTSPACE = "BRIGHTSPACE",
    BLACKBOARD = "BLACKBOARD",
}

export type SelectSchoolInput = {
    schoolId: string;
    schoolRole?: SchoolRole | null;
};

export enum SchoolRole {
    TEACHER = "TEACHER",
    IT_ADMIN = "IT_ADMIN",
    ADMIN = "ADMIN",
    PRINCIPAL = "PRINCIPAL",
}

export type UserAndOrganization = {
    __typename?: "UserAndOrganization";
    user?: UserDetails | null;
    organization?: Organization | null;
};

export type MigrateAccountContentInput = {
    stage?: boolean | null;
    userId?: string | null;
    commit?: boolean | null;
    migKey?: string | null;
};

export type TrackOrganizationPaywallInput = {
    event?: UpgradeEvent | null;
};

export type AuthenticateEdLinkSSOInput = {
    code: string;
    linkingUserId?: string | null;
    redirectUri: string;
};

export type EdLinkAuth = {
    __typename?: "EdLinkAuth";
    username: string;
    password: string;
    user?: UserDetails | null;
    didLink?: boolean | null;
};

export type PurchaseStoreProductInput = {
    storeProductId: StoreProductEnum;
};

export type StoreProductPurchaseConnection = {
    __typename?: "StoreProductPurchaseConnection";
    storeProductHistory: StoreProductHistory;
    user: UserDetails;
};

export type StoreProductHistory = {
    __typename?: "StoreProductHistory";
    userId: string;
    storeProductId: StoreProductEnum;
    current: number;
    bought: number;
    price?: number | null;
    code?: string | null;
    discount?: number | null;
    expiryTime?: number | null;
};

export type ClaimBadgeRewardInput = {
    badge: BadgeEnum;
    tiers: Array<number>;
};

export type GamifyContext = {
    __typename?: "GamifyContext";
    user: UserDetails;
    badges: Array<BadgeProgress>;
    dailyActivity: DailyActivity;
    level?: number | null;
    gameError?: string | null;
};

export type TrackActivityInput = {
    action: ActionEnum;
    itemId?: string | null;
    type?: ItemType | null;
    upgradeEvent?: UpgradeEvent | null;
};

export type ActivityConnection = {
    __typename?: "ActivityConnection";
    user: UserDetails;
    dailyActivity: DailyActivity;
    badges: Array<BadgeProgress>;
    level?: number | null;
};

export type ApplyStreakActionInput = {
    action: ActionEnum;
};

export type ActivateStoreProductInput = {
    storeProductId: StoreProductEnum;
};

export type SimulateStreakInput = {
    xp?: number | null;
    streakDays?: number | null;
    streakActions?: Array<ActionEnum | null> | null;
};

export type SimulateStreakActionsInput = {
    actions: Array<SimulateAction>;
};

export type SimulateAction = {
    action: ActionEnum;
    count: number;
};

export type NotificationConnection = {
    __typename?: "NotificationConnection";
    items: Array<Notification>;
    nextToken?: string | null;
};

export type Notification = {
    __typename?: "Notification";
    userId: string;
    notificationId: string;
    read: number;
    created: string;
    color?: string | null;
    image?: string | null;
    title: string;
    message: string;
    action: string;
    type: NotificationType;
};

export enum NotificationType {
    STREAK_REMINDER = "STREAK_REMINDER",
    STREAK_MILESTONE_REMINDER = "STREAK_MILESTONE_REMINDER",
    STREAK_FREEZE_OR_DEAD = "STREAK_FREEZE_OR_DEAD",
    LONG_PAUSE_REMINDER = "LONG_PAUSE_REMINDER",
    DAILY_PRACTICE_REMINDER = "DAILY_PRACTICE_REMINDER",
    LEARN_MODE_REMINDER = "LEARN_MODE_REMINDER",
    SPACED_REP_REMINDER = "SPACED_REP_REMINDER",
    MASTERY_OF_SET_REMINDER = "MASTERY_OF_SET_REMINDER",
    QUEST_REMINDER = "QUEST_REMINDER",
    LEVEL_UP_REMINDER = "LEVEL_UP_REMINDER",
    INACTIVITY_NOTIFICATION = "INACTIVITY_NOTIFICATION",
    NEW_FOLLOWER = "NEW_FOLLOWER",
    FRIEND_LEVEL_UP = "FRIEND_LEVEL_UP",
    FRIEND_STREAK_REACHED = "FRIEND_STREAK_REACHED",
    STREAK_MILESTONE_ADD_FRIENDS = "STREAK_MILESTONE_ADD_FRIENDS",
    STREAK_MILESTONE_REFER_FRIENDS = "STREAK_MILESTONE_REFER_FRIENDS",
    FRIEND_SUCCESSFULLY_REFERRED = "FRIEND_SUCCESSFULLY_REFERRED",
    STORE_PURCHASE = "STORE_PURCHASE",
    AI_USAGE_MEDIA = "AI_USAGE_MEDIA",
    AI_USAGE_BRAINSTORM_ASSISTANT = "AI_USAGE_BRAINSTORM_ASSISTANT",
    AI_USAGE_EXPLAIN_WRONG = "AI_USAGE_EXPLAIN_WRONG",
    AI_USAGE_EXPLAIN_FRQ = "AI_USAGE_EXPLAIN_FRQ",
    AI_USAGE_FLASHCARDS_FROM_NOTES = "AI_USAGE_FLASHCARDS_FROM_NOTES",
    AI_USAGE_TESTS_FROM_NOTES = "AI_USAGE_TESTS_FROM_NOTES",
    AI_USAGE_CHAT_WITH_AI = "AI_USAGE_CHAT_WITH_AI",
    AI_USAGE_PLANS_PAGE = "AI_USAGE_PLANS_PAGE",
    AI_USAGE_CANCELLATION = "AI_USAGE_CANCELLATION",
    CLASS_FILE_ADDED = "CLASS_FILE_ADDED",
    STUDENT_MOVED_SECTIONS = "STUDENT_MOVED_SECTIONS",
    CLASS_REMOVED = "CLASS_REMOVED",
    CLASS_DELETED = "CLASS_DELETED",
    STUDENT_JOINED = "STUDENT_JOINED",
    CLASS_MASTERY_50 = "CLASS_MASTERY_50",
    CLASS_MASTERY_100 = "CLASS_MASTERY_100",
}

export type ToggleReadNotificationInput = {
    notificationId: string;
    currentRead: number;
};

export type BatchNotificationInput = {
    notificationIds: Array<string>;
};

export type VerifiedSchoolInput = {
    type: VerifiedSchoolType;
    schoolId: string;
    org: string;
    verified?: boolean | null;
    name: string;
    country: string;
    state: string;
    city: string;
    updated: string;
    address?: AddressInput | null;
    aliases?: Array<string> | null;
    gradesLow?: string | null;
    gradesHigh?: string | null;
    website?: string | null;
    phone?: string | null;
};

export enum VerifiedSchoolType {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE",
    ADULT_EDUCATION = "ADULT_EDUCATION",
    CHARTER = "CHARTER",
    JUNIOR_COLLEGE = "JUNIOR_COLLEGE",
    TECHNICAL_COLLEGE = "TECHNICAL_COLLEGE",
    BUSINESS_COLLEGE = "BUSINESS_COLLEGE",
    COSMETOLOGY = "COSMETOLOGY",
    COMPUTER_TRAINING = "COMPUTER_TRAINING",
    FLIGHT = "FLIGHT",
    FINE_ARTS = "FINE_ARTS",
    UNIVERSITY = "UNIVERSITY",
    EDUCATIONAL_SUPPORT = "EDUCATIONAL_SUPPORT",
}

export type AddressInput = {
    streetOne: string;
    streetTwo?: string | null;
    city: string;
    county?: string | null;
    state: string;
    zip: string;
    country: string;
};

export type VerifiedSchool = {
    __typename?: "VerifiedSchool";
    type: VerifiedSchoolType;
    schoolId: string;
    org?: string | null;
    verified?: boolean | null;
    name: string;
    country: string;
    state: string;
    city: string;
    updated: string;
    address?: Address | null;
    mailing?: Address | null;
    population?: SchoolPopulation | null;
    aliases?: Array<string> | null;
    gradesLow?: string | null;
    gradesHigh?: string | null;
    website?: string | null;
    phone?: string | null;
    elSchoolId?: string | null;
};

export type Address = {
    __typename?: "Address";
    streetOne: string;
    streetTwo?: string | null;
    city: string;
    county?: string | null;
    state: string;
    zip: string;
    country: string;
};

export type SchoolPopulation = {
    __typename?: "SchoolPopulation";
    teachers: number;
    ft: number;
    pt: number;
};

export type CreateAssignmentInput = {
    userId: string;
    assignmentId: string;
    classId?: string | null;
    addedAt?: string | null;
    config?: string | null;
    sections?: Array<string> | null;
    files?: Array<AssignmentFileInput> | null;
    status: AssignmentStatus;
    deadline?: number | null;
    type: AssignmentType;
    settings: AssignmentSettingsInput;
    created: string;
    updated: string;
    feedback?: AssignmentFeedbackInput | null;
    title: string;
    description: string;
    instructions: string;
    image?: string | null;
    public: boolean;
    password?: string | null;
    trash?: boolean | null;
    folderId?: string | null;
    subject?: string | null;
    topic?: string | null;
};

export type AssignmentFileInput = {
    itemId: string;
    itemType: ItemType;
};

export enum AssignmentStatus {
    DRAFT = "DRAFT",
    IN_PROGRESS = "IN_PROGRESS",
    GRADING = "GRADING",
    COMPLETED = "COMPLETED",
}

export enum AssignmentType {
    CHAT = "CHAT",
    QUIZ = "QUIZ",
    EXAM = "EXAM",
}

export type AssignmentSettingsInput = {
    timeLimit?: number | null;
    standards?: Array<string> | null;
    grade: string;
    maxSubmissions?: number | null;
    language: Language;
    inputMethods: Array<AssignmentMethodEnum>;
    outputMethods: Array<AssignmentMethodEnum>;
    autoGrade: boolean;
    rubric: AssignmentRubricInput;
    helpfulness: HelpfulnessEnum;
    length?: AssignmentLengthEnum | null;
    priorKnowledge: Array<string>;
    rules: Array<string>;
    suggestions: Array<string>;
    initialPrompt: string;
};

export enum AssignmentMethodEnum {
    TEXT = "TEXT",
    IMAGE = "IMAGE",
    AUDIO = "AUDIO",
}

export type AssignmentRubricInput = {
    A?: AssignmentRubricGradeInput | null;
    B?: AssignmentRubricGradeInput | null;
    C?: AssignmentRubricGradeInput | null;
    D?: AssignmentRubricGradeInput | null;
    F?: AssignmentRubricGradeInput | null;
};

export type AssignmentRubricGradeInput = {
    score?: number | null;
    condition?: string | null;
};

export enum HelpfulnessEnum {
    NONE = "NONE",
    ASK_QUESTIONS = "ASK_QUESTIONS",
    CLUES = "CLUES",
    SOLUTIONS = "SOLUTIONS",
}

export enum AssignmentLengthEnum {
    SHORT = "SHORT",
    MEDIUM = "MEDIUM",
    LONG = "LONG",
}

export type AssignmentFeedbackInput = {
    strength: string;
    improvement: string;
    followUp: string;
    comments?: string | null;
};

export type AssignmentContext = {
    __typename?: "AssignmentContext";
    item: Assignment;
    user: UserDetails;
    badges: Array<BadgeProgress>;
    dailyActivity: DailyActivity;
    level?: number | null;
};

export type Assignment = {
    __typename?: "Assignment";
    userId: string;
    assignmentId: string;
    classId?: string | null;
    addedAt?: string | null;
    config?: string | null;
    files?: Array<AssignmentFile> | null;
    sections?: Array<string> | null;
    partial?: boolean | null;
    status: AssignmentStatus;
    deadline?: number | null;
    type: AssignmentType;
    settings: AssignmentSettings;
    created: string;
    updated: string;
    feedback?: AssignmentFeedback | null;
    title: string;
    description: string;
    instructions: string;
    image?: string | null;
    icon?: EmojiIcon | null;
    public: boolean;
    password?: string | null;
    trash?: boolean | null;
    folderId?: string | null;
    subject?: string | null;
    topic?: string | null;
    numCompleted: number;
    views?: number | null;
};

export type AssignmentFile = {
    __typename?: "AssignmentFile";
    itemId: string;
    itemType: ItemType;
};

export type AssignmentSettings = {
    __typename?: "AssignmentSettings";
    timeLimit?: number | null;
    standards?: Array<string> | null;
    grade: string;
    maxSubmissions?: number | null;
    language: Language;
    inputMethods: Array<AssignmentMethodEnum>;
    outputMethods: Array<AssignmentMethodEnum>;
    autoGrade: boolean;
    rubric: AssignmentRubric;
    helpfulness: HelpfulnessEnum;
    length?: AssignmentLengthEnum | null;
    priorKnowledge: Array<string>;
    rules: Array<string>;
    suggestions: Array<string>;
    initialPrompt: string;
};

export type AssignmentRubric = {
    __typename?: "AssignmentRubric";
    A?: AssignmentRubricGrade | null;
    B?: AssignmentRubricGrade | null;
    C?: AssignmentRubricGrade | null;
    D?: AssignmentRubricGrade | null;
    F?: AssignmentRubricGrade | null;
};

export type AssignmentRubricGrade = {
    __typename?: "AssignmentRubricGrade";
    score?: number | null;
    condition?: string | null;
};

export type AssignmentFeedback = {
    __typename?: "AssignmentFeedback";
    strength: string;
    improvement: string;
    followUp: string;
    comments?: string | null;
};

export type UpdateAssignmentInput = {
    userId: string;
    assignmentId: string;
    classId?: string | null;
    addedAt?: string | null;
    config?: string | null;
    sections?: Array<string> | null;
    files?: Array<AssignmentFileInput> | null;
    status?: AssignmentStatus | null;
    deadline?: number | null;
    type?: AssignmentType | null;
    settings?: AssignmentSettingsInput | null;
    created?: string | null;
    updated?: string | null;
    feedback?: AssignmentFeedbackInput | null;
    title?: string | null;
    description?: string | null;
    instructions?: string | null;
    image?: string | null;
    public?: boolean | null;
    password?: string | null;
    trash?: boolean | null;
    folderId?: string | null;
    subject?: string | null;
    topic?: string | null;
};

export type DeleteAssignmentInput = {
    userId: string;
    assignmentId: string;
};

export type CreateAssignmentSessionInput = {
    assignmentId: string;
    sessionId: string;
    userId?: string | null;
    status?: AssignmentStatus | null;
    created?: string | null;
    updated?: string | null;
    submitted?: string | null;
    score?: number | null;
    rubric?: Array<string> | null;
    feedback?: AssignmentFeedbackInput | null;
};

export type AssignmentSession = {
    __typename?: "AssignmentSession";
    assignmentId: string;
    sessionId: string;
    userId: string;
    status: AssignmentStatus;
    created: string;
    updated: string;
    submitted?: string | null;
    duration?: number | null;
    score?: number | null;
    rubric?: Array<string> | null;
    feedback?: AssignmentFeedback | null;
};

export type UpdateAssignmentSessionInput = {
    assignmentId: string;
    sessionId: string;
    status?: AssignmentStatus | null;
    created?: string | null;
    updated?: string | null;
    submitted?: string | null;
    duration?: number | null;
    score?: number | null;
    rubric?: Array<string> | null;
    feedback?: AssignmentFeedbackInput | null;
};

export type CreateAssignmentFieldsInput = {
    config: string;
    grade: string;
    files: Array<AssignmentFileInput>;
};

export type AssignmentFields = {
    __typename?: "AssignmentFields";
    title: string;
    description: string;
    instructions: string;
    subject: string;
    topic: string;
    settings: AssignmentSettings;
};

export type UpdateAssignmentFieldsInput = {
    assignmentId: string;
    revision: string;
};

export type UnusedInputs = {
    viewInput?: ViewInput | null;
};

export type ViewInput = {
    ID: string;
    type: ItemType;
    userId?: string | null;
};

export type UnusedTypes = {
    __typename?: "UnusedTypes";
    verifiedSchool?: VerifiedSchool | null;
    classPermission?: ClassPermission | null;
    emailType?: EmailType | null;
    pushTemplate?: PushTemplate | null;
    emailTemplate?: EmailTemplate | null;
    promoNotificationCategory?: PromoNotificationCategory | null;
    scheduledEvent?: ScheduledEvent | null;
    view?: View | null;
    assignmentFeedbackWithScore?: AssignmentFeedbackWithScore | null;
    flaggedMessage?: FlaggedMessage | null;
    scanHistory?: ScanHistory | null;
};

export enum ClassPermission {
    ALL = "ALL",
    ONLY_OWN = "ONLY_OWN",
    VIEW = "VIEW",
    NONE = "NONE",
}

export enum EmailType {
    ORGANIZATION_PAYWALL = "ORGANIZATION_PAYWALL",
    TEACHER_DPA_SIGNED = "TEACHER_DPA_SIGNED",
    TEACHER_EMAIL_SENT = "TEACHER_EMAIL_SENT",
    WELCOME_STUDENT = "WELCOME_STUDENT",
    WELCOME_TEACHER = "WELCOME_TEACHER",
    ORGANIZATION_ADMIN_ADDED = "ORGANIZATION_ADMIN_ADDED",
    USER_ORGANIZATION_SUBSCRIPTION_REMOVAL = "USER_ORGANIZATION_SUBSCRIPTION_REMOVAL",
    STREAK_REMINDER = "STREAK_REMINDER",
    STREAK_FREEZE = "STREAK_FREEZE",
    STREAK_DEAD = "STREAK_DEAD",
    LONG_PAUSE = "LONG_PAUSE",
    SPACED_REPETITION = "SPACED_REPETITION",
    INACTIVITY = "INACTIVITY",
    GAME_BLOCK = "GAME_BLOCK",
    THREE_DAYS_PLUS = "THREE_DAYS_PLUS",
    TEN_DAYS_PLUS = "TEN_DAYS_PLUS",
    THREE_DAYS_ULTRA = "THREE_DAYS_ULTRA",
    TEN_DAYS_ULTRA = "TEN_DAYS_ULTRA",
    TEACHER_WITH_EMPTY_CLASSES = "TEACHER_WITH_EMPTY_CLASSES",
    TEACHER_WITHOUT_CLASSES = "TEACHER_WITHOUT_CLASSES",
}

export type PushTemplate = {
    __typename?: "PushTemplate";
    color?: string | null;
    image?: string | null;
    title: string;
    message: string;
    action: string;
};

export type EmailTemplate = {
    __typename?: "EmailTemplate";
    subject: string;
    body: string;
    type?: EmailType | null;
};

export enum PromoNotificationCategory {
    AI_USAGE_GENERATOR = "AI_USAGE_GENERATOR",
    AI_USAGE_BRAINSTORM_ASSISTANT = "AI_USAGE_BRAINSTORM_ASSISTANT",
    AI_USAGE_EXPLAIN_WRONG = "AI_USAGE_EXPLAIN_WRONG",
    AI_USAGE_EXPLAIN_FRQ = "AI_USAGE_EXPLAIN_FRQ",
    AI_USAGE_PLANS = "AI_USAGE_PLANS",
}

export type ScheduledEvent = {
    __typename?: "ScheduledEvent";
    eventTime: number;
    eventId: string;
    userId: string;
    email: string;
    type: NotificationType;
    stage?: number | null;
    itemId?: string | null;
    itemType?: ItemType | null;
    pushTemplate?: PushTemplate | null;
    emailTemplate?: EmailTemplate | null;
    repeatTime?: number | null;
    params?: string | null;
};

export type View = {
    __typename?: "View";
    ID: string;
    type?: ItemType | null;
    time?: number | null;
    count?: number | null;
};

export type AssignmentFeedbackWithScore = {
    __typename?: "AssignmentFeedbackWithScore";
    score?: number | null;
    feedback: AssignmentFeedback;
};

export type FlaggedMessage = {
    __typename?: "FlaggedMessage";
    org: string;
    userId: string;
    sessionId: string;
    messageId: string;
    created: string;
    content: string;
    contentFlag?: ContentFlagEnum | null;
    sender: Sender;
};

export enum ContentFlagEnum {
    HARASSMENT = "HARASSMENT",
    SEXUAL = "SEXUAL",
    HATE = "HATE",
    VIOLENCE = "VIOLENCE",
    ILLICIT = "ILLICIT",
    SELF_HARM = "SELF_HARM",
}

export enum Sender {
    USER = "USER",
    AI = "AI",
}

export type ScanHistory = {
    __typename?: "ScanHistory";
    userId: string;
    scanId: string;
    created: string;
    updated: string;
    subject?: string | null;
    topic?: string | null;
    context?: string | null;
    imageDescription?: string | null;
    relatedTopics?: Array<string | null> | null;
    questions: Array<ScanHistoryQuestion>;
    pinned?: boolean | null;
    summary?: string | null;
    videoResult?: string | null;
};

export type ScanHistoryQuestion = {
    __typename?: "ScanHistoryQuestion";
    text: string;
    type: QuestionTypeEnum;
    choices: Array<string>;
    answer: ScanHistoryAnswer;
};

export enum QuestionTypeEnum {
    SINGLE_CHOICE = "SINGLE_CHOICE",
    MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
    TRUE_FALSE = "TRUE_FALSE",
    OPEN_ENDED = "OPEN_ENDED",
}

export type ScanHistoryAnswer = {
    __typename?: "ScanHistoryAnswer";
    text: string;
    steps: Array<string>;
};

export type UpdateOrganizationInput = {
    org: string;
    name?: string | null;
    pictureUrl?: string | null;
    admins?: Array<OrganizationAdminInput> | null;
    pendingAdmins?: Array<PendingOrganizationAdminInput> | null;
    stage?: OrganizationStage | null;
    dpa?: OrganizationDPAInput | null;
    files?: Array<OrganizationFileInput> | null;
    country?: string | null;
    state?: string | null;
    schools?: Array<string> | null;
    settings?: OrganizationSettingsInput | null;
    schoolsSettings?: Array<OrganizationSettingsInput> | null;
    subscriptions?: Array<OrganizationStripeConnectionInput> | null;
    syncTimestamp?: number | null;
    aliases?: Array<string> | null;
};

export type OrganizationAdminInput = {
    userId: string;
    schoolId: string;
};

export type PendingOrganizationAdminInput = {
    Email: string;
    schoolId: string;
};

export type OrganizationDPAInput = {
    name?: string | null;
    email?: string | null;
    link?: string | null;
    type: DPAType;
};

export type OrganizationFileInput = {
    url: string;
    name: string;
};

export type OrganizationSettingsInput = {
    schoolId?: string | null;
    sharing: OrganizationSharing;
    subsAllowed: boolean;
    ai?: boolean | null;
};

export type OrganizationStripeConnectionInput = {
    schoolId: string;
    created: number;
    current_period_end: number;
    studentPlan: SubscriptionType;
    teacherPlan: SubscriptionType;
    studentsPaidFor: number;
    teachersPaidFor: number;
    paidStudents: number;
    paidTeachers: number;
    autoSubsStudent: boolean;
    autoSubsTeacher: boolean;
};

export type UpdateOrgMembersInput = {
    org: string;
    users: Array<UpdateOrgMemberInput>;
};

export type UpdateOrgMemberInput = {
    Email: string;
    schoolId?: string | null;
    accountType?: AccountType | null;
    birthday?: string | null;
    grade?: string | null;
    orgPlan?: boolean | null;
};

export type UserDetailsConnection = {
    __typename?: "UserDetailsConnection";
    items: Array<UserDetails>;
    nextToken?: string | null;
};

export type UpdateScanHistoryInput = {
    scanId: string;
    pinned?: boolean | null;
    subject?: string | null;
    topic?: string | null;
    relatedTopics?: Array<string | null> | null;
    summary?: string | null;
};

export type DeleteScanHistoryInput = {
    scanIds: Array<string>;
};

export type AnswerTutorialGeneratorInput = {
    scanId: string;
};

export type SubmitExamSessionInput = {
    exam: string;
    type: ExamTypeEnum;
    sections: Array<ExamSessionSectionInput>;
};

export enum ExamTypeEnum {
    MCQ = "MCQ",
    FRQ = "FRQ",
    FULL = "FULL",
}

export type ExamSessionSectionInput = {
    name: string;
    time: number;
    sectionPoints: number;
    sectionTotal: number;
    breakdown: Array<ExamSessionSectionBreakdownInput>;
};

export type ExamSessionSectionBreakdownInput = {
    name: string;
    type: ExamTypeEnum;
    points: number;
    total: number;
    mcqResult?: Array<MCQSubmissionInput> | null;
    frqResult?: Array<FRQSubmissionInput> | null;
};

export type MCQSubmissionInput = {
    questionId: string;
    selectedAnswer?: number | null;
};

export type FRQSubmissionInput = {
    questionId: string;
    partAnswers: Array<FRQPartAnswerInput>;
};

export type FRQPartAnswerInput = {
    answer: string;
    attachments?: Array<AttachmentInput> | null;
};

export type AttachmentInput = {
    url: string;
    parsed?: string | null;
    type: MediaType;
};

export type ExamHistory = {
    __typename?: "ExamHistory";
    userId: string;
    historyId: string;
    exam: string;
    type: ExamTypeEnum;
    sections: Array<ExamHistorySection>;
    mcqMetrics?: Array<string> | null;
    frqMetrics?: Array<string> | null;
    score: number;
    created: string;
    updated: string;
};

export type ExamHistorySection = {
    __typename?: "ExamHistorySection";
    name: string;
    time: number;
    sectionPoints: number;
    sectionTotal: number;
    breakdown: Array<ExamHistorySectionBreakdown>;
};

export type ExamHistorySectionBreakdown = {
    __typename?: "ExamHistorySectionBreakdown";
    name: string;
    type: ExamTypeEnum;
    points: number;
    total: number;
    mcqResult?: ExamHistoryMCQResult | null;
    frqResult?: ExamHistoryFRQResult | null;
};

export type ExamHistoryMCQResult = {
    __typename?: "ExamHistoryMCQResult";
    items?: Array<ExamHistoryMCQResultItems> | null;
};

export type ExamHistoryMCQResultItems = {
    __typename?: "ExamHistoryMCQResultItems";
    questionId: string;
    selectedAnswer?: number | null;
    isCorrect?: boolean | null;
};

export type ExamHistoryFRQResult = {
    __typename?: "ExamHistoryFRQResult";
    items?: Array<ExamHistoryFRQResultItems> | null;
};

export type ExamHistoryFRQResultItems = {
    __typename?: "ExamHistoryFRQResultItems";
    questionId: string;
    partAnswers?: Array<ExamHistoryFRQPartAnswers> | null;
};

export type ExamHistoryFRQPartAnswers = {
    __typename?: "ExamHistoryFRQPartAnswers";
    answer: string;
    earnedScore?: number | null;
    possibleScore?: number | null;
    feedback?: string | null;
    attachments?: Array<Attachment> | null;
};

export type Attachment = {
    __typename?: "Attachment";
    url: string;
    parsed?: string | null;
    type: MediaType;
};

export type DeleteExamHistoryInput = {
    historyId: string;
};

export type CreateMockExamSessionInput = {
    exam: string;
};

export type MockExamSession = {
    __typename?: "MockExamSession";
    max?: number | null;
    scores?: Array<number | null> | null;
    sections?: Array<MockExamSessionSection | null> | null;
};

export type MockExamSessionSection = {
    __typename?: "MockExamSessionSection";
    name: string;
    time?: number | null;
    sectionPoints?: number | null;
    sectionTotal?: number | null;
    breakdown?: Array<MockExamSessionBreakdown | null> | null;
};

export type MockExamSessionBreakdown = {
    __typename?: "MockExamSessionBreakdown";
    name: string;
    type?: ExamTypeEnum | null;
    points?: number | null;
    total?: number | null;
    frqType?: ExamFRQType | null;
    mcqQuestions?: Array<ExamMCQGroup | null> | null;
    frqQuestions?: Array<FullExamFRQ | null> | null;
};

export type FullExamFRQ = {
    __typename?: "FullExamFRQ";
    exam: string;
    questionId: string;
    difficulty: ExamDifficulty;
    question: string;
    rating: number;
    ratingCount: number;
    examUnit: string;
    examSection?: string | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    subject?: string | null;
    topic?: string | null;
    created: string;
    updated: string;
    url?: string | null;
    prompt: ExamPromptType;
    stimuli?: Array<ExamStimulus> | null;
    name: string;
    parts: Array<FullExamFRQPart>;
    rubric: Array<ExamFRQRubricParts>;
    starred: boolean;
    calc?: number | null;
    frqType?: ExamFRQType | null;
};

export type FullExamFRQPart = {
    __typename?: "FullExamFRQPart";
    question: string;
    rating: number;
    ratingCount: number;
    stimuli?: Array<ExamStimulus> | null;
    prefill?: string | null;
};

export type ListNotificationsByUserInput = {
    nextToken?: string | null;
    limit?: number | null;
};

export type GetNoteInput = {
    noteId: string;
    password?: string | null;
};

export type NoteNullableContext = {
    __typename?: "NoteNullableContext";
    item: Note;
    user?: UserDetails | null;
    badges?: Array<BadgeProgress> | null;
    dailyActivity?: DailyActivity | null;
    level?: number | null;
};

export type GetMediaInput = {
    mediaId: string;
    password?: string | null;
};

export type MediaNullableContext = {
    __typename?: "MediaNullableContext";
    item: Media;
    user?: UserDetails | null;
    badges?: Array<BadgeProgress> | null;
    dailyActivity?: DailyActivity | null;
    level?: number | null;
};

export type ListMediaInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type MediaConnection = {
    __typename?: "MediaConnection";
    items: Array<Media>;
    nextToken?: string | null;
};

export type ListNoteInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ModelNoteConnection = {
    __typename?: "ModelNoteConnection";
    items: Array<Note>;
    nextToken?: string | null;
};

export type ListFlashcardSetByUserInput = {
    userId: string;
    standalone?: boolean | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type FlashcardSetConnection = {
    __typename?: "FlashcardSetConnection";
    items: Array<FlashcardSet>;
    nextToken?: string | null;
};

export type ListFlashcardSetByNoteInput = {
    noteId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListFlashcardsByFlashcardSetInput = {
    flashcardSetId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListFollowingInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
    extraDetails?: boolean | null;
};

export type UserConnection = {
    __typename?: "UserConnection";
    items: Array<User>;
    nextToken?: string | null;
};

export type User = {
    __typename?: "User";
    userId: string;
    pictureUrl?: string | null;
    Name?: string | null;
    accountType?: AccountType | null;
    username?: string | null;
    Email?: string | null;
    level?: number | null;
};

export type ListFollowersInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
    extraDetails?: boolean | null;
};

export type ListExamFRQsInput = {
    exam: string;
    examUnit?: string | null;
    difficulty: ExamDifficultyInput;
    limit?: number | null;
    nextToken?: string | null;
};

export type ExamFRQConnection = {
    __typename?: "ExamFRQConnection";
    items: Array<ExamFRQ>;
    nextToken?: string | null;
};

export type ListSubjectsInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type SubjectConnection = {
    __typename?: "SubjectConnection";
    items: Array<Subject>;
    nextToken?: string | null;
};

export type Subject = {
    __typename?: "Subject";
    subject?: string | null;
    picture?: string | null;
    color?: string | null;
    category?: string | null;
    order?: number | null;
    topics?: string | null;
};

export type BannerConnection = {
    __typename?: "BannerConnection";
    items: Array<Banner>;
};

export type Banner = {
    __typename?: "Banner";
    bannerId: string;
    type: BannerType;
    message: string;
    startTime: number;
    endTime: number;
    href?: string | null;
    promoCode?: string | null;
};

export enum BannerType {
    INFO = "INFO",
    WARNING = "WARNING",
    URGENT = "URGENT",
    PROMO = "PROMO",
}

export type ListExamsInput = {
    type?: string | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ExamConnection = {
    __typename?: "ExamConnection";
    items: Array<Exam>;
    nextToken?: string | null;
};

export type Exam = {
    __typename?: "Exam";
    type: string;
    name: string;
    description?: string | null;
    unitLabel: string;
    sectionLabel: string;
    baseLink: string;
    image: string;
    category?: string | null;
    color?: string | null;
    date?: string | null;
    time?: number | null;
    index?: boolean | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    discord?: Array<ExamResourceType | null> | null;
    youtube?: Array<ExamResourceType | null> | null;
    units?: Array<ExamUnit | null> | null;
    crams?: Array<ExamUnit | null> | null;
};

export type ExamResourceType = {
    __typename?: "ExamResourceType";
    name: string;
    link?: string | null;
    image?: string | null;
};

export type ExamUnit = {
    __typename?: "ExamUnit";
    name: string;
    image?: string | null;
    noteId?: string | null;
    flashcardSetId?: string | null;
    sections?: Array<string | null> | null;
};

export type GetCurrentUserInput = {
    forceStripeVerify?: boolean | null;
};

export type ListBookmarkByUserIdInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type BookmarkConnection = {
    __typename?: "BookmarkConnection";
    items: Array<Bookmark>;
    nextToken?: string | null;
};

export type Bookmark = {
    __typename?: "Bookmark";
    userId: string;
    ID: string;
    type: ItemType;
    time?: number | null;
};

export type IsBookmarkInput = {
    ID: string;
};

export type IsUsernameAvailableInput = {
    username: string;
};

export type ESQueryFullInput = {
    index: Array<ItemType>;
    body: string;
};

export type ESResult = {
    __typename?: "ESResult";
    result: string;
};

export type ListFoldersByUserInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type FolderConnection = {
    __typename?: "FolderConnection";
    items: Array<Folder>;
    nextToken?: string | null;
};

export type ListFoldersByParentInput = {
    parentId: string;
    password?: string | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListNotesByFolderInput = {
    folderId: string;
    password?: string | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListFlashcardSetsByFolderInput = {
    folderId: string;
    password?: string | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListMediaByFolderInput = {
    folderId: string;
    password?: string | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type GetFolderInput = {
    folderId: string;
    password?: string | null;
};

export type GetRatingInput = {
    noteId: string;
    userId: string;
};

export type GetUserRatingStatsInput = {
    userId: string;
};

export type RatingStats = {
    __typename?: "RatingStats";
    count?: number | null;
    rating?: number | null;
};

export type GetNoteRatingStatsInput = {
    noteId: string;
};

export type GetFlashcardSetViewerInput = {
    userId: string;
    flashcardSetId: string;
};

export type QuizletParseInput = {
    url?: string | null;
};

export type QuizletFlashcards = {
    __typename?: "QuizletFlashcards";
    data?: string | null;
    title?: string | null;
};

export type AICompletionInput = {
    noteId?: string | null;
    flashcardSetId?: string | null;
    flashcardId?: string | null;
    input?: string | null;
    subject?: string | null;
    topic?: string | null;
    type: AICompletionType;
    params?: string | null;
};

export type AICompletionContext = {
    __typename?: "AICompletionContext";
    item: AICompletion;
    user: UserDetails;
    badges: Array<BadgeProgress>;
    dailyActivity: DailyActivity;
    level?: number | null;
};

export type GetExamInput = {
    exam: string;
};

export type GetExamMCQInput = {
    questionId: string;
};

export type GetExamFRQInput = {
    questionId: string;
};

export type GetSubjectInput = {
    subject: string;
};

export type GetBlogInput = {
    slug: string;
};

export type GetBlogByIdInput = {
    blogId: string;
};

export type GetRecommendedBlogInput = {
    tags: string;
    filter?: string | null;
};

export type BlogConnection = {
    __typename?: "BlogConnection";
    items: Array<Blog>;
    nextToken?: string | null;
};

export type ListBlogsInput = {
    tags?: string | null;
    sort?: BlogSortKey | null;
    limit?: number | null;
    nextToken?: string | null;
};

export enum BlogSortKey {
    CREATED = "CREATED",
    UPDATE = "UPDATE",
}

export type ListBlogsByUserInput = {
    userId: string;
    sort?: BlogSortKey | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type GetStudySessionInput = {
    itemId: string;
};

export type ListStudySessionsInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type StudySessionConnection = {
    __typename?: "StudySessionConnection";
    items: Array<StudySession>;
    nextToken?: string | null;
};

export type ListRecentStudySessionsInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type ListFlashcardStudyStatesInput = {
    flashcardSetId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListItemAIChatsInput = {
    itemId: string;
    itemType: ItemType;
    limit?: number | null;
    nextToken?: string | null;
};

export type AICompletionConnection = {
    __typename?: "AICompletionConnection";
    items: Array<AICompletion>;
    nextToken?: string | null;
};

export type GetClassInput = {
    classId: string;
};

export type ListClassesInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type ClassConnection = {
    __typename?: "ClassConnection";
    items: Array<Class>;
    nextToken?: string | null;
};

export type ListClassStudySessionsInput = {
    classId: string;
    itemId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListClassFlashcardStudyStatesInput = {
    classId: string;
    flashcardSetId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListNotesByClassInput = {
    classId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListFlashcardSetsByClassInput = {
    classId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListMediasByClassInput = {
    classId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListFoldersByClassInput = {
    classId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type AdminListOrgInput = {
    org: string;
    schoolId?: string | null;
    nextToken?: string | null;
    limit?: number | null;
};

export type OrgUserDetailsConnection = {
    __typename?: "OrgUserDetailsConnection";
    items: Array<OrgUserDetails>;
    nextToken?: string | null;
};

export type OrgUserDetails = {
    __typename?: "OrgUserDetails";
    Email: string;
    org?: string | null;
    accountType?: AccountType | null;
    ID?: string | null;
    elID?: string | null;
    clID?: string | null;
    isSSOChecked?: boolean | null;
    Name?: string | null;
    pictureUrl?: string | null;
    username?: string | null;
    birthday?: string | null;
    created?: number | null;
    lastLogIn?: number | null;
    ref_v2?: string | null;
    numFollowers?: number | null;
    numFollowing?: number | null;
    schoolId?: string | null;
    grade?: string | null;
    subscriptionType?: SubscriptionType | null;
    isTrialing?: boolean | null;
    signInTypes?: Array<SignInType> | null;
    orgPlanType?: SubscriptionType | null;
    orgPlanCreated?: number | null;
    lastOrgPlanUpdated?: number | null;
    orgRequests?: Array<UpgradeEvent> | null;
    orgReqTime?: string | null;
    classCount?: number | null;
};

export type GetOrganizationInput = {
    org: string;
};

export type OrganizationConnection = {
    __typename?: "OrganizationConnection";
    items: Array<Organization>;
    nextToken?: string | null;
};

export type ListStoreProductMetadataInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type StoreProductMetadataConnection = {
    __typename?: "StoreProductMetadataConnection";
    items: Array<StoreProductMetadata>;
};

export type StoreProductMetadata = {
    __typename?: "StoreProductMetadata";
    category: StoreCategoryEnum;
    storeProductId: StoreProductEnum;
    name: string;
    description: string;
    link?: string | null;
    feature: boolean;
    price: number;
    discount?: number | null;
    max: number;
    total: number;
    group?: StoreGroupEnum | null;
    color: string;
    planType?: SubscriptionType | null;
};

export enum StoreCategoryEnum {
    ALL = "ALL",
    STREAK = "STREAK",
    CUSTOMIZATION = "CUSTOMIZATION",
    ICONS = "ICONS",
    PLANS = "PLANS",
    MERCH = "MERCH",
}

export enum StoreGroupEnum {
    APP_ICONS = "APP_ICONS",
}

export type ListStoreProductHistoryInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type StoreProductHistoryConnection = {
    __typename?: "StoreProductHistoryConnection";
    items: Array<StoreProductHistory>;
};

export type ListBadgeProgressInput = {
    userId?: string | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type BadgeProgressConnection = {
    __typename?: "BadgeProgressConnection";
    items: Array<BadgeProgress>;
};

export type ListDailyActivityInput = {
    limit?: number | null;
    nextToken?: string | null;
    startDate?: string | null;
    endDate?: string | null;
};

export type DailyActivityConnection = {
    __typename?: "DailyActivityConnection";
    items: Array<DailyActivity>;
    nextToken?: string | null;
};

export type ListBadgesMetadataInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type BadgeMetadataConnection = {
    __typename?: "BadgeMetadataConnection";
    items: Array<BadgeMetadata>;
};

export type BadgeMetadata = {
    __typename?: "BadgeMetadata";
    group: BadgeGroupEnum;
    badge: BadgeEnum;
    name: string;
    description: string;
    success: string;
    failure: string;
    tiers: Array<BadgeTier>;
};

export enum BadgeGroupEnum {
    CREATOR = "CREATOR",
    LEARNING = "LEARNING",
    SOCIAL = "SOCIAL",
    SHARING = "SHARING",
    CLASS = "CLASS",
    MILESTONES = "MILESTONES",
}

export type BadgeTier = {
    __typename?: "BadgeTier";
    xpReward: number;
    coinReward: number;
    required: number;
};

export type GetUserByRefV2Input = {
    ref_v2: string;
};

export type getIntercomUserHashInput = {
    platform: PlatformEnum;
};

export type GetAssignmentInput = {
    assignmentId: string;
};

export type GetAssignmentSessionInput = {
    assignmentId: string;
    sessionId: string;
};

export type AssignmentSessionAndMessages = {
    __typename?: "AssignmentSessionAndMessages";
    session: AssignmentSession;
    messages: Array<AssignmentMessage>;
};

export type AssignmentMessage = {
    __typename?: "AssignmentMessage";
    sessionId: string;
    messageId: string;
    created: string;
    content: string;
    contentFlag?: ContentFlagEnum | null;
    sender: Sender;
};

export type ListAssignmentsInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type AssignmentConnection = {
    __typename?: "AssignmentConnection";
    items: Array<Assignment>;
    nextToken?: string | null;
};

export type ListAssignmentsByFolderInput = {
    folderId: string;
    password?: string | null;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListAssignmentsByClassInput = {
    classId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListAssignmentSessionsInput = {
    assignmentId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type AssignmentSessionConnection = {
    __typename?: "AssignmentSessionConnection";
    items: Array<AssignmentSession>;
    nextToken?: string | null;
};

export type ListAssignmentSessionsByUserInput = {
    userId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type ListAssignmentMessagesInput = {
    sessionId: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type AssignmentMessageConnection = {
    __typename?: "AssignmentMessageConnection";
    items: Array<AssignmentMessage>;
    nextToken?: string | null;
};

export type ListScanHistoryInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type ScanHistoryConnection = {
    __typename?: "ScanHistoryConnection";
    items: Array<ScanHistory>;
    nextToken?: string | null;
};

export type GetScanHistoryInput = {
    scanId: string;
};

export type ListExamHistoryInput = {
    limit?: number | null;
    nextToken?: string | null;
};

export type ExamHistoryConnection = {
    __typename?: "ExamHistoryConnection";
    items: Array<ExamHistory>;
    nextToken?: string | null;
};

export type ListExamHistoryByExamInput = {
    exam: string;
    limit?: number | null;
    nextToken?: string | null;
};

export type GetExamHistoryInput = {
    historyId: string;
};

export type CreateNoteMutationVariables = {
    input: CreateNoteInput;
};

export type CreateNoteMutation = {
    createNote?: {
        __typename?: "Note";
        userId: string;
        noteId: string;
        content?: string | null;
        hash?: string | null;
        public?: boolean | null;
        draft?: boolean | null;
        password?: string | null;
        summary?: string | null;
        title?: string | null;
        trash?: boolean | null;
        created?: string | null;
        updated?: string | null;
        noteCode?: string | null;
        file?: string | null;
        folderId?: string | null;
        flashcardSetId?: string | null;
        mediaId?: string | null;
        importType?: ImportType | null;
        subject?: string | null;
        views?: number | null;
        verified?: boolean | null;
        classPublic?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        lock?: boolean | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        isAI?: boolean | null;
        aiTool?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        neverPublic?: boolean | null;
        deadline?: number | null;
    } | null;
};

export type CreateNoteV2MutationVariables = {
    input: CreateNoteInput;
};

export type CreateNoteV2Mutation = {
    createNoteV2?: {
        __typename?: "NoteContext";
        item: {
            __typename?: "Note";
            userId: string;
            noteId: string;
            content?: string | null;
            hash?: string | null;
            public?: boolean | null;
            draft?: boolean | null;
            password?: string | null;
            summary?: string | null;
            title?: string | null;
            trash?: boolean | null;
            created?: string | null;
            updated?: string | null;
            noteCode?: string | null;
            file?: string | null;
            folderId?: string | null;
            flashcardSetId?: string | null;
            mediaId?: string | null;
            importType?: ImportType | null;
            subject?: string | null;
            views?: number | null;
            verified?: boolean | null;
            classPublic?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            lock?: boolean | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            isAI?: boolean | null;
            aiTool?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            neverPublic?: boolean | null;
            deadline?: number | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type DeleteNoteMutationVariables = {
    input: DeleteNoteInput;
};

export type DeleteNoteMutation = {
    deleteNote?: {
        __typename?: "Note";
        userId: string;
        noteId: string;
        content?: string | null;
        hash?: string | null;
        public?: boolean | null;
        draft?: boolean | null;
        password?: string | null;
        summary?: string | null;
        title?: string | null;
        trash?: boolean | null;
        created?: string | null;
        updated?: string | null;
        noteCode?: string | null;
        file?: string | null;
        folderId?: string | null;
        flashcardSetId?: string | null;
        mediaId?: string | null;
        importType?: ImportType | null;
        subject?: string | null;
        views?: number | null;
        verified?: boolean | null;
        classPublic?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        lock?: boolean | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        isAI?: boolean | null;
        aiTool?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        neverPublic?: boolean | null;
        deadline?: number | null;
    } | null;
};

export type UpdateNoteMutationVariables = {
    input: UpdateNoteInput;
};

export type UpdateNoteMutation = {
    updateNote?: {
        __typename?: "Note";
        userId: string;
        noteId: string;
        content?: string | null;
        hash?: string | null;
        public?: boolean | null;
        draft?: boolean | null;
        password?: string | null;
        summary?: string | null;
        title?: string | null;
        trash?: boolean | null;
        created?: string | null;
        updated?: string | null;
        noteCode?: string | null;
        file?: string | null;
        folderId?: string | null;
        flashcardSetId?: string | null;
        mediaId?: string | null;
        importType?: ImportType | null;
        subject?: string | null;
        views?: number | null;
        verified?: boolean | null;
        classPublic?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        lock?: boolean | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        isAI?: boolean | null;
        aiTool?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        neverPublic?: boolean | null;
        deadline?: number | null;
    } | null;
};

export type UpdateNoteV2MutationVariables = {
    input: UpdateNoteInput;
};

export type UpdateNoteV2Mutation = {
    updateNoteV2?: {
        __typename?: "NoteContext";
        item: {
            __typename?: "Note";
            userId: string;
            noteId: string;
            content?: string | null;
            hash?: string | null;
            public?: boolean | null;
            draft?: boolean | null;
            password?: string | null;
            summary?: string | null;
            title?: string | null;
            trash?: boolean | null;
            created?: string | null;
            updated?: string | null;
            noteCode?: string | null;
            file?: string | null;
            folderId?: string | null;
            flashcardSetId?: string | null;
            mediaId?: string | null;
            importType?: ImportType | null;
            subject?: string | null;
            views?: number | null;
            verified?: boolean | null;
            classPublic?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            lock?: boolean | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            isAI?: boolean | null;
            aiTool?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            neverPublic?: boolean | null;
            deadline?: number | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type GenerateMediaTranscriptionMutationVariables = {
    input: GenerateMediaTranscriptionInput;
};

export type GenerateMediaTranscriptionMutation = {
    generateMediaTranscription?: {
        __typename?: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        verified?: boolean | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        isAI?: boolean | null;
    } | null;
};

export type CreateMediaWithTranscriptionMutationVariables = {
    input: CreateMediaWithTranscriptionInput;
};

export type CreateMediaWithTranscriptionMutation = {
    createMediaWithTranscription?: {
        __typename?: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        verified?: boolean | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        isAI?: boolean | null;
    } | null;
};

export type CreateMediaFromYoutubeMutationVariables = {
    input: CreateMediaFromYoutubeInput;
};

export type CreateMediaFromYoutubeMutation = {
    createMediaFromYoutube?: string | null;
};

export type ImportFromLinkMutationVariables = {
    input: ImportFromLinkInput;
};

export type ImportFromLinkMutation = {
    importFromLink?: {
        __typename?: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        verified?: boolean | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        isAI?: boolean | null;
    } | null;
};

export type DuplicateMediaMutationVariables = {
    input: DuplicateMediaInput;
};

export type DuplicateMediaMutation = {
    duplicateMedia?: {
        __typename?: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        verified?: boolean | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        isAI?: boolean | null;
    } | null;
};

export type UpdateMediaMutationVariables = {
    input: UpdateMediaInput;
};

export type UpdateMediaMutation = {
    updateMedia?: {
        __typename?: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        verified?: boolean | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        isAI?: boolean | null;
    } | null;
};

export type DeleteMediaMutationVariables = {
    input: DeleteMediaInput;
};

export type DeleteMediaMutation = {
    deleteMedia?: {
        __typename?: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        verified?: boolean | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        isAI?: boolean | null;
    } | null;
};

export type CreateFlashcardSetMutationVariables = {
    input: CreateFlashcardSetInput;
};

export type CreateFlashcardSetMutation = {
    createFlashcardSet?: {
        __typename?: "FlashcardSet";
        userId: string;
        flashcardSetId: string;
        sort?: number | null;
        noteId?: string | null;
        mediaId?: string | null;
        position?: number | null;
        title?: string | null;
        description?: string | null;
        public?: boolean | null;
        password?: string | null;
        trash?: boolean | null;
        draft?: boolean | null;
        starred?: Array<string | null> | null;
        size?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        created?: number | null;
        updated?: number | null;
        quizletUrl?: string | null;
        answerSide?: FlashcardSide | null;
        shuffled?: boolean | null;
        flashcards?: Array<{
            __typename?: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
            termRecordedAudio?: string | null;
            definitionRecordedAudio?: string | null;
            mcOptions?: Array<string | null> | null;
            termCustomHint?: string | null;
            definitionCustomHint?: string | null;
        } | null> | null;
        termLanguage?: Language | null;
        definitionLanguage?: Language | null;
        relatedNotes?: Array<string | null> | null;
        order?: Array<number | null> | null;
        flashcardSetCode?: string | null;
        views?: number | null;
        classPublic?: boolean | null;
        subject?: string | null;
        verified?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        folderId?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        lock?: boolean | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        studySettings?: {
            __typename?: "FlashcardStudySettings";
            blockedStudyModes: Array<StudySessionType>;
            settings: {
                __typename?: "FlashcardsSetLockedStudySettings";
                LEARN?: {
                    __typename?: "LearnLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchLockedSettings";
                    locked: Array<string>;
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    position?: number | null;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                } | null;
            };
        } | null;
        isAI?: boolean | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        deadline?: number | null;
    } | null;
};

export type CreateFlashcardSetV2MutationVariables = {
    input: CreateFlashcardSetInput;
};

export type CreateFlashcardSetV2Mutation = {
    createFlashcardSetV2?: {
        __typename?: "FlashcardSetContext";
        item: {
            __typename?: "FlashcardSet";
            userId: string;
            flashcardSetId: string;
            sort?: number | null;
            noteId?: string | null;
            mediaId?: string | null;
            position?: number | null;
            title?: string | null;
            description?: string | null;
            public?: boolean | null;
            password?: string | null;
            trash?: boolean | null;
            draft?: boolean | null;
            starred?: Array<string | null> | null;
            size?: number | null;
            studyFrom?: FlashcardStudyFrom | null;
            created?: number | null;
            updated?: number | null;
            quizletUrl?: string | null;
            answerSide?: FlashcardSide | null;
            shuffled?: boolean | null;
            flashcards?: Array<{
                __typename?: "Flashcard";
                flashcardId: string;
                flashcardSetId?: string | null;
                userId?: string | null;
                term?: string | null;
                termAudio?: string | null;
                definition?: string | null;
                definitionAudio?: string | null;
                distractorIds?: Array<string | null> | null;
                questionType?: QuestionType | null;
                isCorrect?: boolean | null;
                draft?: boolean | null;
                trash?: boolean | null;
                disabled?: boolean | null;
                image?: string | null;
                secondaryImage?: string | null;
                edited?: boolean | null;
                created?: string | null;
                updated?: string | null;
                twoSided?: boolean | null;
                schedule?: string | null;
                quality?: number | null;
                termRecordedAudio?: string | null;
                definitionRecordedAudio?: string | null;
                mcOptions?: Array<string | null> | null;
                termCustomHint?: string | null;
                definitionCustomHint?: string | null;
            } | null> | null;
            termLanguage?: Language | null;
            definitionLanguage?: Language | null;
            relatedNotes?: Array<string | null> | null;
            order?: Array<number | null> | null;
            flashcardSetCode?: string | null;
            views?: number | null;
            classPublic?: boolean | null;
            subject?: string | null;
            verified?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            folderId?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            lock?: boolean | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            studySettings?: {
                __typename?: "FlashcardStudySettings";
                blockedStudyModes: Array<StudySessionType>;
                settings: {
                    __typename?: "FlashcardsSetLockedStudySettings";
                    LEARN?: {
                        __typename?: "LearnLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                        length?: number | null;
                        override?: boolean | null;
                    } | null;
                    TEST?: {
                        __typename?: "TestLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        length: number;
                    } | null;
                    SPACED?: {
                        __typename?: "SpacedLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        cardsPerSession: number;
                        intervals?: Array<number> | null;
                    } | null;
                    MATCH?: {
                        __typename?: "MatchLockedSettings";
                        locked: Array<string>;
                        grid: boolean;
                        starred: boolean;
                        length: number;
                    } | null;
                    REVIEW?: {
                        __typename?: "ReviewLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        position?: number | null;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                    } | null;
                };
            } | null;
            isAI?: boolean | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            deadline?: number | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type UpdateFlashcardSetMutationVariables = {
    input: UpdateFlashcardSetInput;
};

export type UpdateFlashcardSetMutation = {
    updateFlashcardSet?: {
        __typename?: "FlashcardSet";
        userId: string;
        flashcardSetId: string;
        sort?: number | null;
        noteId?: string | null;
        mediaId?: string | null;
        position?: number | null;
        title?: string | null;
        description?: string | null;
        public?: boolean | null;
        password?: string | null;
        trash?: boolean | null;
        draft?: boolean | null;
        starred?: Array<string | null> | null;
        size?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        created?: number | null;
        updated?: number | null;
        quizletUrl?: string | null;
        answerSide?: FlashcardSide | null;
        shuffled?: boolean | null;
        flashcards?: Array<{
            __typename?: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
            termRecordedAudio?: string | null;
            definitionRecordedAudio?: string | null;
            mcOptions?: Array<string | null> | null;
            termCustomHint?: string | null;
            definitionCustomHint?: string | null;
        } | null> | null;
        termLanguage?: Language | null;
        definitionLanguage?: Language | null;
        relatedNotes?: Array<string | null> | null;
        order?: Array<number | null> | null;
        flashcardSetCode?: string | null;
        views?: number | null;
        classPublic?: boolean | null;
        subject?: string | null;
        verified?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        folderId?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        lock?: boolean | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        studySettings?: {
            __typename?: "FlashcardStudySettings";
            blockedStudyModes: Array<StudySessionType>;
            settings: {
                __typename?: "FlashcardsSetLockedStudySettings";
                LEARN?: {
                    __typename?: "LearnLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchLockedSettings";
                    locked: Array<string>;
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    position?: number | null;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                } | null;
            };
        } | null;
        isAI?: boolean | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        deadline?: number | null;
    } | null;
};

export type UpdateFlashcardSetV2MutationVariables = {
    input: UpdateFlashcardSetInput;
};

export type UpdateFlashcardSetV2Mutation = {
    updateFlashcardSetV2?: {
        __typename?: "FlashcardSetContext";
        item: {
            __typename?: "FlashcardSet";
            userId: string;
            flashcardSetId: string;
            sort?: number | null;
            noteId?: string | null;
            mediaId?: string | null;
            position?: number | null;
            title?: string | null;
            description?: string | null;
            public?: boolean | null;
            password?: string | null;
            trash?: boolean | null;
            draft?: boolean | null;
            starred?: Array<string | null> | null;
            size?: number | null;
            studyFrom?: FlashcardStudyFrom | null;
            created?: number | null;
            updated?: number | null;
            quizletUrl?: string | null;
            answerSide?: FlashcardSide | null;
            shuffled?: boolean | null;
            flashcards?: Array<{
                __typename?: "Flashcard";
                flashcardId: string;
                flashcardSetId?: string | null;
                userId?: string | null;
                term?: string | null;
                termAudio?: string | null;
                definition?: string | null;
                definitionAudio?: string | null;
                distractorIds?: Array<string | null> | null;
                questionType?: QuestionType | null;
                isCorrect?: boolean | null;
                draft?: boolean | null;
                trash?: boolean | null;
                disabled?: boolean | null;
                image?: string | null;
                secondaryImage?: string | null;
                edited?: boolean | null;
                created?: string | null;
                updated?: string | null;
                twoSided?: boolean | null;
                schedule?: string | null;
                quality?: number | null;
                termRecordedAudio?: string | null;
                definitionRecordedAudio?: string | null;
                mcOptions?: Array<string | null> | null;
                termCustomHint?: string | null;
                definitionCustomHint?: string | null;
            } | null> | null;
            termLanguage?: Language | null;
            definitionLanguage?: Language | null;
            relatedNotes?: Array<string | null> | null;
            order?: Array<number | null> | null;
            flashcardSetCode?: string | null;
            views?: number | null;
            classPublic?: boolean | null;
            subject?: string | null;
            verified?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            folderId?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            lock?: boolean | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            studySettings?: {
                __typename?: "FlashcardStudySettings";
                blockedStudyModes: Array<StudySessionType>;
                settings: {
                    __typename?: "FlashcardsSetLockedStudySettings";
                    LEARN?: {
                        __typename?: "LearnLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                        length?: number | null;
                        override?: boolean | null;
                    } | null;
                    TEST?: {
                        __typename?: "TestLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        length: number;
                    } | null;
                    SPACED?: {
                        __typename?: "SpacedLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        cardsPerSession: number;
                        intervals?: Array<number> | null;
                    } | null;
                    MATCH?: {
                        __typename?: "MatchLockedSettings";
                        locked: Array<string>;
                        grid: boolean;
                        starred: boolean;
                        length: number;
                    } | null;
                    REVIEW?: {
                        __typename?: "ReviewLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        position?: number | null;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                    } | null;
                };
            } | null;
            isAI?: boolean | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            deadline?: number | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type DeleteFlashcardSetMutationVariables = {
    input: DeleteFlashcardSetInput;
};

export type DeleteFlashcardSetMutation = {
    deleteFlashcardSet?: {
        __typename?: "FlashcardSet";
        userId: string;
        flashcardSetId: string;
        sort?: number | null;
        noteId?: string | null;
        mediaId?: string | null;
        position?: number | null;
        title?: string | null;
        description?: string | null;
        public?: boolean | null;
        password?: string | null;
        trash?: boolean | null;
        draft?: boolean | null;
        starred?: Array<string | null> | null;
        size?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        created?: number | null;
        updated?: number | null;
        quizletUrl?: string | null;
        answerSide?: FlashcardSide | null;
        shuffled?: boolean | null;
        flashcards?: Array<{
            __typename?: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
            termRecordedAudio?: string | null;
            definitionRecordedAudio?: string | null;
            mcOptions?: Array<string | null> | null;
            termCustomHint?: string | null;
            definitionCustomHint?: string | null;
        } | null> | null;
        termLanguage?: Language | null;
        definitionLanguage?: Language | null;
        relatedNotes?: Array<string | null> | null;
        order?: Array<number | null> | null;
        flashcardSetCode?: string | null;
        views?: number | null;
        classPublic?: boolean | null;
        subject?: string | null;
        verified?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        folderId?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        lock?: boolean | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        studySettings?: {
            __typename?: "FlashcardStudySettings";
            blockedStudyModes: Array<StudySessionType>;
            settings: {
                __typename?: "FlashcardsSetLockedStudySettings";
                LEARN?: {
                    __typename?: "LearnLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchLockedSettings";
                    locked: Array<string>;
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    position?: number | null;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                } | null;
            };
        } | null;
        isAI?: boolean | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        deadline?: number | null;
    } | null;
};

export type CreateFlashcardMutationVariables = {
    input: FlashcardInput;
};

export type CreateFlashcardMutation = {
    createFlashcard?: {
        __typename?: "Flashcard";
        flashcardId: string;
        flashcardSetId?: string | null;
        userId?: string | null;
        term?: string | null;
        termAudio?: string | null;
        definition?: string | null;
        definitionAudio?: string | null;
        distractorIds?: Array<string | null> | null;
        questionType?: QuestionType | null;
        isCorrect?: boolean | null;
        draft?: boolean | null;
        trash?: boolean | null;
        disabled?: boolean | null;
        image?: string | null;
        secondaryImage?: string | null;
        edited?: boolean | null;
        created?: string | null;
        updated?: string | null;
        twoSided?: boolean | null;
        schedule?: string | null;
        quality?: number | null;
        termRecordedAudio?: string | null;
        definitionRecordedAudio?: string | null;
        mcOptions?: Array<string | null> | null;
        termCustomHint?: string | null;
        definitionCustomHint?: string | null;
    } | null;
};

export type BatchUpdateFlashcardMutationVariables = {
    input: FlashcardListInput;
};

export type BatchUpdateFlashcardMutation = {
    batchUpdateFlashcard?: {
        __typename?: "FlashcardConnection";
        items: Array<{
            __typename?: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
            termRecordedAudio?: string | null;
            definitionRecordedAudio?: string | null;
            mcOptions?: Array<string | null> | null;
            termCustomHint?: string | null;
            definitionCustomHint?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type DeleteFlashcardMutationVariables = {
    input: FlashcardInput;
};

export type DeleteFlashcardMutation = {
    deleteFlashcard?: {
        __typename?: "Flashcard";
        flashcardId: string;
        flashcardSetId?: string | null;
        userId?: string | null;
        term?: string | null;
        termAudio?: string | null;
        definition?: string | null;
        definitionAudio?: string | null;
        distractorIds?: Array<string | null> | null;
        questionType?: QuestionType | null;
        isCorrect?: boolean | null;
        draft?: boolean | null;
        trash?: boolean | null;
        disabled?: boolean | null;
        image?: string | null;
        secondaryImage?: string | null;
        edited?: boolean | null;
        created?: string | null;
        updated?: string | null;
        twoSided?: boolean | null;
        schedule?: string | null;
        quality?: number | null;
        termRecordedAudio?: string | null;
        definitionRecordedAudio?: string | null;
        mcOptions?: Array<string | null> | null;
        termCustomHint?: string | null;
        definitionCustomHint?: string | null;
    } | null;
};

export type MarkAsReadMutationVariables = {
    input: NotificationInput;
};

export type MarkAsReadMutation = {
    markAsRead?: {
        __typename?: "GenericReturnType";
        status?: number | null;
        body?: string | null;
    } | null;
};

export type DeleteNotificationMutationVariables = {
    input: NotificationInput;
};

export type DeleteNotificationMutation = {
    deleteNotification?: {
        __typename?: "GenericReturnType";
        status?: number | null;
        body?: string | null;
    } | null;
};

export type UpdateUserDetailsV2MutationVariables = {
    input: UserDetailsInput;
};

export type UpdateUserDetailsV2Mutation = {
    updateUserDetailsV2: {
        __typename?: "UserDetailsContext";
        item: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    };
};

export type FollowMutationVariables = {
    input: FollowInput;
};

export type FollowMutation = {
    follow?: {
        __typename?: "Follower";
        userId?: string | null;
        followerId?: string | null;
    } | null;
};

export type FollowV2MutationVariables = {
    input: FollowInput;
};

export type FollowV2Mutation = {
    followV2?: {
        __typename?: "FollowerContext";
        item: {
            __typename?: "Follower";
            userId?: string | null;
            followerId?: string | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type UnfollowMutationVariables = {
    input: UnfollowInput;
};

export type UnfollowMutation = {
    unfollow?: {
        __typename?: "Follower";
        userId?: string | null;
        followerId?: string | null;
    } | null;
};

export type RemoveFollowerMutationVariables = {
    input: RemoveFollowerInput;
};

export type RemoveFollowerMutation = {
    removeFollower?: {
        __typename?: "Follower";
        userId?: string | null;
        followerId?: string | null;
    } | null;
};

export type UpdateUsernameV2MutationVariables = {
    input: UpdateUsernameInput;
};

export type UpdateUsernameV2Mutation = {
    updateUsernameV2?: {
        __typename?: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: AccountType | null;
        ID?: string | null;
        elID?: string | null;
        clID?: string | null;
        isSSOChecked?: boolean | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        views?: number | null;
        birthday?: string | null;
        phone?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename?: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename?: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            resumes_at?: number | null;
            interval?: string | null;
            status?: string | null;
            platform?: PlatformEnum | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename?: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            frq?: number | null;
            cost?: number | null;
            mSets?: number | null;
            mNotes?: number | null;
            tools?: number | null;
            assignments?: number | null;
            scans?: number | null;
            aiV?: number | null;
            history?: Array<{
                __typename?: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename?: "ClassInfo";
            enrolled: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
        signInTypes?: Array<SignInType> | null;
        orgPlanType?: SubscriptionType | null;
        orgPlanCreated?: number | null;
        lastOrgPlanUpdated?: number | null;
        favTools?: Array<string> | null;
        lastStreakDate?: string | null;
        xpSyncDate?: string | null;
        xp?: number | null;
        level?: number | null;
        streak?: number | null;
        longPauseEnd?: string | null;
        coins?: number | null;
        featuredBadges?: Array<string> | null;
        records?: {
            __typename?: "GamifyRecords";
            xpEarned: string;
            longestStreak: string;
            badgesEarned: string;
            coinsEarned: string;
        } | null;
        inventory?: {
            __typename?: "GamifyInventory";
            freezes: number;
            revivals: number;
            pauses: number;
            icon: StoreProductEnum;
            features: Array<StoreProductEnum>;
            gamifySubExpiry?: number | null;
            gamifySubStoreProduct?: StoreProductEnum | null;
        } | null;
        gameBlock?: string | null;
        lastAdWatch?: string | null;
        lastTimeZoneChange?: number | null;
        notifSettings?: {
            __typename?: "NotificationSettings";
            emailSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            pushSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            gamifySettings?: {
                __typename?: "GamificationSettings";
                level: boolean;
                streak: boolean;
                badges: boolean;
            } | null;
        } | null;
        stoppedNotifications?: boolean | null;
        inactiveUser?: boolean | null;
        promoCodes?: Array<{
            __typename?: "PromoCode";
            code: string;
            category: string;
        }> | null;
        sentPromotions?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        orgRequests?: Array<UpgradeEvent> | null;
        orgReqTime?: string | null;
        emailsSent?: number | null;
        lastSent?: number | null;
        isSidebarExpanded?: boolean | null;
    } | null;
};

export type ViewItemsMutationVariables = {
    input: TemporaryViewInput;
};

export type ViewItemsMutation = {
    viewItems?: {
        __typename?: "TemporaryViewOutput";
        viewedItems: Array<{
            __typename?: "TemporaryView";
            itemId: string;
            itemType: ItemType;
            count: number;
            itemOwnerId: string;
            viewTime: number;
        }>;
    } | null;
};

export type BookmarkMutationVariables = {
    input: CreateBookmarkInput;
};

export type BookmarkMutation = {
    bookmark?: {
        __typename?: "GenericReturnType";
        status?: number | null;
        body?: string | null;
    } | null;
};

export type UnbookmarkMutationVariables = {
    input: DeleteBookmarkInput;
};

export type UnbookmarkMutation = {
    unbookmark?: {
        __typename?: "GenericReturnType";
        status?: number | null;
        body?: string | null;
    } | null;
};

export type CreateFolderMutationVariables = {
    input: CreateFolderInput;
};

export type CreateFolderMutation = {
    createFolder?: {
        __typename?: "Folder";
        userId: string;
        folderId: string;
        parentId?: string | null;
        name?: string | null;
        color?: string | null;
        description?: string | null;
        created?: string | null;
        updated?: string | null;
        notesCount?: number | null;
        trash?: boolean | null;
        public?: boolean | null;
        password?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        partial?: boolean | null;
    } | null;
};

export type UpdateFolderMutationVariables = {
    input: UpdateFolderInput;
};

export type UpdateFolderMutation = {
    updateFolder?: {
        __typename?: "Folder";
        userId: string;
        folderId: string;
        parentId?: string | null;
        name?: string | null;
        color?: string | null;
        description?: string | null;
        created?: string | null;
        updated?: string | null;
        notesCount?: number | null;
        trash?: boolean | null;
        public?: boolean | null;
        password?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        partial?: boolean | null;
    } | null;
};

export type DeleteFolderMutationVariables = {
    input: DeleteFolderInput;
};

export type DeleteFolderMutation = {
    deleteFolder?: {
        __typename?: "Folder";
        userId: string;
        folderId: string;
        parentId?: string | null;
        name?: string | null;
        color?: string | null;
        description?: string | null;
        created?: string | null;
        updated?: string | null;
        notesCount?: number | null;
        trash?: boolean | null;
        public?: boolean | null;
        password?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        partial?: boolean | null;
    } | null;
};

export type CreateRatingV2MutationVariables = {
    input: CreateRatingInput;
};

export type CreateRatingV2Mutation = {
    createRatingV2?: {
        __typename?: "RatingV2Context";
        item: {
            __typename?: "Rating";
            noteId: string;
            userId: string;
            noteOwnerId: string;
            message?: string | null;
            rating?: number | null;
            type?: ItemType | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type UpdateRatingMutationVariables = {
    input: UpdateRatingInput;
};

export type UpdateRatingMutation = {
    updateRating?: {
        __typename?: "Rating";
        noteId: string;
        userId: string;
        noteOwnerId: string;
        message?: string | null;
        rating?: number | null;
        type?: ItemType | null;
    } | null;
};

export type CreateFlashcardSetViewerMutationVariables = {
    input: FlashcardSetViewerInput;
};

export type CreateFlashcardSetViewerMutation = {
    createFlashcardSetViewer?: {
        __typename?: "FlashcardSetViewer";
        userId: string;
        flashcardSetId: string;
        position?: number | null;
        starred?: Array<string | null> | null;
        length?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        studiedFlashcards?: Array<string | null> | null;
        order?: Array<string | null> | null;
        shuffled?: boolean | null;
        lastViewedFlashcard?: string | null;
        answerSide?: FlashcardSide | null;
        reviewMode?: FlashcardReviewMode | null;
    } | null;
};

export type UpdateFlashcardSetViewerMutationVariables = {
    input: FlashcardSetViewerInput;
};

export type UpdateFlashcardSetViewerMutation = {
    updateFlashcardSetViewer?: {
        __typename?: "FlashcardSetViewer";
        userId: string;
        flashcardSetId: string;
        position?: number | null;
        starred?: Array<string | null> | null;
        length?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        studiedFlashcards?: Array<string | null> | null;
        order?: Array<string | null> | null;
        shuffled?: boolean | null;
        lastViewedFlashcard?: string | null;
        answerSide?: FlashcardSide | null;
        reviewMode?: FlashcardReviewMode | null;
    } | null;
};

export type MigrateFlashcardSetMutationVariables = {
    input: GetFlashcardSetInput;
};

export type MigrateFlashcardSetMutation = {
    migrateFlashcardSet?: {
        __typename?: "FlashcardSet";
        userId: string;
        flashcardSetId: string;
        sort?: number | null;
        noteId?: string | null;
        mediaId?: string | null;
        position?: number | null;
        title?: string | null;
        description?: string | null;
        public?: boolean | null;
        password?: string | null;
        trash?: boolean | null;
        draft?: boolean | null;
        starred?: Array<string | null> | null;
        size?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        created?: number | null;
        updated?: number | null;
        quizletUrl?: string | null;
        answerSide?: FlashcardSide | null;
        shuffled?: boolean | null;
        flashcards?: Array<{
            __typename?: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
            termRecordedAudio?: string | null;
            definitionRecordedAudio?: string | null;
            mcOptions?: Array<string | null> | null;
            termCustomHint?: string | null;
            definitionCustomHint?: string | null;
        } | null> | null;
        termLanguage?: Language | null;
        definitionLanguage?: Language | null;
        relatedNotes?: Array<string | null> | null;
        order?: Array<number | null> | null;
        flashcardSetCode?: string | null;
        views?: number | null;
        classPublic?: boolean | null;
        subject?: string | null;
        verified?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        folderId?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        lock?: boolean | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        studySettings?: {
            __typename?: "FlashcardStudySettings";
            blockedStudyModes: Array<StudySessionType>;
            settings: {
                __typename?: "FlashcardsSetLockedStudySettings";
                LEARN?: {
                    __typename?: "LearnLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchLockedSettings";
                    locked: Array<string>;
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    position?: number | null;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                } | null;
            };
        } | null;
        isAI?: boolean | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        deadline?: number | null;
    } | null;
};

export type MigrateUserFlashcardSetsMutationVariables = {
    input: UserIdInput;
};

export type MigrateUserFlashcardSetsMutation = {
    migrateUserFlashcardSets?: {
        __typename?: "FlashcardSet";
        userId: string;
        flashcardSetId: string;
        sort?: number | null;
        noteId?: string | null;
        mediaId?: string | null;
        position?: number | null;
        title?: string | null;
        description?: string | null;
        public?: boolean | null;
        password?: string | null;
        trash?: boolean | null;
        draft?: boolean | null;
        starred?: Array<string | null> | null;
        size?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        created?: number | null;
        updated?: number | null;
        quizletUrl?: string | null;
        answerSide?: FlashcardSide | null;
        shuffled?: boolean | null;
        flashcards?: Array<{
            __typename?: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
            termRecordedAudio?: string | null;
            definitionRecordedAudio?: string | null;
            mcOptions?: Array<string | null> | null;
            termCustomHint?: string | null;
            definitionCustomHint?: string | null;
        } | null> | null;
        termLanguage?: Language | null;
        definitionLanguage?: Language | null;
        relatedNotes?: Array<string | null> | null;
        order?: Array<number | null> | null;
        flashcardSetCode?: string | null;
        views?: number | null;
        classPublic?: boolean | null;
        subject?: string | null;
        verified?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        folderId?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        lock?: boolean | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        studySettings?: {
            __typename?: "FlashcardStudySettings";
            blockedStudyModes: Array<StudySessionType>;
            settings: {
                __typename?: "FlashcardsSetLockedStudySettings";
                LEARN?: {
                    __typename?: "LearnLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchLockedSettings";
                    locked: Array<string>;
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    position?: number | null;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                } | null;
            };
        } | null;
        isAI?: boolean | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        deadline?: number | null;
    } | null;
};

export type VerifyStripeCheckoutMutationVariables = {
    input: StripeConnectionInput;
};

export type VerifyStripeCheckoutMutation = {
    verifyStripeCheckout?: {
        __typename?: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: AccountType | null;
        ID?: string | null;
        elID?: string | null;
        clID?: string | null;
        isSSOChecked?: boolean | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        views?: number | null;
        birthday?: string | null;
        phone?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename?: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename?: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            resumes_at?: number | null;
            interval?: string | null;
            status?: string | null;
            platform?: PlatformEnum | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename?: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            frq?: number | null;
            cost?: number | null;
            mSets?: number | null;
            mNotes?: number | null;
            tools?: number | null;
            assignments?: number | null;
            scans?: number | null;
            aiV?: number | null;
            history?: Array<{
                __typename?: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename?: "ClassInfo";
            enrolled: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
        signInTypes?: Array<SignInType> | null;
        orgPlanType?: SubscriptionType | null;
        orgPlanCreated?: number | null;
        lastOrgPlanUpdated?: number | null;
        favTools?: Array<string> | null;
        lastStreakDate?: string | null;
        xpSyncDate?: string | null;
        xp?: number | null;
        level?: number | null;
        streak?: number | null;
        longPauseEnd?: string | null;
        coins?: number | null;
        featuredBadges?: Array<string> | null;
        records?: {
            __typename?: "GamifyRecords";
            xpEarned: string;
            longestStreak: string;
            badgesEarned: string;
            coinsEarned: string;
        } | null;
        inventory?: {
            __typename?: "GamifyInventory";
            freezes: number;
            revivals: number;
            pauses: number;
            icon: StoreProductEnum;
            features: Array<StoreProductEnum>;
            gamifySubExpiry?: number | null;
            gamifySubStoreProduct?: StoreProductEnum | null;
        } | null;
        gameBlock?: string | null;
        lastAdWatch?: string | null;
        lastTimeZoneChange?: number | null;
        notifSettings?: {
            __typename?: "NotificationSettings";
            emailSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            pushSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            gamifySettings?: {
                __typename?: "GamificationSettings";
                level: boolean;
                streak: boolean;
                badges: boolean;
            } | null;
        } | null;
        stoppedNotifications?: boolean | null;
        inactiveUser?: boolean | null;
        promoCodes?: Array<{
            __typename?: "PromoCode";
            code: string;
            category: string;
        }> | null;
        sentPromotions?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        orgRequests?: Array<UpgradeEvent> | null;
        orgReqTime?: string | null;
        emailsSent?: number | null;
        lastSent?: number | null;
        isSidebarExpanded?: boolean | null;
    } | null;
};

export type RequestDeleteAccountMutationVariables = {
    input: DeleteAccountRequestInput;
};

export type RequestDeleteAccountMutation = {
    requestDeleteAccount?: {
        __typename?: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: AccountType | null;
        ID?: string | null;
        elID?: string | null;
        clID?: string | null;
        isSSOChecked?: boolean | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        views?: number | null;
        birthday?: string | null;
        phone?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename?: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename?: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            resumes_at?: number | null;
            interval?: string | null;
            status?: string | null;
            platform?: PlatformEnum | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename?: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            frq?: number | null;
            cost?: number | null;
            mSets?: number | null;
            mNotes?: number | null;
            tools?: number | null;
            assignments?: number | null;
            scans?: number | null;
            aiV?: number | null;
            history?: Array<{
                __typename?: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename?: "ClassInfo";
            enrolled: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
        signInTypes?: Array<SignInType> | null;
        orgPlanType?: SubscriptionType | null;
        orgPlanCreated?: number | null;
        lastOrgPlanUpdated?: number | null;
        favTools?: Array<string> | null;
        lastStreakDate?: string | null;
        xpSyncDate?: string | null;
        xp?: number | null;
        level?: number | null;
        streak?: number | null;
        longPauseEnd?: string | null;
        coins?: number | null;
        featuredBadges?: Array<string> | null;
        records?: {
            __typename?: "GamifyRecords";
            xpEarned: string;
            longestStreak: string;
            badgesEarned: string;
            coinsEarned: string;
        } | null;
        inventory?: {
            __typename?: "GamifyInventory";
            freezes: number;
            revivals: number;
            pauses: number;
            icon: StoreProductEnum;
            features: Array<StoreProductEnum>;
            gamifySubExpiry?: number | null;
            gamifySubStoreProduct?: StoreProductEnum | null;
        } | null;
        gameBlock?: string | null;
        lastAdWatch?: string | null;
        lastTimeZoneChange?: number | null;
        notifSettings?: {
            __typename?: "NotificationSettings";
            emailSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            pushSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            gamifySettings?: {
                __typename?: "GamificationSettings";
                level: boolean;
                streak: boolean;
                badges: boolean;
            } | null;
        } | null;
        stoppedNotifications?: boolean | null;
        inactiveUser?: boolean | null;
        promoCodes?: Array<{
            __typename?: "PromoCode";
            code: string;
            category: string;
        }> | null;
        sentPromotions?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        orgRequests?: Array<UpgradeEvent> | null;
        orgReqTime?: string | null;
        emailsSent?: number | null;
        lastSent?: number | null;
        isSidebarExpanded?: boolean | null;
    } | null;
};

export type DetectLanguageMutationVariables = {
    input: TextToSpeechInput;
};

export type DetectLanguageMutation = {
    detectLanguage?: {
        __typename?: "LanguageResponse";
        language?: Language | null;
        confidence?: number | null;
    } | null;
};

export type TextToSpeechMutationVariables = {
    input: TextToSpeechInput;
};

export type TextToSpeechMutation = {
    textToSpeech?: {
        __typename?: "TextToSpeechResponse";
        url: string;
    } | null;
};

export type DeleteAudioMutationVariables = {
    input: TextToSpeechInput;
};

export type DeleteAudioMutation = {
    deleteAudio?: {
        __typename?: "TextToSpeechResponse";
        url: string;
    } | null;
};

export type CreatePasswordMutationVariables = {
    input: PasswordInput;
};

export type CreatePasswordMutation = {
    createPassword?: {
        __typename?: "Password";
        password: string;
        type: ItemType;
        id: string;
    } | null;
};

export type CheckPasswordMutationVariables = {
    input: PasswordInput;
};

export type CheckPasswordMutation = {
    checkPassword?: boolean | null;
};

export type EditChapterMutationVariables = {
    input: EditChapterInput;
};

export type EditChapterMutation = {
    editChapter?: {
        __typename?: "GenericReturnType";
        status?: number | null;
        body?: string | null;
    } | null;
};

export type CreateBlogMutationVariables = {
    input?: BlogInput | null;
};

export type CreateBlogMutation = {
    createBlog?: {
        __typename?: "Blog";
        userId: string;
        blogId: string;
        title: string;
        slug: string;
        content: string;
        summary?: string | null;
        created: string;
        updated: string;
        tags?: string | null;
        coverImage?: {
            __typename?: "BlogCoverImage";
            url?: string | null;
            width?: number | null;
            height?: number | null;
        } | null;
        metadata?: {
            __typename?: "BlogSEOMetadata";
            title?: string | null;
            description?: string | null;
        } | null;
        draft?: boolean | null;
        trash?: boolean | null;
        type?: string | null;
        pinned?: number | null;
    } | null;
};

export type UpdateBlogMutationVariables = {
    input?: BlogInput | null;
};

export type UpdateBlogMutation = {
    updateBlog?: {
        __typename?: "Blog";
        userId: string;
        blogId: string;
        title: string;
        slug: string;
        content: string;
        summary?: string | null;
        created: string;
        updated: string;
        tags?: string | null;
        coverImage?: {
            __typename?: "BlogCoverImage";
            url?: string | null;
            width?: number | null;
            height?: number | null;
        } | null;
        metadata?: {
            __typename?: "BlogSEOMetadata";
            title?: string | null;
            description?: string | null;
        } | null;
        draft?: boolean | null;
        trash?: boolean | null;
        type?: string | null;
        pinned?: number | null;
    } | null;
};

export type DeleteBlogMutationVariables = {
    input?: BlogInput | null;
};

export type DeleteBlogMutation = {
    deleteBlog?: {
        __typename?: "Blog";
        userId: string;
        blogId: string;
        title: string;
        slug: string;
        content: string;
        summary?: string | null;
        created: string;
        updated: string;
        tags?: string | null;
        coverImage?: {
            __typename?: "BlogCoverImage";
            url?: string | null;
            width?: number | null;
            height?: number | null;
        } | null;
        metadata?: {
            __typename?: "BlogSEOMetadata";
            title?: string | null;
            description?: string | null;
        } | null;
        draft?: boolean | null;
        trash?: boolean | null;
        type?: string | null;
        pinned?: number | null;
    } | null;
};

export type StartFlashcardSetStudySessionRoundMutationVariables = {
    input: FlashcardSetStudySessionInput;
};

export type StartFlashcardSetStudySessionRoundMutation = {
    startFlashcardSetStudySessionRound?: {
        __typename?: "FlashcardSetStudySessionConnection";
        studySession: {
            __typename?: "StudySession";
            userId: string;
            itemId: string;
            type: ItemType;
            created: string;
            updated: string;
            examDate?: string | null;
            unmigrated?: boolean | null;
            newCards: number;
            nextDue: string;
            nextNewDue: string;
            settings: {
                __typename?: "StudySessionSettings";
                LEARN?: {
                    __typename?: "LearnStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                    override?: boolean | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchStudySessionSetting";
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    sorting?: boolean | null;
                } | null;
            };
            progress: {
                __typename?: "StudySessionProgress";
                NEW: number;
                LEARNING: number;
                REVIEWING: number;
                MASTERED: number;
            };
            practiceTests?: Array<{
                __typename?: "PracticeTestProgress";
                timeTaken: number;
                timestamp: number;
                score: string;
            }> | null;
            syncTime: string;
            lastView?: string | null;
            title?: string | null;
        };
        questions: Array<{
            __typename?: "FlashcardSetStudySessionQuestion";
            flashcardId: string;
            distractors: Array<string>;
            questionType: QuestionType;
            answerSide: FlashcardSide;
            buttons?: Array<number> | null;
            tfIsCorrect: boolean;
            progress: StudySessionProgressEnum;
            spacing?: {
                __typename?: "SpacingIntervals";
                AGAIN: number;
                HARD: number;
                GOOD: number;
                EASY: number;
            } | null;
        }>;
    } | null;
};

export type GradeFlashcardSetStudySessionV2MutationVariables = {
    input: GradeFlashcardSetStudySessionInput;
};

export type GradeFlashcardSetStudySessionV2Mutation = {
    gradeFlashcardSetStudySessionV2?: {
        __typename?: "FlashcardSetStudySessionRoundV2Context";
        item: {
            __typename?: "FlashcardSetStudySessionRound";
            studySession: {
                __typename?: "StudySession";
                userId: string;
                itemId: string;
                type: ItemType;
                created: string;
                updated: string;
                examDate?: string | null;
                unmigrated?: boolean | null;
                newCards: number;
                nextDue: string;
                nextNewDue: string;
                settings: {
                    __typename?: "StudySessionSettings";
                    LEARN?: {
                        __typename?: "LearnStudySessionSetting";
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                        length?: number | null;
                        override?: boolean | null;
                    } | null;
                    TEST?: {
                        __typename?: "TestStudySessionSetting";
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        length: number;
                    } | null;
                    SPACED?: {
                        __typename?: "SpacedStudySessionSetting";
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        cardsPerSession: number;
                        intervals?: Array<number> | null;
                        override?: boolean | null;
                    } | null;
                    MATCH?: {
                        __typename?: "MatchStudySessionSetting";
                        grid: boolean;
                        starred: boolean;
                        length: number;
                    } | null;
                    REVIEW?: {
                        __typename?: "ReviewStudySessionSetting";
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                        sorting?: boolean | null;
                    } | null;
                };
                progress: {
                    __typename?: "StudySessionProgress";
                    NEW: number;
                    LEARNING: number;
                    REVIEWING: number;
                    MASTERED: number;
                };
                practiceTests?: Array<{
                    __typename?: "PracticeTestProgress";
                    timeTaken: number;
                    timestamp: number;
                    score: string;
                }> | null;
                syncTime: string;
                lastView?: string | null;
                title?: string | null;
            };
            studyStates: Array<{
                __typename?: "FlashcardStudyState";
                userId: string;
                flashcardId: string;
                flashcardSetId: string;
                created: string;
                updated: string;
                distractors: Array<string>;
                mastery: number;
                lastStudied: string;
                blank?: boolean | null;
                sort?: FlashcardSortingEnum | null;
                nextDue: string;
                difficulty?: number | null;
                interval: number;
                eFactor: number;
                progress: StudySessionProgressEnum;
                history: Array<{
                    __typename?: "FlashcardStudyStateHistory";
                    timestamp: number;
                    timeTaken: number;
                    mode: StudySessionType;
                    questionType: QuestionType;
                    selectedFlashcardId?: string | null;
                    side: FlashcardSide;
                    button?: SpacedRepetitionButton | null;
                    isCorrect: boolean;
                    answer: string;
                }>;
            }>;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        level?: number | null;
    } | null;
};

export type ResetStudySessionSortingMutationVariables = {
    input: ResetStudySessionSortingInput;
};

export type ResetStudySessionSortingMutation = {
    resetStudySessionSorting?: {
        __typename?: "FlashcardStudyStateConnection";
        items: Array<{
            __typename?: "FlashcardStudyState";
            userId: string;
            flashcardId: string;
            flashcardSetId: string;
            created: string;
            updated: string;
            distractors: Array<string>;
            mastery: number;
            lastStudied: string;
            blank?: boolean | null;
            sort?: FlashcardSortingEnum | null;
            nextDue: string;
            difficulty?: number | null;
            interval: number;
            eFactor: number;
            progress: StudySessionProgressEnum;
            history: Array<{
                __typename?: "FlashcardStudyStateHistory";
                timestamp: number;
                timeTaken: number;
                mode: StudySessionType;
                questionType: QuestionType;
                selectedFlashcardId?: string | null;
                side: FlashcardSide;
                button?: SpacedRepetitionButton | null;
                isCorrect: boolean;
                answer: string;
            }>;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ClearFlashcardSetStudySessionMutationVariables = {
    input: ClearFlashcardSetStudySessionInput;
};

export type ClearFlashcardSetStudySessionMutation = {
    clearFlashcardSetStudySession?: {
        __typename?: "StudySession";
        userId: string;
        itemId: string;
        type: ItemType;
        created: string;
        updated: string;
        examDate?: string | null;
        unmigrated?: boolean | null;
        newCards: number;
        nextDue: string;
        nextNewDue: string;
        settings: {
            __typename?: "StudySessionSettings";
            LEARN?: {
                __typename?: "LearnStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                reType: boolean;
                length?: number | null;
                override?: boolean | null;
            } | null;
            TEST?: {
                __typename?: "TestStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                length: number;
            } | null;
            SPACED?: {
                __typename?: "SpacedStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                cardsPerSession: number;
                intervals?: Array<number> | null;
                override?: boolean | null;
            } | null;
            MATCH?: {
                __typename?: "MatchStudySessionSetting";
                grid: boolean;
                starred: boolean;
                length: number;
            } | null;
            REVIEW?: {
                __typename?: "ReviewStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                reType: boolean;
                sorting?: boolean | null;
            } | null;
        };
        progress: {
            __typename?: "StudySessionProgress";
            NEW: number;
            LEARNING: number;
            REVIEWING: number;
            MASTERED: number;
        };
        practiceTests?: Array<{
            __typename?: "PracticeTestProgress";
            timeTaken: number;
            timestamp: number;
            score: string;
        }> | null;
        syncTime: string;
        lastView?: string | null;
        title?: string | null;
    } | null;
};

export type GradeNoteTestMutationVariables = {
    input: GradeNoteTestInput;
};

export type GradeNoteTestMutation = {
    gradeNoteTest?: {
        __typename?: "StudySession";
        userId: string;
        itemId: string;
        type: ItemType;
        created: string;
        updated: string;
        examDate?: string | null;
        unmigrated?: boolean | null;
        newCards: number;
        nextDue: string;
        nextNewDue: string;
        settings: {
            __typename?: "StudySessionSettings";
            LEARN?: {
                __typename?: "LearnStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                reType: boolean;
                length?: number | null;
                override?: boolean | null;
            } | null;
            TEST?: {
                __typename?: "TestStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                length: number;
            } | null;
            SPACED?: {
                __typename?: "SpacedStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                cardsPerSession: number;
                intervals?: Array<number> | null;
                override?: boolean | null;
            } | null;
            MATCH?: {
                __typename?: "MatchStudySessionSetting";
                grid: boolean;
                starred: boolean;
                length: number;
            } | null;
            REVIEW?: {
                __typename?: "ReviewStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                reType: boolean;
                sorting?: boolean | null;
            } | null;
        };
        progress: {
            __typename?: "StudySessionProgress";
            NEW: number;
            LEARNING: number;
            REVIEWING: number;
            MASTERED: number;
        };
        practiceTests?: Array<{
            __typename?: "PracticeTestProgress";
            timeTaken: number;
            timestamp: number;
            score: string;
        }> | null;
        syncTime: string;
        lastView?: string | null;
        title?: string | null;
    } | null;
};

export type SubmitAICompletionMutationVariables = {
    input?: SubmitAICompletionInput | null;
};

export type SubmitAICompletionMutation = {
    submitAICompletion?: {
        __typename?: "AICompletion";
        userId: string;
        timestamp?: string | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        flashcardId?: string | null;
        itemId?: string | null;
        itemType?: ItemType | null;
        id?: string | null;
        input?: string | null;
        messages?: Array<{
            __typename?: "AIMessage";
            role?: string | null;
            content?: string | null;
        } | null> | null;
        output: string;
        inputTokens?: number | null;
        outputTokens?: number | null;
        subject?: string | null;
        topic?: string | null;
        type?: AICompletionType | null;
        timeTaken?: number | null;
        params?: string | null;
    } | null;
};

export type UpdateEmailMutationVariables = {
    input: UpdateEmailInput;
};

export type UpdateEmailMutation = {
    updateEmail?: {
        __typename?: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: AccountType | null;
        ID?: string | null;
        elID?: string | null;
        clID?: string | null;
        isSSOChecked?: boolean | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        views?: number | null;
        birthday?: string | null;
        phone?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename?: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename?: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            resumes_at?: number | null;
            interval?: string | null;
            status?: string | null;
            platform?: PlatformEnum | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename?: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            frq?: number | null;
            cost?: number | null;
            mSets?: number | null;
            mNotes?: number | null;
            tools?: number | null;
            assignments?: number | null;
            scans?: number | null;
            aiV?: number | null;
            history?: Array<{
                __typename?: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename?: "ClassInfo";
            enrolled: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
        signInTypes?: Array<SignInType> | null;
        orgPlanType?: SubscriptionType | null;
        orgPlanCreated?: number | null;
        lastOrgPlanUpdated?: number | null;
        favTools?: Array<string> | null;
        lastStreakDate?: string | null;
        xpSyncDate?: string | null;
        xp?: number | null;
        level?: number | null;
        streak?: number | null;
        longPauseEnd?: string | null;
        coins?: number | null;
        featuredBadges?: Array<string> | null;
        records?: {
            __typename?: "GamifyRecords";
            xpEarned: string;
            longestStreak: string;
            badgesEarned: string;
            coinsEarned: string;
        } | null;
        inventory?: {
            __typename?: "GamifyInventory";
            freezes: number;
            revivals: number;
            pauses: number;
            icon: StoreProductEnum;
            features: Array<StoreProductEnum>;
            gamifySubExpiry?: number | null;
            gamifySubStoreProduct?: StoreProductEnum | null;
        } | null;
        gameBlock?: string | null;
        lastAdWatch?: string | null;
        lastTimeZoneChange?: number | null;
        notifSettings?: {
            __typename?: "NotificationSettings";
            emailSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            pushSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            gamifySettings?: {
                __typename?: "GamificationSettings";
                level: boolean;
                streak: boolean;
                badges: boolean;
            } | null;
        } | null;
        stoppedNotifications?: boolean | null;
        inactiveUser?: boolean | null;
        promoCodes?: Array<{
            __typename?: "PromoCode";
            code: string;
            category: string;
        }> | null;
        sentPromotions?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        orgRequests?: Array<UpgradeEvent> | null;
        orgReqTime?: string | null;
        emailsSent?: number | null;
        lastSent?: number | null;
        isSidebarExpanded?: boolean | null;
    } | null;
};

export type ManuallyVerifyUserMutationVariables = {
    input: ManuallyVerifyUserInput;
};

export type ManuallyVerifyUserMutation = {
    manuallyVerifyUser?: boolean | null;
};

export type CreateClassMutationVariables = {
    input: CreateClassInput;
};

export type CreateClassMutation = {
    createClass?: {
        __typename?: "Class";
        userId: string;
        classId: string;
        sections: Array<{
            __typename?: "ClassSection";
            name: string;
            id: string;
            color: string;
            timings?: Array<{
                __typename?: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            } | null> | null;
        }>;
        members: Array<{
            __typename?: "ClassMemberWithDetails";
            userId: string;
            role: ClassRole;
            sections: Array<string>;
            pending?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            dpa?: boolean | null;
            lastLogIn?: number | null;
        }>;
        memberCount?: number | null;
        pinned: Array<string>;
        created: string;
        updated: string;
        name: string;
        description?: string | null;
        timings?: Array<{
            __typename?: "ClassTimings";
            start?: string | null;
            end?: string | null;
            days?: Array<string | null> | null;
        }> | null;
        timezone?: string | null;
        org?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        color: string;
        cover?: string | null;
        googleClassId?: string | null;
        fileCount?: number | null;
        partial?: boolean | null;
        flashcardStudySettings?: {
            __typename?: "FlashcardStudySettings";
            blockedStudyModes: Array<StudySessionType>;
            settings: {
                __typename?: "FlashcardsSetLockedStudySettings";
                LEARN?: {
                    __typename?: "LearnLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchLockedSettings";
                    locked: Array<string>;
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    position?: number | null;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                } | null;
            };
        } | null;
    } | null;
};

export type CreateClassV2MutationVariables = {
    input: CreateClassInput;
};

export type CreateClassV2Mutation = {
    createClassV2?: {
        __typename?: "ClassContext";
        item: {
            __typename?: "Class";
            userId: string;
            classId: string;
            sections: Array<{
                __typename?: "ClassSection";
                name: string;
                id: string;
                color: string;
                timings?: Array<{
                    __typename?: "ClassTimings";
                    start?: string | null;
                    end?: string | null;
                    days?: Array<string | null> | null;
                } | null> | null;
            }>;
            members: Array<{
                __typename?: "ClassMemberWithDetails";
                userId: string;
                role: ClassRole;
                sections: Array<string>;
                pending?: boolean | null;
                Name?: string | null;
                pictureUrl?: string | null;
                username?: string | null;
                dpa?: boolean | null;
                lastLogIn?: number | null;
            }>;
            memberCount?: number | null;
            pinned: Array<string>;
            created: string;
            updated: string;
            name: string;
            description?: string | null;
            timings?: Array<{
                __typename?: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            }> | null;
            timezone?: string | null;
            org?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            color: string;
            cover?: string | null;
            googleClassId?: string | null;
            fileCount?: number | null;
            partial?: boolean | null;
            flashcardStudySettings?: {
                __typename?: "FlashcardStudySettings";
                blockedStudyModes: Array<StudySessionType>;
                settings: {
                    __typename?: "FlashcardsSetLockedStudySettings";
                    LEARN?: {
                        __typename?: "LearnLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                        length?: number | null;
                        override?: boolean | null;
                    } | null;
                    TEST?: {
                        __typename?: "TestLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        length: number;
                    } | null;
                    SPACED?: {
                        __typename?: "SpacedLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        cardsPerSession: number;
                        intervals?: Array<number> | null;
                    } | null;
                    MATCH?: {
                        __typename?: "MatchLockedSettings";
                        locked: Array<string>;
                        grid: boolean;
                        starred: boolean;
                        length: number;
                    } | null;
                    REVIEW?: {
                        __typename?: "ReviewLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        position?: number | null;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                    } | null;
                };
            } | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type UpdateClassMutationVariables = {
    input: UpdateClassInput;
};

export type UpdateClassMutation = {
    updateClass?: {
        __typename?: "Class";
        userId: string;
        classId: string;
        sections: Array<{
            __typename?: "ClassSection";
            name: string;
            id: string;
            color: string;
            timings?: Array<{
                __typename?: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            } | null> | null;
        }>;
        members: Array<{
            __typename?: "ClassMemberWithDetails";
            userId: string;
            role: ClassRole;
            sections: Array<string>;
            pending?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            dpa?: boolean | null;
            lastLogIn?: number | null;
        }>;
        memberCount?: number | null;
        pinned: Array<string>;
        created: string;
        updated: string;
        name: string;
        description?: string | null;
        timings?: Array<{
            __typename?: "ClassTimings";
            start?: string | null;
            end?: string | null;
            days?: Array<string | null> | null;
        }> | null;
        timezone?: string | null;
        org?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        color: string;
        cover?: string | null;
        googleClassId?: string | null;
        fileCount?: number | null;
        partial?: boolean | null;
        flashcardStudySettings?: {
            __typename?: "FlashcardStudySettings";
            blockedStudyModes: Array<StudySessionType>;
            settings: {
                __typename?: "FlashcardsSetLockedStudySettings";
                LEARN?: {
                    __typename?: "LearnLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchLockedSettings";
                    locked: Array<string>;
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    position?: number | null;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                } | null;
            };
        } | null;
    } | null;
};

export type DeleteClassMutationVariables = {
    input: DeleteClassInput;
};

export type DeleteClassMutation = {
    deleteClass?: {
        __typename?: "Class";
        userId: string;
        classId: string;
        sections: Array<{
            __typename?: "ClassSection";
            name: string;
            id: string;
            color: string;
            timings?: Array<{
                __typename?: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            } | null> | null;
        }>;
        members: Array<{
            __typename?: "ClassMemberWithDetails";
            userId: string;
            role: ClassRole;
            sections: Array<string>;
            pending?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            dpa?: boolean | null;
            lastLogIn?: number | null;
        }>;
        memberCount?: number | null;
        pinned: Array<string>;
        created: string;
        updated: string;
        name: string;
        description?: string | null;
        timings?: Array<{
            __typename?: "ClassTimings";
            start?: string | null;
            end?: string | null;
            days?: Array<string | null> | null;
        }> | null;
        timezone?: string | null;
        org?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        color: string;
        cover?: string | null;
        googleClassId?: string | null;
        fileCount?: number | null;
        partial?: boolean | null;
        flashcardStudySettings?: {
            __typename?: "FlashcardStudySettings";
            blockedStudyModes: Array<StudySessionType>;
            settings: {
                __typename?: "FlashcardsSetLockedStudySettings";
                LEARN?: {
                    __typename?: "LearnLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchLockedSettings";
                    locked: Array<string>;
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    position?: number | null;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                } | null;
            };
        } | null;
    } | null;
};

export type AddMediaS3UserTagMutationVariables = {
    input: AddMediaS3UserTagInput;
};

export type AddMediaS3UserTagMutation = {
    addMediaS3UserTag?: boolean | null;
};

export type JoinClassMutationVariables = {
    input: JoinClassInput;
};

export type JoinClassMutation = {
    joinClass?: {
        __typename?: "Class";
        userId: string;
        classId: string;
        sections: Array<{
            __typename?: "ClassSection";
            name: string;
            id: string;
            color: string;
            timings?: Array<{
                __typename?: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            } | null> | null;
        }>;
        members: Array<{
            __typename?: "ClassMemberWithDetails";
            userId: string;
            role: ClassRole;
            sections: Array<string>;
            pending?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            dpa?: boolean | null;
            lastLogIn?: number | null;
        }>;
        memberCount?: number | null;
        pinned: Array<string>;
        created: string;
        updated: string;
        name: string;
        description?: string | null;
        timings?: Array<{
            __typename?: "ClassTimings";
            start?: string | null;
            end?: string | null;
            days?: Array<string | null> | null;
        }> | null;
        timezone?: string | null;
        org?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        color: string;
        cover?: string | null;
        googleClassId?: string | null;
        fileCount?: number | null;
        partial?: boolean | null;
        flashcardStudySettings?: {
            __typename?: "FlashcardStudySettings";
            blockedStudyModes: Array<StudySessionType>;
            settings: {
                __typename?: "FlashcardsSetLockedStudySettings";
                LEARN?: {
                    __typename?: "LearnLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchLockedSettings";
                    locked: Array<string>;
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    position?: number | null;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                } | null;
            };
        } | null;
    } | null;
};

export type JoinClassV2MutationVariables = {
    input: JoinClassInput;
};

export type JoinClassV2Mutation = {
    joinClassV2?: {
        __typename?: "ClassContext";
        item: {
            __typename?: "Class";
            userId: string;
            classId: string;
            sections: Array<{
                __typename?: "ClassSection";
                name: string;
                id: string;
                color: string;
                timings?: Array<{
                    __typename?: "ClassTimings";
                    start?: string | null;
                    end?: string | null;
                    days?: Array<string | null> | null;
                } | null> | null;
            }>;
            members: Array<{
                __typename?: "ClassMemberWithDetails";
                userId: string;
                role: ClassRole;
                sections: Array<string>;
                pending?: boolean | null;
                Name?: string | null;
                pictureUrl?: string | null;
                username?: string | null;
                dpa?: boolean | null;
                lastLogIn?: number | null;
            }>;
            memberCount?: number | null;
            pinned: Array<string>;
            created: string;
            updated: string;
            name: string;
            description?: string | null;
            timings?: Array<{
                __typename?: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            }> | null;
            timezone?: string | null;
            org?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            color: string;
            cover?: string | null;
            googleClassId?: string | null;
            fileCount?: number | null;
            partial?: boolean | null;
            flashcardStudySettings?: {
                __typename?: "FlashcardStudySettings";
                blockedStudyModes: Array<StudySessionType>;
                settings: {
                    __typename?: "FlashcardsSetLockedStudySettings";
                    LEARN?: {
                        __typename?: "LearnLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                        length?: number | null;
                        override?: boolean | null;
                    } | null;
                    TEST?: {
                        __typename?: "TestLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        length: number;
                    } | null;
                    SPACED?: {
                        __typename?: "SpacedLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        cardsPerSession: number;
                        intervals?: Array<number> | null;
                    } | null;
                    MATCH?: {
                        __typename?: "MatchLockedSettings";
                        locked: Array<string>;
                        grid: boolean;
                        starred: boolean;
                        length: number;
                    } | null;
                    REVIEW?: {
                        __typename?: "ReviewLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        position?: number | null;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                    } | null;
                };
            } | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type LeaveClassMutationVariables = {
    input: LeaveClassInput;
};

export type LeaveClassMutation = {
    leaveClass?: {
        __typename?: "Class";
        userId: string;
        classId: string;
        sections: Array<{
            __typename?: "ClassSection";
            name: string;
            id: string;
            color: string;
            timings?: Array<{
                __typename?: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            } | null> | null;
        }>;
        members: Array<{
            __typename?: "ClassMemberWithDetails";
            userId: string;
            role: ClassRole;
            sections: Array<string>;
            pending?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            dpa?: boolean | null;
            lastLogIn?: number | null;
        }>;
        memberCount?: number | null;
        pinned: Array<string>;
        created: string;
        updated: string;
        name: string;
        description?: string | null;
        timings?: Array<{
            __typename?: "ClassTimings";
            start?: string | null;
            end?: string | null;
            days?: Array<string | null> | null;
        }> | null;
        timezone?: string | null;
        org?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        color: string;
        cover?: string | null;
        googleClassId?: string | null;
        fileCount?: number | null;
        partial?: boolean | null;
        flashcardStudySettings?: {
            __typename?: "FlashcardStudySettings";
            blockedStudyModes: Array<StudySessionType>;
            settings: {
                __typename?: "FlashcardsSetLockedStudySettings";
                LEARN?: {
                    __typename?: "LearnLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchLockedSettings";
                    locked: Array<string>;
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    position?: number | null;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                } | null;
            };
        } | null;
    } | null;
};

export type CreateEmbeddingsMutationVariables = {
    input: CreateEmbeddingsInput;
};

export type CreateEmbeddingsMutation = {
    createEmbeddings?: string | null;
};

export type CreateExamMCQSessionMutationVariables = {
    input: CreateExamMCQSessionInput;
};

export type CreateExamMCQSessionMutation = {
    createExamMCQSession?: {
        __typename?: "ExamMCQConnection";
        items: Array<{
            __typename?: "ExamMCQGroup";
            questions: Array<{
                __typename?: "ExamMCQ";
                exam: string;
                questionId: string;
                difficulty: ExamDifficulty;
                choices: Array<string>;
                question: string;
                reasons: Array<string>;
                rating: number;
                ratingCount: number;
                examUnit: string;
                examSection?: string | null;
                noteId?: string | null;
                flashcardSetId?: string | null;
                subject?: string | null;
                topic?: string | null;
                created: string;
                updated: string;
                url?: string | null;
                prompt: ExamPromptType;
                numCorrect: number;
                numQuestions: number;
                stimuli?: Array<string | null> | null;
                stimulusGroupId?: string | null;
                questionGroupId?: string | null;
                replaced?: boolean | null;
                calc?: number | null;
            }>;
            stimuli: Array<{
                __typename?: "ExamStimulus";
                questionId: string;
                stimulusId: string;
                content: string;
                stimulusType: ExamStimulusType;
                schema?: string | null;
            }>;
        }>;
        nextToken?: string | null;
    } | null;
};

export type RateExamMCQMutationVariables = {
    input: RateExamMCQInput;
};

export type RateExamMCQMutation = {
    rateExamMCQ?: {
        __typename?: "ExamMCQ";
        exam: string;
        questionId: string;
        difficulty: ExamDifficulty;
        choices: Array<string>;
        question: string;
        reasons: Array<string>;
        rating: number;
        ratingCount: number;
        examUnit: string;
        examSection?: string | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        subject?: string | null;
        topic?: string | null;
        created: string;
        updated: string;
        url?: string | null;
        prompt: ExamPromptType;
        numCorrect: number;
        numQuestions: number;
        stimuli?: Array<string | null> | null;
        stimulusGroupId?: string | null;
        questionGroupId?: string | null;
        replaced?: boolean | null;
        calc?: number | null;
    } | null;
};

export type RateExamFRQMutationVariables = {
    input: RateExamFRQInput;
};

export type RateExamFRQMutation = {
    rateExamFRQ?: {
        __typename?: "ExamFRQ";
        exam: string;
        questionId: string;
        difficulty: ExamDifficulty;
        question: string;
        rating: number;
        ratingCount: number;
        examUnit: string;
        examSection?: string | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        subject?: string | null;
        topic?: string | null;
        created: string;
        updated: string;
        url?: string | null;
        prompt: ExamPromptType;
        stimuli?: Array<string> | null;
        name: string;
        parts: Array<{
            __typename?: "ExamFRQPart";
            question: string;
            rating: number;
            ratingCount: number;
            stimuli?: Array<string> | null;
            prefill?: string | null;
        }>;
        rubric: Array<{
            __typename?: "ExamFRQRubricParts";
            items: Array<{
                __typename?: "ExamFRQRubricItems";
                guidelines: string;
                points: number;
            }>;
        }>;
        calc?: number | null;
        starred: boolean;
        frqType?: ExamFRQType | null;
    } | null;
};

export type SubmitExamMCQSessionMutationVariables = {
    input: SubmitExamMCQSessionInput;
};

export type SubmitExamMCQSessionMutation = {
    submitExamMCQSession?: {
        __typename?: "ExamProgress";
        userId: string;
        exam: string;
        numQuestions: number;
        numCorrect: number;
        mastery: number;
        numDownvotes?: number | null;
        unitProgress: Array<{
            __typename?: "ExamUnitProgressMap";
            examUnit: string;
            progress: {
                __typename?: "ExamUnitProgress";
                numQuestions: number;
                numCorrect: number;
                mastery: number;
            };
        }>;
    } | null;
};

export type ReferOrganizationV2MutationVariables = {
    input: ReferOrganizationInput;
};

export type ReferOrganizationV2Mutation = {
    referOrganizationV2?: {
        __typename?: "OrganizationContext";
        item: {
            __typename?: "Organization";
            org: string;
            schoolId: string;
            created: string;
            updated: string;
            name?: string | null;
            pictureUrl?: string | null;
            country?: string | null;
            state?: string | null;
            files?: Array<{
                __typename?: "OrganizationFile";
                url: string;
                name: string;
            }> | null;
            students: number;
            teachers: number;
            syncTimestamp?: number | null;
            isDirty?: boolean | null;
            paywall?: {
                __typename?: "OrganizationPaywallInfo";
                count: number;
                lastSent: number;
            } | null;
            aliases?: Array<string> | null;
            stage: OrganizationStage;
            referrals: Array<{
                __typename?: "OrganizationReferral";
                userId: string;
                created: string;
                sent: boolean;
                type: string;
                email: string;
            }>;
            dpa?: {
                __typename?: "OrganizationDPA";
                name?: string | null;
                email?: string | null;
                link?: string | null;
                type: DPAType;
            } | null;
            admins?: Array<{
                __typename?: "OrganizationAdmin";
                userId: string;
                schoolId: string;
            }> | null;
            pendingAdmins?: Array<{
                __typename?: "PendingOrganizationAdmin";
                Email: string;
                schoolId: string;
            }> | null;
            settings?: {
                __typename?: "OrganizationSettings";
                schoolId?: string | null;
                sharing: OrganizationSharing;
                subsAllowed: boolean;
                ai?: boolean | null;
            } | null;
            schoolsSettings?: Array<{
                __typename?: "OrganizationSettings";
                schoolId?: string | null;
                sharing: OrganizationSharing;
                subsAllowed: boolean;
                ai?: boolean | null;
            }> | null;
            verifiedSchools?: boolean | null;
            subscriptions?: Array<{
                __typename?: "OrganizationStripeConnection";
                schoolId: string;
                created: number;
                current_period_end: number;
                studentPlan: SubscriptionType;
                teacherPlan: SubscriptionType;
                studentsPaidFor: number;
                teachersPaidFor: number;
                paidStudents: number;
                paidTeachers: number;
                autoSubsStudent: boolean;
                autoSubsTeacher: boolean;
            }> | null;
            customerId?: string | null;
            provider?: EdLinkProvider | null;
            elDistrictId?: string | null;
            elIntegrationId?: string | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type SelectSchoolMutationVariables = {
    input: SelectSchoolInput;
};

export type SelectSchoolMutation = {
    selectSchool?: {
        __typename?: "UserAndOrganization";
        user?: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        } | null;
        organization?: {
            __typename?: "Organization";
            org: string;
            schoolId: string;
            created: string;
            updated: string;
            name?: string | null;
            pictureUrl?: string | null;
            country?: string | null;
            state?: string | null;
            files?: Array<{
                __typename?: "OrganizationFile";
                url: string;
                name: string;
            }> | null;
            students: number;
            teachers: number;
            syncTimestamp?: number | null;
            isDirty?: boolean | null;
            paywall?: {
                __typename?: "OrganizationPaywallInfo";
                count: number;
                lastSent: number;
            } | null;
            aliases?: Array<string> | null;
            stage: OrganizationStage;
            referrals: Array<{
                __typename?: "OrganizationReferral";
                userId: string;
                created: string;
                sent: boolean;
                type: string;
                email: string;
            }>;
            dpa?: {
                __typename?: "OrganizationDPA";
                name?: string | null;
                email?: string | null;
                link?: string | null;
                type: DPAType;
            } | null;
            admins?: Array<{
                __typename?: "OrganizationAdmin";
                userId: string;
                schoolId: string;
            }> | null;
            pendingAdmins?: Array<{
                __typename?: "PendingOrganizationAdmin";
                Email: string;
                schoolId: string;
            }> | null;
            settings?: {
                __typename?: "OrganizationSettings";
                schoolId?: string | null;
                sharing: OrganizationSharing;
                subsAllowed: boolean;
                ai?: boolean | null;
            } | null;
            schoolsSettings?: Array<{
                __typename?: "OrganizationSettings";
                schoolId?: string | null;
                sharing: OrganizationSharing;
                subsAllowed: boolean;
                ai?: boolean | null;
            }> | null;
            verifiedSchools?: boolean | null;
            subscriptions?: Array<{
                __typename?: "OrganizationStripeConnection";
                schoolId: string;
                created: number;
                current_period_end: number;
                studentPlan: SubscriptionType;
                teacherPlan: SubscriptionType;
                studentsPaidFor: number;
                teachersPaidFor: number;
                paidStudents: number;
                paidTeachers: number;
                autoSubsStudent: boolean;
                autoSubsTeacher: boolean;
            }> | null;
            customerId?: string | null;
            provider?: EdLinkProvider | null;
            elDistrictId?: string | null;
            elIntegrationId?: string | null;
        } | null;
    } | null;
};

export type MigrateAccountContentMutationVariables = {
    input: MigrateAccountContentInput;
};

export type MigrateAccountContentMutation = {
    migrateAccountContent?: {
        __typename?: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: AccountType | null;
        ID?: string | null;
        elID?: string | null;
        clID?: string | null;
        isSSOChecked?: boolean | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        views?: number | null;
        birthday?: string | null;
        phone?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename?: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename?: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            resumes_at?: number | null;
            interval?: string | null;
            status?: string | null;
            platform?: PlatformEnum | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename?: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            frq?: number | null;
            cost?: number | null;
            mSets?: number | null;
            mNotes?: number | null;
            tools?: number | null;
            assignments?: number | null;
            scans?: number | null;
            aiV?: number | null;
            history?: Array<{
                __typename?: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename?: "ClassInfo";
            enrolled: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
        signInTypes?: Array<SignInType> | null;
        orgPlanType?: SubscriptionType | null;
        orgPlanCreated?: number | null;
        lastOrgPlanUpdated?: number | null;
        favTools?: Array<string> | null;
        lastStreakDate?: string | null;
        xpSyncDate?: string | null;
        xp?: number | null;
        level?: number | null;
        streak?: number | null;
        longPauseEnd?: string | null;
        coins?: number | null;
        featuredBadges?: Array<string> | null;
        records?: {
            __typename?: "GamifyRecords";
            xpEarned: string;
            longestStreak: string;
            badgesEarned: string;
            coinsEarned: string;
        } | null;
        inventory?: {
            __typename?: "GamifyInventory";
            freezes: number;
            revivals: number;
            pauses: number;
            icon: StoreProductEnum;
            features: Array<StoreProductEnum>;
            gamifySubExpiry?: number | null;
            gamifySubStoreProduct?: StoreProductEnum | null;
        } | null;
        gameBlock?: string | null;
        lastAdWatch?: string | null;
        lastTimeZoneChange?: number | null;
        notifSettings?: {
            __typename?: "NotificationSettings";
            emailSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            pushSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            gamifySettings?: {
                __typename?: "GamificationSettings";
                level: boolean;
                streak: boolean;
                badges: boolean;
            } | null;
        } | null;
        stoppedNotifications?: boolean | null;
        inactiveUser?: boolean | null;
        promoCodes?: Array<{
            __typename?: "PromoCode";
            code: string;
            category: string;
        }> | null;
        sentPromotions?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        orgRequests?: Array<UpgradeEvent> | null;
        orgReqTime?: string | null;
        emailsSent?: number | null;
        lastSent?: number | null;
        isSidebarExpanded?: boolean | null;
    } | null;
};

export type TrackOrganizationPaywallMutationVariables = {
    input: TrackOrganizationPaywallInput;
};

export type TrackOrganizationPaywallMutation = {
    trackOrganizationPaywall?: {
        __typename?: "Organization";
        org: string;
        schoolId: string;
        created: string;
        updated: string;
        name?: string | null;
        pictureUrl?: string | null;
        country?: string | null;
        state?: string | null;
        files?: Array<{
            __typename?: "OrganizationFile";
            url: string;
            name: string;
        }> | null;
        students: number;
        teachers: number;
        syncTimestamp?: number | null;
        isDirty?: boolean | null;
        paywall?: {
            __typename?: "OrganizationPaywallInfo";
            count: number;
            lastSent: number;
        } | null;
        aliases?: Array<string> | null;
        stage: OrganizationStage;
        referrals: Array<{
            __typename?: "OrganizationReferral";
            userId: string;
            created: string;
            sent: boolean;
            type: string;
            email: string;
        }>;
        dpa?: {
            __typename?: "OrganizationDPA";
            name?: string | null;
            email?: string | null;
            link?: string | null;
            type: DPAType;
        } | null;
        admins?: Array<{
            __typename?: "OrganizationAdmin";
            userId: string;
            schoolId: string;
        }> | null;
        pendingAdmins?: Array<{
            __typename?: "PendingOrganizationAdmin";
            Email: string;
            schoolId: string;
        }> | null;
        settings?: {
            __typename?: "OrganizationSettings";
            schoolId?: string | null;
            sharing: OrganizationSharing;
            subsAllowed: boolean;
            ai?: boolean | null;
        } | null;
        schoolsSettings?: Array<{
            __typename?: "OrganizationSettings";
            schoolId?: string | null;
            sharing: OrganizationSharing;
            subsAllowed: boolean;
            ai?: boolean | null;
        }> | null;
        verifiedSchools?: boolean | null;
        subscriptions?: Array<{
            __typename?: "OrganizationStripeConnection";
            schoolId: string;
            created: number;
            current_period_end: number;
            studentPlan: SubscriptionType;
            teacherPlan: SubscriptionType;
            studentsPaidFor: number;
            teachersPaidFor: number;
            paidStudents: number;
            paidTeachers: number;
            autoSubsStudent: boolean;
            autoSubsTeacher: boolean;
        }> | null;
        customerId?: string | null;
        provider?: EdLinkProvider | null;
        elDistrictId?: string | null;
        elIntegrationId?: string | null;
    } | null;
};

export type AuthenticateEdLinkSSOMutationVariables = {
    input: AuthenticateEdLinkSSOInput;
};

export type AuthenticateEdLinkSSOMutation = {
    authenticateEdLinkSSO?: {
        __typename?: "EdLinkAuth";
        username: string;
        password: string;
        user?: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        } | null;
        didLink?: boolean | null;
    } | null;
};

export type PurchaseStoreProductMutationVariables = {
    input: PurchaseStoreProductInput;
};

export type PurchaseStoreProductMutation = {
    purchaseStoreProduct?: {
        __typename?: "StoreProductPurchaseConnection";
        storeProductHistory: {
            __typename?: "StoreProductHistory";
            userId: string;
            storeProductId: StoreProductEnum;
            current: number;
            bought: number;
            price?: number | null;
            code?: string | null;
            discount?: number | null;
            expiryTime?: number | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
    } | null;
};

export type ClaimBadgeRewardMutationVariables = {
    input: ClaimBadgeRewardInput;
};

export type ClaimBadgeRewardMutation = {
    claimBadgeReward?: {
        __typename?: "GamifyContext";
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
        gameError?: string | null;
    } | null;
};

export type TrackActivityMutationVariables = {
    input: TrackActivityInput;
};

export type TrackActivityMutation = {
    trackActivity?: {
        __typename?: "ActivityConnection";
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        level?: number | null;
    } | null;
};

export type ApplyStreakActionMutationVariables = {
    input: ApplyStreakActionInput;
};

export type ApplyStreakActionMutation = {
    applyStreakAction?: {
        __typename?: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: AccountType | null;
        ID?: string | null;
        elID?: string | null;
        clID?: string | null;
        isSSOChecked?: boolean | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        views?: number | null;
        birthday?: string | null;
        phone?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename?: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename?: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            resumes_at?: number | null;
            interval?: string | null;
            status?: string | null;
            platform?: PlatformEnum | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename?: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            frq?: number | null;
            cost?: number | null;
            mSets?: number | null;
            mNotes?: number | null;
            tools?: number | null;
            assignments?: number | null;
            scans?: number | null;
            aiV?: number | null;
            history?: Array<{
                __typename?: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename?: "ClassInfo";
            enrolled: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
        signInTypes?: Array<SignInType> | null;
        orgPlanType?: SubscriptionType | null;
        orgPlanCreated?: number | null;
        lastOrgPlanUpdated?: number | null;
        favTools?: Array<string> | null;
        lastStreakDate?: string | null;
        xpSyncDate?: string | null;
        xp?: number | null;
        level?: number | null;
        streak?: number | null;
        longPauseEnd?: string | null;
        coins?: number | null;
        featuredBadges?: Array<string> | null;
        records?: {
            __typename?: "GamifyRecords";
            xpEarned: string;
            longestStreak: string;
            badgesEarned: string;
            coinsEarned: string;
        } | null;
        inventory?: {
            __typename?: "GamifyInventory";
            freezes: number;
            revivals: number;
            pauses: number;
            icon: StoreProductEnum;
            features: Array<StoreProductEnum>;
            gamifySubExpiry?: number | null;
            gamifySubStoreProduct?: StoreProductEnum | null;
        } | null;
        gameBlock?: string | null;
        lastAdWatch?: string | null;
        lastTimeZoneChange?: number | null;
        notifSettings?: {
            __typename?: "NotificationSettings";
            emailSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            pushSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            gamifySettings?: {
                __typename?: "GamificationSettings";
                level: boolean;
                streak: boolean;
                badges: boolean;
            } | null;
        } | null;
        stoppedNotifications?: boolean | null;
        inactiveUser?: boolean | null;
        promoCodes?: Array<{
            __typename?: "PromoCode";
            code: string;
            category: string;
        }> | null;
        sentPromotions?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        orgRequests?: Array<UpgradeEvent> | null;
        orgReqTime?: string | null;
        emailsSent?: number | null;
        lastSent?: number | null;
        isSidebarExpanded?: boolean | null;
    } | null;
};

export type ApplyStreakActionV2MutationVariables = {
    input: ApplyStreakActionInput;
};

export type ApplyStreakActionV2Mutation = {
    applyStreakActionV2?: {
        __typename?: "GamifyContext";
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
        gameError?: string | null;
    } | null;
};

export type ActivateStoreProductMutationVariables = {
    input: ActivateStoreProductInput;
};

export type ActivateStoreProductMutation = {
    activateStoreProduct?: {
        __typename?: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: AccountType | null;
        ID?: string | null;
        elID?: string | null;
        clID?: string | null;
        isSSOChecked?: boolean | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        views?: number | null;
        birthday?: string | null;
        phone?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename?: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename?: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            resumes_at?: number | null;
            interval?: string | null;
            status?: string | null;
            platform?: PlatformEnum | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename?: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            frq?: number | null;
            cost?: number | null;
            mSets?: number | null;
            mNotes?: number | null;
            tools?: number | null;
            assignments?: number | null;
            scans?: number | null;
            aiV?: number | null;
            history?: Array<{
                __typename?: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename?: "ClassInfo";
            enrolled: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
        signInTypes?: Array<SignInType> | null;
        orgPlanType?: SubscriptionType | null;
        orgPlanCreated?: number | null;
        lastOrgPlanUpdated?: number | null;
        favTools?: Array<string> | null;
        lastStreakDate?: string | null;
        xpSyncDate?: string | null;
        xp?: number | null;
        level?: number | null;
        streak?: number | null;
        longPauseEnd?: string | null;
        coins?: number | null;
        featuredBadges?: Array<string> | null;
        records?: {
            __typename?: "GamifyRecords";
            xpEarned: string;
            longestStreak: string;
            badgesEarned: string;
            coinsEarned: string;
        } | null;
        inventory?: {
            __typename?: "GamifyInventory";
            freezes: number;
            revivals: number;
            pauses: number;
            icon: StoreProductEnum;
            features: Array<StoreProductEnum>;
            gamifySubExpiry?: number | null;
            gamifySubStoreProduct?: StoreProductEnum | null;
        } | null;
        gameBlock?: string | null;
        lastAdWatch?: string | null;
        lastTimeZoneChange?: number | null;
        notifSettings?: {
            __typename?: "NotificationSettings";
            emailSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            pushSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            gamifySettings?: {
                __typename?: "GamificationSettings";
                level: boolean;
                streak: boolean;
                badges: boolean;
            } | null;
        } | null;
        stoppedNotifications?: boolean | null;
        inactiveUser?: boolean | null;
        promoCodes?: Array<{
            __typename?: "PromoCode";
            code: string;
            category: string;
        }> | null;
        sentPromotions?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        orgRequests?: Array<UpgradeEvent> | null;
        orgReqTime?: string | null;
        emailsSent?: number | null;
        lastSent?: number | null;
        isSidebarExpanded?: boolean | null;
    } | null;
};

export type WatchAdMutationVariables = {};

export type WatchAdMutation = {
    watchAd?: {
        __typename?: "GamifyContext";
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
        gameError?: string | null;
    } | null;
};

export type SimulateStreakMutationVariables = {
    input: SimulateStreakInput;
};

export type SimulateStreakMutation = {
    simulateStreak?: {
        __typename?: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: AccountType | null;
        ID?: string | null;
        elID?: string | null;
        clID?: string | null;
        isSSOChecked?: boolean | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        views?: number | null;
        birthday?: string | null;
        phone?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename?: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename?: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            resumes_at?: number | null;
            interval?: string | null;
            status?: string | null;
            platform?: PlatformEnum | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename?: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            frq?: number | null;
            cost?: number | null;
            mSets?: number | null;
            mNotes?: number | null;
            tools?: number | null;
            assignments?: number | null;
            scans?: number | null;
            aiV?: number | null;
            history?: Array<{
                __typename?: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename?: "ClassInfo";
            enrolled: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
        signInTypes?: Array<SignInType> | null;
        orgPlanType?: SubscriptionType | null;
        orgPlanCreated?: number | null;
        lastOrgPlanUpdated?: number | null;
        favTools?: Array<string> | null;
        lastStreakDate?: string | null;
        xpSyncDate?: string | null;
        xp?: number | null;
        level?: number | null;
        streak?: number | null;
        longPauseEnd?: string | null;
        coins?: number | null;
        featuredBadges?: Array<string> | null;
        records?: {
            __typename?: "GamifyRecords";
            xpEarned: string;
            longestStreak: string;
            badgesEarned: string;
            coinsEarned: string;
        } | null;
        inventory?: {
            __typename?: "GamifyInventory";
            freezes: number;
            revivals: number;
            pauses: number;
            icon: StoreProductEnum;
            features: Array<StoreProductEnum>;
            gamifySubExpiry?: number | null;
            gamifySubStoreProduct?: StoreProductEnum | null;
        } | null;
        gameBlock?: string | null;
        lastAdWatch?: string | null;
        lastTimeZoneChange?: number | null;
        notifSettings?: {
            __typename?: "NotificationSettings";
            emailSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            pushSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            gamifySettings?: {
                __typename?: "GamificationSettings";
                level: boolean;
                streak: boolean;
                badges: boolean;
            } | null;
        } | null;
        stoppedNotifications?: boolean | null;
        inactiveUser?: boolean | null;
        promoCodes?: Array<{
            __typename?: "PromoCode";
            code: string;
            category: string;
        }> | null;
        sentPromotions?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        orgRequests?: Array<UpgradeEvent> | null;
        orgReqTime?: string | null;
        emailsSent?: number | null;
        lastSent?: number | null;
        isSidebarExpanded?: boolean | null;
    } | null;
};

export type SimulateStreakActionsMutationVariables = {
    input: SimulateStreakActionsInput;
};

export type SimulateStreakActionsMutation = {
    simulateStreakActions?: {
        __typename?: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: AccountType | null;
        ID?: string | null;
        elID?: string | null;
        clID?: string | null;
        isSSOChecked?: boolean | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        views?: number | null;
        birthday?: string | null;
        phone?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename?: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename?: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            resumes_at?: number | null;
            interval?: string | null;
            status?: string | null;
            platform?: PlatformEnum | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename?: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            frq?: number | null;
            cost?: number | null;
            mSets?: number | null;
            mNotes?: number | null;
            tools?: number | null;
            assignments?: number | null;
            scans?: number | null;
            aiV?: number | null;
            history?: Array<{
                __typename?: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename?: "ClassInfo";
            enrolled: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
        signInTypes?: Array<SignInType> | null;
        orgPlanType?: SubscriptionType | null;
        orgPlanCreated?: number | null;
        lastOrgPlanUpdated?: number | null;
        favTools?: Array<string> | null;
        lastStreakDate?: string | null;
        xpSyncDate?: string | null;
        xp?: number | null;
        level?: number | null;
        streak?: number | null;
        longPauseEnd?: string | null;
        coins?: number | null;
        featuredBadges?: Array<string> | null;
        records?: {
            __typename?: "GamifyRecords";
            xpEarned: string;
            longestStreak: string;
            badgesEarned: string;
            coinsEarned: string;
        } | null;
        inventory?: {
            __typename?: "GamifyInventory";
            freezes: number;
            revivals: number;
            pauses: number;
            icon: StoreProductEnum;
            features: Array<StoreProductEnum>;
            gamifySubExpiry?: number | null;
            gamifySubStoreProduct?: StoreProductEnum | null;
        } | null;
        gameBlock?: string | null;
        lastAdWatch?: string | null;
        lastTimeZoneChange?: number | null;
        notifSettings?: {
            __typename?: "NotificationSettings";
            emailSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            pushSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            gamifySettings?: {
                __typename?: "GamificationSettings";
                level: boolean;
                streak: boolean;
                badges: boolean;
            } | null;
        } | null;
        stoppedNotifications?: boolean | null;
        inactiveUser?: boolean | null;
        promoCodes?: Array<{
            __typename?: "PromoCode";
            code: string;
            category: string;
        }> | null;
        sentPromotions?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        orgRequests?: Array<UpgradeEvent> | null;
        orgReqTime?: string | null;
        emailsSent?: number | null;
        lastSent?: number | null;
        isSidebarExpanded?: boolean | null;
    } | null;
};

export type RemoveGamifyAttributesMutationVariables = {};

export type RemoveGamifyAttributesMutation = {
    removeGamifyAttributes?: {
        __typename?: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: AccountType | null;
        ID?: string | null;
        elID?: string | null;
        clID?: string | null;
        isSSOChecked?: boolean | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        views?: number | null;
        birthday?: string | null;
        phone?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename?: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename?: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            resumes_at?: number | null;
            interval?: string | null;
            status?: string | null;
            platform?: PlatformEnum | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename?: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            frq?: number | null;
            cost?: number | null;
            mSets?: number | null;
            mNotes?: number | null;
            tools?: number | null;
            assignments?: number | null;
            scans?: number | null;
            aiV?: number | null;
            history?: Array<{
                __typename?: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename?: "ClassInfo";
            enrolled: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
        signInTypes?: Array<SignInType> | null;
        orgPlanType?: SubscriptionType | null;
        orgPlanCreated?: number | null;
        lastOrgPlanUpdated?: number | null;
        favTools?: Array<string> | null;
        lastStreakDate?: string | null;
        xpSyncDate?: string | null;
        xp?: number | null;
        level?: number | null;
        streak?: number | null;
        longPauseEnd?: string | null;
        coins?: number | null;
        featuredBadges?: Array<string> | null;
        records?: {
            __typename?: "GamifyRecords";
            xpEarned: string;
            longestStreak: string;
            badgesEarned: string;
            coinsEarned: string;
        } | null;
        inventory?: {
            __typename?: "GamifyInventory";
            freezes: number;
            revivals: number;
            pauses: number;
            icon: StoreProductEnum;
            features: Array<StoreProductEnum>;
            gamifySubExpiry?: number | null;
            gamifySubStoreProduct?: StoreProductEnum | null;
        } | null;
        gameBlock?: string | null;
        lastAdWatch?: string | null;
        lastTimeZoneChange?: number | null;
        notifSettings?: {
            __typename?: "NotificationSettings";
            emailSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            pushSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            gamifySettings?: {
                __typename?: "GamificationSettings";
                level: boolean;
                streak: boolean;
                badges: boolean;
            } | null;
        } | null;
        stoppedNotifications?: boolean | null;
        inactiveUser?: boolean | null;
        promoCodes?: Array<{
            __typename?: "PromoCode";
            code: string;
            category: string;
        }> | null;
        sentPromotions?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        orgRequests?: Array<UpgradeEvent> | null;
        orgReqTime?: string | null;
        emailsSent?: number | null;
        lastSent?: number | null;
        isSidebarExpanded?: boolean | null;
    } | null;
};

export type ProcessAsyncActionsMutationVariables = {};

export type ProcessAsyncActionsMutation = {
    processAsyncActions?: {
        __typename?: "GamifyContext";
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
        gameError?: string | null;
    } | null;
};

export type DeleteAllNotificationsMutationVariables = {};

export type DeleteAllNotificationsMutation = {
    deleteAllNotifications?: {
        __typename?: "NotificationConnection";
        items: Array<{
            __typename?: "Notification";
            userId: string;
            notificationId: string;
            read: number;
            created: string;
            color?: string | null;
            image?: string | null;
            title: string;
            message: string;
            action: string;
            type: NotificationType;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ToggleReadNotificationMutationVariables = {
    input?: ToggleReadNotificationInput | null;
};

export type ToggleReadNotificationMutation = {
    toggleReadNotification?: {
        __typename?: "Notification";
        userId: string;
        notificationId: string;
        read: number;
        created: string;
        color?: string | null;
        image?: string | null;
        title: string;
        message: string;
        action: string;
        type: NotificationType;
    } | null;
};

export type BatchToggleReadNotificationsMutationVariables = {
    input?: BatchNotificationInput | null;
};

export type BatchToggleReadNotificationsMutation = {
    batchToggleReadNotifications?: {
        __typename?: "NotificationConnection";
        items: Array<{
            __typename?: "Notification";
            userId: string;
            notificationId: string;
            read: number;
            created: string;
            color?: string | null;
            image?: string | null;
            title: string;
            message: string;
            action: string;
            type: NotificationType;
        }>;
        nextToken?: string | null;
    } | null;
};

export type CreateVerifiedSchoolMutationVariables = {
    input: VerifiedSchoolInput;
};

export type CreateVerifiedSchoolMutation = {
    createVerifiedSchool?: {
        __typename?: "VerifiedSchool";
        type: VerifiedSchoolType;
        schoolId: string;
        org?: string | null;
        verified?: boolean | null;
        name: string;
        country: string;
        state: string;
        city: string;
        updated: string;
        address?: {
            __typename?: "Address";
            streetOne: string;
            streetTwo?: string | null;
            city: string;
            county?: string | null;
            state: string;
            zip: string;
            country: string;
        } | null;
        mailing?: {
            __typename?: "Address";
            streetOne: string;
            streetTwo?: string | null;
            city: string;
            county?: string | null;
            state: string;
            zip: string;
            country: string;
        } | null;
        population?: {
            __typename?: "SchoolPopulation";
            teachers: number;
            ft: number;
            pt: number;
        } | null;
        aliases?: Array<string> | null;
        gradesLow?: string | null;
        gradesHigh?: string | null;
        website?: string | null;
        phone?: string | null;
        elSchoolId?: string | null;
    } | null;
};

export type CreateAssignmentMutationVariables = {
    input: CreateAssignmentInput;
};

export type CreateAssignmentMutation = {
    createAssignment?: {
        __typename?: "AssignmentContext";
        item: {
            __typename?: "Assignment";
            userId: string;
            assignmentId: string;
            classId?: string | null;
            addedAt?: string | null;
            config?: string | null;
            files?: Array<{
                __typename?: "AssignmentFile";
                itemId: string;
                itemType: ItemType;
            }> | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
            status: AssignmentStatus;
            deadline?: number | null;
            type: AssignmentType;
            settings: {
                __typename?: "AssignmentSettings";
                timeLimit?: number | null;
                standards?: Array<string> | null;
                grade: string;
                maxSubmissions?: number | null;
                language: Language;
                inputMethods: Array<AssignmentMethodEnum>;
                outputMethods: Array<AssignmentMethodEnum>;
                autoGrade: boolean;
                rubric: {
                    __typename?: "AssignmentRubric";
                    A?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    B?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    C?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    D?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    F?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                };
                helpfulness: HelpfulnessEnum;
                length?: AssignmentLengthEnum | null;
                priorKnowledge: Array<string>;
                rules: Array<string>;
                suggestions: Array<string>;
                initialPrompt: string;
            };
            created: string;
            updated: string;
            feedback?: {
                __typename?: "AssignmentFeedback";
                strength: string;
                improvement: string;
                followUp: string;
                comments?: string | null;
            } | null;
            title: string;
            description: string;
            instructions: string;
            image?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            public: boolean;
            password?: string | null;
            trash?: boolean | null;
            folderId?: string | null;
            subject?: string | null;
            topic?: string | null;
            numCompleted: number;
            views?: number | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type UpdateAssignmentMutationVariables = {
    input: UpdateAssignmentInput;
};

export type UpdateAssignmentMutation = {
    updateAssignment?: {
        __typename?: "AssignmentContext";
        item: {
            __typename?: "Assignment";
            userId: string;
            assignmentId: string;
            classId?: string | null;
            addedAt?: string | null;
            config?: string | null;
            files?: Array<{
                __typename?: "AssignmentFile";
                itemId: string;
                itemType: ItemType;
            }> | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
            status: AssignmentStatus;
            deadline?: number | null;
            type: AssignmentType;
            settings: {
                __typename?: "AssignmentSettings";
                timeLimit?: number | null;
                standards?: Array<string> | null;
                grade: string;
                maxSubmissions?: number | null;
                language: Language;
                inputMethods: Array<AssignmentMethodEnum>;
                outputMethods: Array<AssignmentMethodEnum>;
                autoGrade: boolean;
                rubric: {
                    __typename?: "AssignmentRubric";
                    A?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    B?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    C?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    D?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    F?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                };
                helpfulness: HelpfulnessEnum;
                length?: AssignmentLengthEnum | null;
                priorKnowledge: Array<string>;
                rules: Array<string>;
                suggestions: Array<string>;
                initialPrompt: string;
            };
            created: string;
            updated: string;
            feedback?: {
                __typename?: "AssignmentFeedback";
                strength: string;
                improvement: string;
                followUp: string;
                comments?: string | null;
            } | null;
            title: string;
            description: string;
            instructions: string;
            image?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            public: boolean;
            password?: string | null;
            trash?: boolean | null;
            folderId?: string | null;
            subject?: string | null;
            topic?: string | null;
            numCompleted: number;
            views?: number | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type DeleteAssignmentMutationVariables = {
    input: DeleteAssignmentInput;
};

export type DeleteAssignmentMutation = {
    deleteAssignment?: {
        __typename?: "Assignment";
        userId: string;
        assignmentId: string;
        classId?: string | null;
        addedAt?: string | null;
        config?: string | null;
        files?: Array<{
            __typename?: "AssignmentFile";
            itemId: string;
            itemType: ItemType;
        }> | null;
        sections?: Array<string> | null;
        partial?: boolean | null;
        status: AssignmentStatus;
        deadline?: number | null;
        type: AssignmentType;
        settings: {
            __typename?: "AssignmentSettings";
            timeLimit?: number | null;
            standards?: Array<string> | null;
            grade: string;
            maxSubmissions?: number | null;
            language: Language;
            inputMethods: Array<AssignmentMethodEnum>;
            outputMethods: Array<AssignmentMethodEnum>;
            autoGrade: boolean;
            rubric: {
                __typename?: "AssignmentRubric";
                A?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
                B?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
                C?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
                D?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
                F?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
            };
            helpfulness: HelpfulnessEnum;
            length?: AssignmentLengthEnum | null;
            priorKnowledge: Array<string>;
            rules: Array<string>;
            suggestions: Array<string>;
            initialPrompt: string;
        };
        created: string;
        updated: string;
        feedback?: {
            __typename?: "AssignmentFeedback";
            strength: string;
            improvement: string;
            followUp: string;
            comments?: string | null;
        } | null;
        title: string;
        description: string;
        instructions: string;
        image?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        public: boolean;
        password?: string | null;
        trash?: boolean | null;
        folderId?: string | null;
        subject?: string | null;
        topic?: string | null;
        numCompleted: number;
        views?: number | null;
    } | null;
};

export type CreateAssignmentSessionMutationVariables = {
    input: CreateAssignmentSessionInput;
};

export type CreateAssignmentSessionMutation = {
    createAssignmentSession?: {
        __typename?: "AssignmentSession";
        assignmentId: string;
        sessionId: string;
        userId: string;
        status: AssignmentStatus;
        created: string;
        updated: string;
        submitted?: string | null;
        duration?: number | null;
        score?: number | null;
        rubric?: Array<string> | null;
        feedback?: {
            __typename?: "AssignmentFeedback";
            strength: string;
            improvement: string;
            followUp: string;
            comments?: string | null;
        } | null;
    } | null;
};

export type UpdateAssignmentSessionMutationVariables = {
    input: UpdateAssignmentSessionInput;
};

export type UpdateAssignmentSessionMutation = {
    updateAssignmentSession?: {
        __typename?: "AssignmentSession";
        assignmentId: string;
        sessionId: string;
        userId: string;
        status: AssignmentStatus;
        created: string;
        updated: string;
        submitted?: string | null;
        duration?: number | null;
        score?: number | null;
        rubric?: Array<string> | null;
        feedback?: {
            __typename?: "AssignmentFeedback";
            strength: string;
            improvement: string;
            followUp: string;
            comments?: string | null;
        } | null;
    } | null;
};

export type CreateAssignmentFieldsMutationVariables = {
    input: CreateAssignmentFieldsInput;
};

export type CreateAssignmentFieldsMutation = {
    createAssignmentFields?: {
        __typename?: "AssignmentFields";
        title: string;
        description: string;
        instructions: string;
        subject: string;
        topic: string;
        settings: {
            __typename?: "AssignmentSettings";
            timeLimit?: number | null;
            standards?: Array<string> | null;
            grade: string;
            maxSubmissions?: number | null;
            language: Language;
            inputMethods: Array<AssignmentMethodEnum>;
            outputMethods: Array<AssignmentMethodEnum>;
            autoGrade: boolean;
            rubric: {
                __typename?: "AssignmentRubric";
                A?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
                B?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
                C?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
                D?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
                F?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
            };
            helpfulness: HelpfulnessEnum;
            length?: AssignmentLengthEnum | null;
            priorKnowledge: Array<string>;
            rules: Array<string>;
            suggestions: Array<string>;
            initialPrompt: string;
        };
    } | null;
};

export type UpdateAssignmentFieldsMutationVariables = {
    input: UpdateAssignmentFieldsInput;
};

export type UpdateAssignmentFieldsMutation = {
    updateAssignmentFields?: {
        __typename?: "AssignmentFields";
        title: string;
        description: string;
        instructions: string;
        subject: string;
        topic: string;
        settings: {
            __typename?: "AssignmentSettings";
            timeLimit?: number | null;
            standards?: Array<string> | null;
            grade: string;
            maxSubmissions?: number | null;
            language: Language;
            inputMethods: Array<AssignmentMethodEnum>;
            outputMethods: Array<AssignmentMethodEnum>;
            autoGrade: boolean;
            rubric: {
                __typename?: "AssignmentRubric";
                A?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
                B?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
                C?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
                D?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
                F?: {
                    __typename?: "AssignmentRubricGrade";
                    score?: number | null;
                    condition?: string | null;
                } | null;
            };
            helpfulness: HelpfulnessEnum;
            length?: AssignmentLengthEnum | null;
            priorKnowledge: Array<string>;
            rules: Array<string>;
            suggestions: Array<string>;
            initialPrompt: string;
        };
    } | null;
};

export type _unusedSchoolApiMutationVariables = {
    input?: UnusedInputs | null;
};

export type _unusedSchoolApiMutation = {
    _unusedSchoolApi?: {
        __typename?: "UnusedTypes";
        verifiedSchool?: {
            __typename?: "VerifiedSchool";
            type: VerifiedSchoolType;
            schoolId: string;
            org?: string | null;
            verified?: boolean | null;
            name: string;
            country: string;
            state: string;
            city: string;
            updated: string;
            address?: {
                __typename?: "Address";
                streetOne: string;
                streetTwo?: string | null;
                city: string;
                county?: string | null;
                state: string;
                zip: string;
                country: string;
            } | null;
            mailing?: {
                __typename?: "Address";
                streetOne: string;
                streetTwo?: string | null;
                city: string;
                county?: string | null;
                state: string;
                zip: string;
                country: string;
            } | null;
            population?: {
                __typename?: "SchoolPopulation";
                teachers: number;
                ft: number;
                pt: number;
            } | null;
            aliases?: Array<string> | null;
            gradesLow?: string | null;
            gradesHigh?: string | null;
            website?: string | null;
            phone?: string | null;
            elSchoolId?: string | null;
        } | null;
        classPermission?: ClassPermission | null;
        emailType?: EmailType | null;
        pushTemplate?: {
            __typename?: "PushTemplate";
            color?: string | null;
            image?: string | null;
            title: string;
            message: string;
            action: string;
        } | null;
        emailTemplate?: {
            __typename?: "EmailTemplate";
            subject: string;
            body: string;
            type?: EmailType | null;
        } | null;
        promoNotificationCategory?: PromoNotificationCategory | null;
        scheduledEvent?: {
            __typename?: "ScheduledEvent";
            eventTime: number;
            eventId: string;
            userId: string;
            email: string;
            type: NotificationType;
            stage?: number | null;
            itemId?: string | null;
            itemType?: ItemType | null;
            pushTemplate?: {
                __typename?: "PushTemplate";
                color?: string | null;
                image?: string | null;
                title: string;
                message: string;
                action: string;
            } | null;
            emailTemplate?: {
                __typename?: "EmailTemplate";
                subject: string;
                body: string;
                type?: EmailType | null;
            } | null;
            repeatTime?: number | null;
            params?: string | null;
        } | null;
        view?: {
            __typename?: "View";
            ID: string;
            type?: ItemType | null;
            time?: number | null;
            count?: number | null;
        } | null;
        assignmentFeedbackWithScore?: {
            __typename?: "AssignmentFeedbackWithScore";
            score?: number | null;
            feedback: {
                __typename?: "AssignmentFeedback";
                strength: string;
                improvement: string;
                followUp: string;
                comments?: string | null;
            };
        } | null;
        flaggedMessage?: {
            __typename?: "FlaggedMessage";
            org: string;
            userId: string;
            sessionId: string;
            messageId: string;
            created: string;
            content: string;
            contentFlag?: ContentFlagEnum | null;
            sender: Sender;
        } | null;
        scanHistory?: {
            __typename?: "ScanHistory";
            userId: string;
            scanId: string;
            created: string;
            updated: string;
            subject?: string | null;
            topic?: string | null;
            context?: string | null;
            imageDescription?: string | null;
            relatedTopics?: Array<string | null> | null;
            questions: Array<{
                __typename?: "ScanHistoryQuestion";
                text: string;
                type: QuestionTypeEnum;
                choices: Array<string>;
                answer: {
                    __typename?: "ScanHistoryAnswer";
                    text: string;
                    steps: Array<string>;
                };
            }>;
            pinned?: boolean | null;
            summary?: string | null;
            videoResult?: string | null;
        } | null;
    } | null;
};

export type UpdateOrganizationMutationVariables = {
    input: UpdateOrganizationInput;
};

export type UpdateOrganizationMutation = {
    updateOrganization?: {
        __typename?: "Organization";
        org: string;
        schoolId: string;
        created: string;
        updated: string;
        name?: string | null;
        pictureUrl?: string | null;
        country?: string | null;
        state?: string | null;
        files?: Array<{
            __typename?: "OrganizationFile";
            url: string;
            name: string;
        }> | null;
        students: number;
        teachers: number;
        syncTimestamp?: number | null;
        isDirty?: boolean | null;
        paywall?: {
            __typename?: "OrganizationPaywallInfo";
            count: number;
            lastSent: number;
        } | null;
        aliases?: Array<string> | null;
        stage: OrganizationStage;
        referrals: Array<{
            __typename?: "OrganizationReferral";
            userId: string;
            created: string;
            sent: boolean;
            type: string;
            email: string;
        }>;
        dpa?: {
            __typename?: "OrganizationDPA";
            name?: string | null;
            email?: string | null;
            link?: string | null;
            type: DPAType;
        } | null;
        admins?: Array<{
            __typename?: "OrganizationAdmin";
            userId: string;
            schoolId: string;
        }> | null;
        pendingAdmins?: Array<{
            __typename?: "PendingOrganizationAdmin";
            Email: string;
            schoolId: string;
        }> | null;
        settings?: {
            __typename?: "OrganizationSettings";
            schoolId?: string | null;
            sharing: OrganizationSharing;
            subsAllowed: boolean;
            ai?: boolean | null;
        } | null;
        schoolsSettings?: Array<{
            __typename?: "OrganizationSettings";
            schoolId?: string | null;
            sharing: OrganizationSharing;
            subsAllowed: boolean;
            ai?: boolean | null;
        }> | null;
        verifiedSchools?: boolean | null;
        subscriptions?: Array<{
            __typename?: "OrganizationStripeConnection";
            schoolId: string;
            created: number;
            current_period_end: number;
            studentPlan: SubscriptionType;
            teacherPlan: SubscriptionType;
            studentsPaidFor: number;
            teachersPaidFor: number;
            paidStudents: number;
            paidTeachers: number;
            autoSubsStudent: boolean;
            autoSubsTeacher: boolean;
        }> | null;
        customerId?: string | null;
        provider?: EdLinkProvider | null;
        elDistrictId?: string | null;
        elIntegrationId?: string | null;
    } | null;
};

export type UpdateOrgMembersMutationVariables = {
    input: UpdateOrgMembersInput;
};

export type UpdateOrgMembersMutation = {
    updateOrgMembers?: {
        __typename?: "UserDetailsConnection";
        items: Array<{
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type UpdateScanHistoryMutationVariables = {
    input: UpdateScanHistoryInput;
};

export type UpdateScanHistoryMutation = {
    updateScanHistory: {
        __typename?: "ScanHistory";
        userId: string;
        scanId: string;
        created: string;
        updated: string;
        subject?: string | null;
        topic?: string | null;
        context?: string | null;
        imageDescription?: string | null;
        relatedTopics?: Array<string | null> | null;
        questions: Array<{
            __typename?: "ScanHistoryQuestion";
            text: string;
            type: QuestionTypeEnum;
            choices: Array<string>;
            answer: {
                __typename?: "ScanHistoryAnswer";
                text: string;
                steps: Array<string>;
            };
        }>;
        pinned?: boolean | null;
        summary?: string | null;
        videoResult?: string | null;
    };
};

export type DeleteScanHistoryMutationVariables = {
    input: DeleteScanHistoryInput;
};

export type DeleteScanHistoryMutation = {
    deleteScanHistory: Array<string>;
};

export type AnswerTutorialGeneratorMutationVariables = {
    input: AnswerTutorialGeneratorInput;
};

export type AnswerTutorialGeneratorMutation = {
    answerTutorialGenerator?: string | null;
};

export type SubmitExamSessionMutationVariables = {
    input: SubmitExamSessionInput;
};

export type SubmitExamSessionMutation = {
    submitExamSession?: {
        __typename?: "ExamHistory";
        userId: string;
        historyId: string;
        exam: string;
        type: ExamTypeEnum;
        sections: Array<{
            __typename?: "ExamHistorySection";
            name: string;
            time: number;
            sectionPoints: number;
            sectionTotal: number;
            breakdown: Array<{
                __typename?: "ExamHistorySectionBreakdown";
                name: string;
                type: ExamTypeEnum;
                points: number;
                total: number;
                mcqResult?: {
                    __typename?: "ExamHistoryMCQResult";
                    items?: Array<{
                        __typename?: "ExamHistoryMCQResultItems";
                        questionId: string;
                        selectedAnswer?: number | null;
                        isCorrect?: boolean | null;
                    }> | null;
                } | null;
                frqResult?: {
                    __typename?: "ExamHistoryFRQResult";
                    items?: Array<{
                        __typename?: "ExamHistoryFRQResultItems";
                        questionId: string;
                        partAnswers?: Array<{
                            __typename?: "ExamHistoryFRQPartAnswers";
                            answer: string;
                            earnedScore?: number | null;
                            possibleScore?: number | null;
                            feedback?: string | null;
                            attachments?: Array<{
                                __typename?: "Attachment";
                                url: string;
                                parsed?: string | null;
                                type: MediaType;
                            }> | null;
                        }> | null;
                    }> | null;
                } | null;
            }>;
        }>;
        mcqMetrics?: Array<string> | null;
        frqMetrics?: Array<string> | null;
        score: number;
        created: string;
        updated: string;
    } | null;
};

export type DeleteExamHistoryMutationVariables = {
    input: DeleteExamHistoryInput;
};

export type DeleteExamHistoryMutation = {
    deleteExamHistory?: {
        __typename?: "ExamHistory";
        userId: string;
        historyId: string;
        exam: string;
        type: ExamTypeEnum;
        sections: Array<{
            __typename?: "ExamHistorySection";
            name: string;
            time: number;
            sectionPoints: number;
            sectionTotal: number;
            breakdown: Array<{
                __typename?: "ExamHistorySectionBreakdown";
                name: string;
                type: ExamTypeEnum;
                points: number;
                total: number;
                mcqResult?: {
                    __typename?: "ExamHistoryMCQResult";
                    items?: Array<{
                        __typename?: "ExamHistoryMCQResultItems";
                        questionId: string;
                        selectedAnswer?: number | null;
                        isCorrect?: boolean | null;
                    }> | null;
                } | null;
                frqResult?: {
                    __typename?: "ExamHistoryFRQResult";
                    items?: Array<{
                        __typename?: "ExamHistoryFRQResultItems";
                        questionId: string;
                        partAnswers?: Array<{
                            __typename?: "ExamHistoryFRQPartAnswers";
                            answer: string;
                            earnedScore?: number | null;
                            possibleScore?: number | null;
                            feedback?: string | null;
                            attachments?: Array<{
                                __typename?: "Attachment";
                                url: string;
                                parsed?: string | null;
                                type: MediaType;
                            }> | null;
                        }> | null;
                    }> | null;
                } | null;
            }>;
        }>;
        mcqMetrics?: Array<string> | null;
        frqMetrics?: Array<string> | null;
        score: number;
        created: string;
        updated: string;
    } | null;
};

export type CreateMockExamSessionMutationVariables = {
    input: CreateMockExamSessionInput;
};

export type CreateMockExamSessionMutation = {
    createMockExamSession?: {
        __typename?: "MockExamSession";
        max?: number | null;
        scores?: Array<number | null> | null;
        sections?: Array<{
            __typename?: "MockExamSessionSection";
            name: string;
            time?: number | null;
            sectionPoints?: number | null;
            sectionTotal?: number | null;
            breakdown?: Array<{
                __typename?: "MockExamSessionBreakdown";
                name: string;
                type?: ExamTypeEnum | null;
                points?: number | null;
                total?: number | null;
                frqType?: ExamFRQType | null;
                mcqQuestions?: Array<{
                    __typename?: "ExamMCQGroup";
                    questions: Array<{
                        __typename?: "ExamMCQ";
                        exam: string;
                        questionId: string;
                        difficulty: ExamDifficulty;
                        choices: Array<string>;
                        question: string;
                        reasons: Array<string>;
                        rating: number;
                        ratingCount: number;
                        examUnit: string;
                        examSection?: string | null;
                        noteId?: string | null;
                        flashcardSetId?: string | null;
                        subject?: string | null;
                        topic?: string | null;
                        created: string;
                        updated: string;
                        url?: string | null;
                        prompt: ExamPromptType;
                        numCorrect: number;
                        numQuestions: number;
                        stimuli?: Array<string | null> | null;
                        stimulusGroupId?: string | null;
                        questionGroupId?: string | null;
                        replaced?: boolean | null;
                        calc?: number | null;
                    }>;
                    stimuli: Array<{
                        __typename?: "ExamStimulus";
                        questionId: string;
                        stimulusId: string;
                        content: string;
                        stimulusType: ExamStimulusType;
                        schema?: string | null;
                    }>;
                } | null> | null;
                frqQuestions?: Array<{
                    __typename?: "FullExamFRQ";
                    exam: string;
                    questionId: string;
                    difficulty: ExamDifficulty;
                    question: string;
                    rating: number;
                    ratingCount: number;
                    examUnit: string;
                    examSection?: string | null;
                    noteId?: string | null;
                    flashcardSetId?: string | null;
                    subject?: string | null;
                    topic?: string | null;
                    created: string;
                    updated: string;
                    url?: string | null;
                    prompt: ExamPromptType;
                    stimuli?: Array<{
                        __typename?: "ExamStimulus";
                        questionId: string;
                        stimulusId: string;
                        content: string;
                        stimulusType: ExamStimulusType;
                        schema?: string | null;
                    }> | null;
                    name: string;
                    parts: Array<{
                        __typename?: "FullExamFRQPart";
                        question: string;
                        rating: number;
                        ratingCount: number;
                        stimuli?: Array<{
                            __typename?: "ExamStimulus";
                            questionId: string;
                            stimulusId: string;
                            content: string;
                            stimulusType: ExamStimulusType;
                            schema?: string | null;
                        }> | null;
                        prefill?: string | null;
                    }>;
                    rubric: Array<{
                        __typename?: "ExamFRQRubricParts";
                        items: Array<{
                            __typename?: "ExamFRQRubricItems";
                            guidelines: string;
                            points: number;
                        }>;
                    }>;
                    starred: boolean;
                    calc?: number | null;
                    frqType?: ExamFRQType | null;
                } | null> | null;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type ListNotificationsByUserQueryVariables = {
    input: ListNotificationsByUserInput;
};

export type ListNotificationsByUserQuery = {
    listNotificationsByUser?: {
        __typename?: "NotificationConnection";
        items: Array<{
            __typename?: "Notification";
            userId: string;
            notificationId: string;
            read: number;
            created: string;
            color?: string | null;
            image?: string | null;
            title: string;
            message: string;
            action: string;
            type: NotificationType;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetNoteQueryVariables = {
    input: GetNoteInput;
};

export type GetNoteQuery = {
    getNote?: {
        __typename?: "Note";
        userId: string;
        noteId: string;
        content?: string | null;
        hash?: string | null;
        public?: boolean | null;
        draft?: boolean | null;
        password?: string | null;
        summary?: string | null;
        title?: string | null;
        trash?: boolean | null;
        created?: string | null;
        updated?: string | null;
        noteCode?: string | null;
        file?: string | null;
        folderId?: string | null;
        flashcardSetId?: string | null;
        mediaId?: string | null;
        importType?: ImportType | null;
        subject?: string | null;
        views?: number | null;
        verified?: boolean | null;
        classPublic?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        lock?: boolean | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        isAI?: boolean | null;
        aiTool?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        neverPublic?: boolean | null;
        deadline?: number | null;
    } | null;
};

export type GetNoteV2QueryVariables = {
    input: GetNoteInput;
};

export type GetNoteV2Query = {
    getNoteV2?: {
        __typename?: "NoteNullableContext";
        item: {
            __typename?: "Note";
            userId: string;
            noteId: string;
            content?: string | null;
            hash?: string | null;
            public?: boolean | null;
            draft?: boolean | null;
            password?: string | null;
            summary?: string | null;
            title?: string | null;
            trash?: boolean | null;
            created?: string | null;
            updated?: string | null;
            noteCode?: string | null;
            file?: string | null;
            folderId?: string | null;
            flashcardSetId?: string | null;
            mediaId?: string | null;
            importType?: ImportType | null;
            subject?: string | null;
            views?: number | null;
            verified?: boolean | null;
            classPublic?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            lock?: boolean | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            isAI?: boolean | null;
            aiTool?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            neverPublic?: boolean | null;
            deadline?: number | null;
        };
        user?: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        } | null;
        badges?: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }> | null;
        dailyActivity?: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        } | null;
        level?: number | null;
    } | null;
};

export type GetMediaQueryVariables = {
    input: GetMediaInput;
};

export type GetMediaQuery = {
    getMedia?: {
        __typename?: "Media";
        userId: string;
        mediaId: string;
        created: string;
        updated: string;
        title?: string | null;
        description?: string | null;
        password?: string | null;
        public: boolean;
        noteId?: string | null;
        flashcardSetId?: string | null;
        folderId?: string | null;
        trash?: boolean | null;
        type: MediaType;
        fileType: FileType;
        pages?: number | null;
        length?: number | null;
        bucket: string;
        embedUrl?: string | null;
        embedCommands?: Array<string | null> | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        rating?: number | null;
        ratingCount?: number | null;
        verified?: boolean | null;
        transcript?: TranscriptEnum | null;
        schoolId?: string | null;
        grade?: string | null;
        views?: number | null;
        jwt?: string | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        isAI?: boolean | null;
    } | null;
};

export type GetMediaV2QueryVariables = {
    input: GetMediaInput;
};

export type GetMediaV2Query = {
    getMediaV2?: {
        __typename?: "MediaNullableContext";
        item: {
            __typename?: "Media";
            userId: string;
            mediaId: string;
            created: string;
            updated: string;
            title?: string | null;
            description?: string | null;
            password?: string | null;
            public: boolean;
            noteId?: string | null;
            flashcardSetId?: string | null;
            folderId?: string | null;
            trash?: boolean | null;
            type: MediaType;
            fileType: FileType;
            pages?: number | null;
            length?: number | null;
            bucket: string;
            embedUrl?: string | null;
            embedCommands?: Array<string | null> | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            verified?: boolean | null;
            transcript?: TranscriptEnum | null;
            schoolId?: string | null;
            grade?: string | null;
            views?: number | null;
            jwt?: string | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            isAI?: boolean | null;
        };
        user?: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        } | null;
        badges?: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }> | null;
        dailyActivity?: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        } | null;
        level?: number | null;
    } | null;
};

export type ListMediaQueryVariables = {
    input: ListMediaInput;
};

export type ListMediaQuery = {
    listMedia?: {
        __typename?: "MediaConnection";
        items: Array<{
            __typename?: "Media";
            userId: string;
            mediaId: string;
            created: string;
            updated: string;
            title?: string | null;
            description?: string | null;
            password?: string | null;
            public: boolean;
            noteId?: string | null;
            flashcardSetId?: string | null;
            folderId?: string | null;
            trash?: boolean | null;
            type: MediaType;
            fileType: FileType;
            pages?: number | null;
            length?: number | null;
            bucket: string;
            embedUrl?: string | null;
            embedCommands?: Array<string | null> | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            verified?: boolean | null;
            transcript?: TranscriptEnum | null;
            schoolId?: string | null;
            grade?: string | null;
            views?: number | null;
            jwt?: string | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            isAI?: boolean | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListNotesQueryVariables = {
    input: ListNoteInput;
};

export type ListNotesQuery = {
    listNotes?: {
        __typename?: "ModelNoteConnection";
        items: Array<{
            __typename?: "Note";
            userId: string;
            noteId: string;
            content?: string | null;
            hash?: string | null;
            public?: boolean | null;
            draft?: boolean | null;
            password?: string | null;
            summary?: string | null;
            title?: string | null;
            trash?: boolean | null;
            created?: string | null;
            updated?: string | null;
            noteCode?: string | null;
            file?: string | null;
            folderId?: string | null;
            flashcardSetId?: string | null;
            mediaId?: string | null;
            importType?: ImportType | null;
            subject?: string | null;
            views?: number | null;
            verified?: boolean | null;
            classPublic?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            lock?: boolean | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            isAI?: boolean | null;
            aiTool?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            neverPublic?: boolean | null;
            deadline?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetFlashcardSetQueryVariables = {
    input: GetFlashcardSetInput;
};

export type GetFlashcardSetQuery = {
    getFlashcardSet?: {
        __typename?: "FlashcardSet";
        userId: string;
        flashcardSetId: string;
        sort?: number | null;
        noteId?: string | null;
        mediaId?: string | null;
        position?: number | null;
        title?: string | null;
        description?: string | null;
        public?: boolean | null;
        password?: string | null;
        trash?: boolean | null;
        draft?: boolean | null;
        starred?: Array<string | null> | null;
        size?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        created?: number | null;
        updated?: number | null;
        quizletUrl?: string | null;
        answerSide?: FlashcardSide | null;
        shuffled?: boolean | null;
        flashcards?: Array<{
            __typename?: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
            termRecordedAudio?: string | null;
            definitionRecordedAudio?: string | null;
            mcOptions?: Array<string | null> | null;
            termCustomHint?: string | null;
            definitionCustomHint?: string | null;
        } | null> | null;
        termLanguage?: Language | null;
        definitionLanguage?: Language | null;
        relatedNotes?: Array<string | null> | null;
        order?: Array<number | null> | null;
        flashcardSetCode?: string | null;
        views?: number | null;
        classPublic?: boolean | null;
        subject?: string | null;
        verified?: boolean | null;
        schoolId?: string | null;
        grade?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        tags?: Array<string | null> | null;
        topic?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        folderId?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        lock?: boolean | null;
        partial?: boolean | null;
        xpTracked?: string | null;
        studySettings?: {
            __typename?: "FlashcardStudySettings";
            blockedStudyModes: Array<StudySessionType>;
            settings: {
                __typename?: "FlashcardsSetLockedStudySettings";
                LEARN?: {
                    __typename?: "LearnLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchLockedSettings";
                    locked: Array<string>;
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    position?: number | null;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                } | null;
            };
        } | null;
        isAI?: boolean | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        deadline?: number | null;
    } | null;
};

export type ListFlashcardSetByUserQueryVariables = {
    input: ListFlashcardSetByUserInput;
};

export type ListFlashcardSetByUserQuery = {
    listFlashcardSetByUser?: {
        __typename?: "FlashcardSetConnection";
        items: Array<{
            __typename?: "FlashcardSet";
            userId: string;
            flashcardSetId: string;
            sort?: number | null;
            noteId?: string | null;
            mediaId?: string | null;
            position?: number | null;
            title?: string | null;
            description?: string | null;
            public?: boolean | null;
            password?: string | null;
            trash?: boolean | null;
            draft?: boolean | null;
            starred?: Array<string | null> | null;
            size?: number | null;
            studyFrom?: FlashcardStudyFrom | null;
            created?: number | null;
            updated?: number | null;
            quizletUrl?: string | null;
            answerSide?: FlashcardSide | null;
            shuffled?: boolean | null;
            flashcards?: Array<{
                __typename?: "Flashcard";
                flashcardId: string;
                flashcardSetId?: string | null;
                userId?: string | null;
                term?: string | null;
                termAudio?: string | null;
                definition?: string | null;
                definitionAudio?: string | null;
                distractorIds?: Array<string | null> | null;
                questionType?: QuestionType | null;
                isCorrect?: boolean | null;
                draft?: boolean | null;
                trash?: boolean | null;
                disabled?: boolean | null;
                image?: string | null;
                secondaryImage?: string | null;
                edited?: boolean | null;
                created?: string | null;
                updated?: string | null;
                twoSided?: boolean | null;
                schedule?: string | null;
                quality?: number | null;
                termRecordedAudio?: string | null;
                definitionRecordedAudio?: string | null;
                mcOptions?: Array<string | null> | null;
                termCustomHint?: string | null;
                definitionCustomHint?: string | null;
            } | null> | null;
            termLanguage?: Language | null;
            definitionLanguage?: Language | null;
            relatedNotes?: Array<string | null> | null;
            order?: Array<number | null> | null;
            flashcardSetCode?: string | null;
            views?: number | null;
            classPublic?: boolean | null;
            subject?: string | null;
            verified?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            folderId?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            lock?: boolean | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            studySettings?: {
                __typename?: "FlashcardStudySettings";
                blockedStudyModes: Array<StudySessionType>;
                settings: {
                    __typename?: "FlashcardsSetLockedStudySettings";
                    LEARN?: {
                        __typename?: "LearnLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                        length?: number | null;
                        override?: boolean | null;
                    } | null;
                    TEST?: {
                        __typename?: "TestLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        length: number;
                    } | null;
                    SPACED?: {
                        __typename?: "SpacedLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        cardsPerSession: number;
                        intervals?: Array<number> | null;
                    } | null;
                    MATCH?: {
                        __typename?: "MatchLockedSettings";
                        locked: Array<string>;
                        grid: boolean;
                        starred: boolean;
                        length: number;
                    } | null;
                    REVIEW?: {
                        __typename?: "ReviewLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        position?: number | null;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                    } | null;
                };
            } | null;
            isAI?: boolean | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            deadline?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFlashcardSetByNoteQueryVariables = {
    input: ListFlashcardSetByNoteInput;
};

export type ListFlashcardSetByNoteQuery = {
    listFlashcardSetByNote?: {
        __typename?: "FlashcardSetConnection";
        items: Array<{
            __typename?: "FlashcardSet";
            userId: string;
            flashcardSetId: string;
            sort?: number | null;
            noteId?: string | null;
            mediaId?: string | null;
            position?: number | null;
            title?: string | null;
            description?: string | null;
            public?: boolean | null;
            password?: string | null;
            trash?: boolean | null;
            draft?: boolean | null;
            starred?: Array<string | null> | null;
            size?: number | null;
            studyFrom?: FlashcardStudyFrom | null;
            created?: number | null;
            updated?: number | null;
            quizletUrl?: string | null;
            answerSide?: FlashcardSide | null;
            shuffled?: boolean | null;
            flashcards?: Array<{
                __typename?: "Flashcard";
                flashcardId: string;
                flashcardSetId?: string | null;
                userId?: string | null;
                term?: string | null;
                termAudio?: string | null;
                definition?: string | null;
                definitionAudio?: string | null;
                distractorIds?: Array<string | null> | null;
                questionType?: QuestionType | null;
                isCorrect?: boolean | null;
                draft?: boolean | null;
                trash?: boolean | null;
                disabled?: boolean | null;
                image?: string | null;
                secondaryImage?: string | null;
                edited?: boolean | null;
                created?: string | null;
                updated?: string | null;
                twoSided?: boolean | null;
                schedule?: string | null;
                quality?: number | null;
                termRecordedAudio?: string | null;
                definitionRecordedAudio?: string | null;
                mcOptions?: Array<string | null> | null;
                termCustomHint?: string | null;
                definitionCustomHint?: string | null;
            } | null> | null;
            termLanguage?: Language | null;
            definitionLanguage?: Language | null;
            relatedNotes?: Array<string | null> | null;
            order?: Array<number | null> | null;
            flashcardSetCode?: string | null;
            views?: number | null;
            classPublic?: boolean | null;
            subject?: string | null;
            verified?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            folderId?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            lock?: boolean | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            studySettings?: {
                __typename?: "FlashcardStudySettings";
                blockedStudyModes: Array<StudySessionType>;
                settings: {
                    __typename?: "FlashcardsSetLockedStudySettings";
                    LEARN?: {
                        __typename?: "LearnLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                        length?: number | null;
                        override?: boolean | null;
                    } | null;
                    TEST?: {
                        __typename?: "TestLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        length: number;
                    } | null;
                    SPACED?: {
                        __typename?: "SpacedLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        cardsPerSession: number;
                        intervals?: Array<number> | null;
                    } | null;
                    MATCH?: {
                        __typename?: "MatchLockedSettings";
                        locked: Array<string>;
                        grid: boolean;
                        starred: boolean;
                        length: number;
                    } | null;
                    REVIEW?: {
                        __typename?: "ReviewLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        position?: number | null;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                    } | null;
                };
            } | null;
            isAI?: boolean | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            deadline?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFlashcardsByFlashcardSetQueryVariables = {
    input: ListFlashcardsByFlashcardSetInput;
};

export type ListFlashcardsByFlashcardSetQuery = {
    listFlashcardsByFlashcardSet?: {
        __typename?: "FlashcardConnection";
        items: Array<{
            __typename?: "Flashcard";
            flashcardId: string;
            flashcardSetId?: string | null;
            userId?: string | null;
            term?: string | null;
            termAudio?: string | null;
            definition?: string | null;
            definitionAudio?: string | null;
            distractorIds?: Array<string | null> | null;
            questionType?: QuestionType | null;
            isCorrect?: boolean | null;
            draft?: boolean | null;
            trash?: boolean | null;
            disabled?: boolean | null;
            image?: string | null;
            secondaryImage?: string | null;
            edited?: boolean | null;
            created?: string | null;
            updated?: string | null;
            twoSided?: boolean | null;
            schedule?: string | null;
            quality?: number | null;
            termRecordedAudio?: string | null;
            definitionRecordedAudio?: string | null;
            mcOptions?: Array<string | null> | null;
            termCustomHint?: string | null;
            definitionCustomHint?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFollowingQueryVariables = {
    input: ListFollowingInput;
};

export type ListFollowingQuery = {
    listFollowing?: {
        __typename?: "UserConnection";
        items: Array<{
            __typename?: "User";
            userId: string;
            pictureUrl?: string | null;
            Name?: string | null;
            accountType?: AccountType | null;
            username?: string | null;
            Email?: string | null;
            level?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFollowersQueryVariables = {
    input: ListFollowersInput;
};

export type ListFollowersQuery = {
    listFollowers?: {
        __typename?: "UserConnection";
        items: Array<{
            __typename?: "User";
            userId: string;
            pictureUrl?: string | null;
            Name?: string | null;
            accountType?: AccountType | null;
            username?: string | null;
            Email?: string | null;
            level?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListExamFRQsQueryVariables = {
    input: ListExamFRQsInput;
};

export type ListExamFRQsQuery = {
    listExamFRQs?: {
        __typename?: "ExamFRQConnection";
        items: Array<{
            __typename?: "ExamFRQ";
            exam: string;
            questionId: string;
            difficulty: ExamDifficulty;
            question: string;
            rating: number;
            ratingCount: number;
            examUnit: string;
            examSection?: string | null;
            noteId?: string | null;
            flashcardSetId?: string | null;
            subject?: string | null;
            topic?: string | null;
            created: string;
            updated: string;
            url?: string | null;
            prompt: ExamPromptType;
            stimuli?: Array<string> | null;
            name: string;
            parts: Array<{
                __typename?: "ExamFRQPart";
                question: string;
                rating: number;
                ratingCount: number;
                stimuli?: Array<string> | null;
                prefill?: string | null;
            }>;
            rubric: Array<{
                __typename?: "ExamFRQRubricParts";
                items: Array<{
                    __typename?: "ExamFRQRubricItems";
                    guidelines: string;
                    points: number;
                }>;
            }>;
            calc?: number | null;
            starred: boolean;
            frqType?: ExamFRQType | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListSubjectsQueryVariables = {
    input?: ListSubjectsInput | null;
};

export type ListSubjectsQuery = {
    listSubjects?: {
        __typename?: "SubjectConnection";
        items: Array<{
            __typename?: "Subject";
            subject?: string | null;
            picture?: string | null;
            color?: string | null;
            category?: string | null;
            order?: number | null;
            topics?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListBannersQueryVariables = {};

export type ListBannersQuery = {
    listBanners?: {
        __typename?: "BannerConnection";
        items: Array<{
            __typename?: "Banner";
            bannerId: string;
            type: BannerType;
            message: string;
            startTime: number;
            endTime: number;
            href?: string | null;
            promoCode?: string | null;
        }>;
    } | null;
};

export type ListExamsQueryVariables = {
    input?: ListExamsInput | null;
};

export type ListExamsQuery = {
    listExams?: {
        __typename?: "ExamConnection";
        items: Array<{
            __typename?: "Exam";
            type: string;
            name: string;
            description?: string | null;
            unitLabel: string;
            sectionLabel: string;
            baseLink: string;
            image: string;
            category?: string | null;
            color?: string | null;
            date?: string | null;
            time?: number | null;
            index?: boolean | null;
            noteId?: string | null;
            flashcardSetId?: string | null;
            discord?: Array<{
                __typename?: "ExamResourceType";
                name: string;
                link?: string | null;
                image?: string | null;
            } | null> | null;
            youtube?: Array<{
                __typename?: "ExamResourceType";
                name: string;
                link?: string | null;
                image?: string | null;
            } | null> | null;
            units?: Array<{
                __typename?: "ExamUnit";
                name: string;
                image?: string | null;
                noteId?: string | null;
                flashcardSetId?: string | null;
                sections?: Array<string | null> | null;
            } | null> | null;
            crams?: Array<{
                __typename?: "ExamUnit";
                name: string;
                image?: string | null;
                noteId?: string | null;
                flashcardSetId?: string | null;
                sections?: Array<string | null> | null;
            } | null> | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type IsFollowingQueryVariables = {
    input: FollowInput;
};

export type IsFollowingQuery = {
    isFollowing?: boolean | null;
};

export type GetCurrentUserQueryVariables = {
    input?: GetCurrentUserInput | null;
};

export type GetCurrentUserQuery = {
    getCurrentUser: {
        __typename?: "UserDetails";
        Email: string;
        org?: string | null;
        accountType?: AccountType | null;
        ID?: string | null;
        elID?: string | null;
        clID?: string | null;
        isSSOChecked?: boolean | null;
        timeZone?: string | null;
        referral?: string | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        views?: number | null;
        birthday?: string | null;
        phone?: string | null;
        hideSensitiveInfo?: boolean | null;
        noteSidebarWidth?: number | null;
        created?: number | null;
        lastLogIn?: number | null;
        lastNotified?: number | null;
        answerSide?: FlashcardSide | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        ref_v2?: string | null;
        refFrom_v2?: string | null;
        refList_v2?: Array<string> | null;
        verified?: boolean | null;
        bio?: string | null;
        socials?: {
            __typename?: "Socials";
            ig?: string | null;
            twitter?: string | null;
            tiktok?: string | null;
            in?: string | null;
            link?: string | null;
            spotify?: string | null;
        } | null;
        cover?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        rating?: number | null;
        ratingCount?: number | null;
        customerId?: string | null;
        subscriptions?: Array<{
            __typename?: "StripeConnection";
            subscriptionId: string;
            customerId: string;
            priceId: string;
            productId: string;
            created?: number | null;
            current_period_end?: number | null;
            cancel_at_period_end?: boolean | null;
            resumes_at?: number | null;
            interval?: string | null;
            status?: string | null;
            platform?: PlatformEnum | null;
        }> | null;
        alerts?: string | null;
        subscriptionType?: SubscriptionType | null;
        stripeVerifyTime?: number | null;
        stripeTrialTime?: number | null;
        cancelPromoTime?: number | null;
        deleteReq?: boolean | null;
        ai?: {
            __typename?: "UserAIStats";
            curPrompts?: number | null;
            curPeriodEnd?: number | null;
            curTokens?: number | null;
            videos?: number | null;
            vNotes?: number | null;
            vSets?: number | null;
            mins?: number | null;
            pdfs?: number | null;
            pNotes?: number | null;
            pSets?: number | null;
            pages?: number | null;
            nTests?: number | null;
            nSets?: number | null;
            chats?: number | null;
            explain?: number | null;
            frq?: number | null;
            cost?: number | null;
            mSets?: number | null;
            mNotes?: number | null;
            tools?: number | null;
            assignments?: number | null;
            scans?: number | null;
            aiV?: number | null;
            history?: Array<{
                __typename?: "UserAIStatsHistory";
                periodEnd?: number | null;
                prompts?: number | null;
                tokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
            } | null> | null;
        } | null;
        profileColor?: string | null;
        privacyAck?: boolean | null;
        blogger?: boolean | null;
        examHistory?: string | null;
        usernameChange?: number | null;
        signInType?: SignInType | null;
        invites?: number | null;
        classes?: {
            __typename?: "ClassInfo";
            enrolled: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
            teacher: Array<string>;
            invited: Array<{
                __typename?: "ClassEnrollment";
                teacherId: string;
                classId: string;
                role: ClassRole;
                sections: Array<string>;
            }>;
        } | null;
        migKey?: string | null;
        signInTypes?: Array<SignInType> | null;
        orgPlanType?: SubscriptionType | null;
        orgPlanCreated?: number | null;
        lastOrgPlanUpdated?: number | null;
        favTools?: Array<string> | null;
        lastStreakDate?: string | null;
        xpSyncDate?: string | null;
        xp?: number | null;
        level?: number | null;
        streak?: number | null;
        longPauseEnd?: string | null;
        coins?: number | null;
        featuredBadges?: Array<string> | null;
        records?: {
            __typename?: "GamifyRecords";
            xpEarned: string;
            longestStreak: string;
            badgesEarned: string;
            coinsEarned: string;
        } | null;
        inventory?: {
            __typename?: "GamifyInventory";
            freezes: number;
            revivals: number;
            pauses: number;
            icon: StoreProductEnum;
            features: Array<StoreProductEnum>;
            gamifySubExpiry?: number | null;
            gamifySubStoreProduct?: StoreProductEnum | null;
        } | null;
        gameBlock?: string | null;
        lastAdWatch?: string | null;
        lastTimeZoneChange?: number | null;
        notifSettings?: {
            __typename?: "NotificationSettings";
            emailSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            pushSettings: {
                __typename?: "BaseNotificationSettings";
                disabled: Array<NotificationCategory>;
                tokens?: Array<string> | null;
                reminderTime?: string | null;
            };
            gamifySettings?: {
                __typename?: "GamificationSettings";
                level: boolean;
                streak: boolean;
                badges: boolean;
            } | null;
        } | null;
        stoppedNotifications?: boolean | null;
        inactiveUser?: boolean | null;
        promoCodes?: Array<{
            __typename?: "PromoCode";
            code: string;
            category: string;
        }> | null;
        sentPromotions?: string | null;
        autocompleteSettings?: {
            __typename?: "AutocompleteSettings";
            language?: Language | null;
            disabled?: boolean | null;
            btnDisabled?: boolean | null;
            speed?: number | null;
            length?: string | null;
            grade?: string | null;
            format?: string | null;
            style?: string | null;
        } | null;
        orgRequests?: Array<UpgradeEvent> | null;
        orgReqTime?: string | null;
        emailsSent?: number | null;
        lastSent?: number | null;
        isSidebarExpanded?: boolean | null;
    };
};

export type GetCurrentUserAndOrganizationQueryVariables = {
    input?: GetCurrentUserInput | null;
};

export type GetCurrentUserAndOrganizationQuery = {
    getCurrentUserAndOrganization: {
        __typename?: "UserAndOrganization";
        user?: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        } | null;
        organization?: {
            __typename?: "Organization";
            org: string;
            schoolId: string;
            created: string;
            updated: string;
            name?: string | null;
            pictureUrl?: string | null;
            country?: string | null;
            state?: string | null;
            files?: Array<{
                __typename?: "OrganizationFile";
                url: string;
                name: string;
            }> | null;
            students: number;
            teachers: number;
            syncTimestamp?: number | null;
            isDirty?: boolean | null;
            paywall?: {
                __typename?: "OrganizationPaywallInfo";
                count: number;
                lastSent: number;
            } | null;
            aliases?: Array<string> | null;
            stage: OrganizationStage;
            referrals: Array<{
                __typename?: "OrganizationReferral";
                userId: string;
                created: string;
                sent: boolean;
                type: string;
                email: string;
            }>;
            dpa?: {
                __typename?: "OrganizationDPA";
                name?: string | null;
                email?: string | null;
                link?: string | null;
                type: DPAType;
            } | null;
            admins?: Array<{
                __typename?: "OrganizationAdmin";
                userId: string;
                schoolId: string;
            }> | null;
            pendingAdmins?: Array<{
                __typename?: "PendingOrganizationAdmin";
                Email: string;
                schoolId: string;
            }> | null;
            settings?: {
                __typename?: "OrganizationSettings";
                schoolId?: string | null;
                sharing: OrganizationSharing;
                subsAllowed: boolean;
                ai?: boolean | null;
            } | null;
            schoolsSettings?: Array<{
                __typename?: "OrganizationSettings";
                schoolId?: string | null;
                sharing: OrganizationSharing;
                subsAllowed: boolean;
                ai?: boolean | null;
            }> | null;
            verifiedSchools?: boolean | null;
            subscriptions?: Array<{
                __typename?: "OrganizationStripeConnection";
                schoolId: string;
                created: number;
                current_period_end: number;
                studentPlan: SubscriptionType;
                teacherPlan: SubscriptionType;
                studentsPaidFor: number;
                teachersPaidFor: number;
                paidStudents: number;
                paidTeachers: number;
                autoSubsStudent: boolean;
                autoSubsTeacher: boolean;
            }> | null;
            customerId?: string | null;
            provider?: EdLinkProvider | null;
            elDistrictId?: string | null;
            elIntegrationId?: string | null;
        } | null;
    };
};

export type ListBookmarkByUserIdQueryVariables = {
    input: ListBookmarkByUserIdInput;
};

export type ListBookmarkByUserIdQuery = {
    listBookmarkByUserId?: {
        __typename?: "BookmarkConnection";
        items: Array<{
            __typename?: "Bookmark";
            userId: string;
            ID: string;
            type: ItemType;
            time?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type IsBookmarkedQueryVariables = {
    input: IsBookmarkInput;
};

export type IsBookmarkedQuery = {
    isBookmarked?: boolean | null;
};

export type IsUsernameAvailableQueryVariables = {
    input: IsUsernameAvailableInput;
};

export type IsUsernameAvailableQuery = {
    isUsernameAvailable?: boolean | null;
};

export type ESQueryFullQueryVariables = {
    input: ESQueryFullInput;
};

export type ESQueryFullQuery = {
    ESQueryFull?: {
        __typename?: "ESResult";
        result: string;
    } | null;
};

export type ListFoldersByUserQueryVariables = {
    input: ListFoldersByUserInput;
};

export type ListFoldersByUserQuery = {
    listFoldersByUser?: {
        __typename?: "FolderConnection";
        items: Array<{
            __typename?: "Folder";
            userId: string;
            folderId: string;
            parentId?: string | null;
            name?: string | null;
            color?: string | null;
            description?: string | null;
            created?: string | null;
            updated?: string | null;
            notesCount?: number | null;
            trash?: boolean | null;
            public?: boolean | null;
            password?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFoldersByParentQueryVariables = {
    input: ListFoldersByParentInput;
};

export type ListFoldersByParentQuery = {
    listFoldersByParent?: {
        __typename?: "FolderConnection";
        items: Array<{
            __typename?: "Folder";
            userId: string;
            folderId: string;
            parentId?: string | null;
            name?: string | null;
            color?: string | null;
            description?: string | null;
            created?: string | null;
            updated?: string | null;
            notesCount?: number | null;
            trash?: boolean | null;
            public?: boolean | null;
            password?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListNotesByFolderQueryVariables = {
    input: ListNotesByFolderInput;
};

export type ListNotesByFolderQuery = {
    listNotesByFolder?: {
        __typename?: "ModelNoteConnection";
        items: Array<{
            __typename?: "Note";
            userId: string;
            noteId: string;
            content?: string | null;
            hash?: string | null;
            public?: boolean | null;
            draft?: boolean | null;
            password?: string | null;
            summary?: string | null;
            title?: string | null;
            trash?: boolean | null;
            created?: string | null;
            updated?: string | null;
            noteCode?: string | null;
            file?: string | null;
            folderId?: string | null;
            flashcardSetId?: string | null;
            mediaId?: string | null;
            importType?: ImportType | null;
            subject?: string | null;
            views?: number | null;
            verified?: boolean | null;
            classPublic?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            lock?: boolean | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            isAI?: boolean | null;
            aiTool?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            neverPublic?: boolean | null;
            deadline?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFlashcardSetsByFolderQueryVariables = {
    input: ListFlashcardSetsByFolderInput;
};

export type ListFlashcardSetsByFolderQuery = {
    listFlashcardSetsByFolder?: {
        __typename?: "FlashcardSetConnection";
        items: Array<{
            __typename?: "FlashcardSet";
            userId: string;
            flashcardSetId: string;
            sort?: number | null;
            noteId?: string | null;
            mediaId?: string | null;
            position?: number | null;
            title?: string | null;
            description?: string | null;
            public?: boolean | null;
            password?: string | null;
            trash?: boolean | null;
            draft?: boolean | null;
            starred?: Array<string | null> | null;
            size?: number | null;
            studyFrom?: FlashcardStudyFrom | null;
            created?: number | null;
            updated?: number | null;
            quizletUrl?: string | null;
            answerSide?: FlashcardSide | null;
            shuffled?: boolean | null;
            flashcards?: Array<{
                __typename?: "Flashcard";
                flashcardId: string;
                flashcardSetId?: string | null;
                userId?: string | null;
                term?: string | null;
                termAudio?: string | null;
                definition?: string | null;
                definitionAudio?: string | null;
                distractorIds?: Array<string | null> | null;
                questionType?: QuestionType | null;
                isCorrect?: boolean | null;
                draft?: boolean | null;
                trash?: boolean | null;
                disabled?: boolean | null;
                image?: string | null;
                secondaryImage?: string | null;
                edited?: boolean | null;
                created?: string | null;
                updated?: string | null;
                twoSided?: boolean | null;
                schedule?: string | null;
                quality?: number | null;
                termRecordedAudio?: string | null;
                definitionRecordedAudio?: string | null;
                mcOptions?: Array<string | null> | null;
                termCustomHint?: string | null;
                definitionCustomHint?: string | null;
            } | null> | null;
            termLanguage?: Language | null;
            definitionLanguage?: Language | null;
            relatedNotes?: Array<string | null> | null;
            order?: Array<number | null> | null;
            flashcardSetCode?: string | null;
            views?: number | null;
            classPublic?: boolean | null;
            subject?: string | null;
            verified?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            folderId?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            lock?: boolean | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            studySettings?: {
                __typename?: "FlashcardStudySettings";
                blockedStudyModes: Array<StudySessionType>;
                settings: {
                    __typename?: "FlashcardsSetLockedStudySettings";
                    LEARN?: {
                        __typename?: "LearnLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                        length?: number | null;
                        override?: boolean | null;
                    } | null;
                    TEST?: {
                        __typename?: "TestLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        length: number;
                    } | null;
                    SPACED?: {
                        __typename?: "SpacedLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        cardsPerSession: number;
                        intervals?: Array<number> | null;
                    } | null;
                    MATCH?: {
                        __typename?: "MatchLockedSettings";
                        locked: Array<string>;
                        grid: boolean;
                        starred: boolean;
                        length: number;
                    } | null;
                    REVIEW?: {
                        __typename?: "ReviewLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        position?: number | null;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                    } | null;
                };
            } | null;
            isAI?: boolean | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            deadline?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListMediaByFolderQueryVariables = {
    input: ListMediaByFolderInput;
};

export type ListMediaByFolderQuery = {
    listMediaByFolder?: {
        __typename?: "MediaConnection";
        items: Array<{
            __typename?: "Media";
            userId: string;
            mediaId: string;
            created: string;
            updated: string;
            title?: string | null;
            description?: string | null;
            password?: string | null;
            public: boolean;
            noteId?: string | null;
            flashcardSetId?: string | null;
            folderId?: string | null;
            trash?: boolean | null;
            type: MediaType;
            fileType: FileType;
            pages?: number | null;
            length?: number | null;
            bucket: string;
            embedUrl?: string | null;
            embedCommands?: Array<string | null> | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            verified?: boolean | null;
            transcript?: TranscriptEnum | null;
            schoolId?: string | null;
            grade?: string | null;
            views?: number | null;
            jwt?: string | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            isAI?: boolean | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetFolderQueryVariables = {
    input: GetFolderInput;
};

export type GetFolderQuery = {
    getFolder?: {
        __typename?: "Folder";
        userId: string;
        folderId: string;
        parentId?: string | null;
        name?: string | null;
        color?: string | null;
        description?: string | null;
        created?: string | null;
        updated?: string | null;
        notesCount?: number | null;
        trash?: boolean | null;
        public?: boolean | null;
        password?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        classId?: string | null;
        addedAt?: string | null;
        sections?: Array<string> | null;
        partial?: boolean | null;
    } | null;
};

export type GetRatingQueryVariables = {
    input: GetRatingInput;
};

export type GetRatingQuery = {
    getRating?: {
        __typename?: "Rating";
        noteId: string;
        userId: string;
        noteOwnerId: string;
        message?: string | null;
        rating?: number | null;
        type?: ItemType | null;
    } | null;
};

export type GetUserRatingStatsQueryVariables = {
    input: GetUserRatingStatsInput;
};

export type GetUserRatingStatsQuery = {
    getUserRatingStats?: {
        __typename?: "RatingStats";
        count?: number | null;
        rating?: number | null;
    } | null;
};

export type GetNoteRatingStatsQueryVariables = {
    input: GetNoteRatingStatsInput;
};

export type GetNoteRatingStatsQuery = {
    getNoteRatingStats?: {
        __typename?: "RatingStats";
        count?: number | null;
        rating?: number | null;
    } | null;
};

export type GetFlashcardSetViewerQueryVariables = {
    input: GetFlashcardSetViewerInput;
};

export type GetFlashcardSetViewerQuery = {
    getFlashcardSetViewer?: {
        __typename?: "FlashcardSetViewer";
        userId: string;
        flashcardSetId: string;
        position?: number | null;
        starred?: Array<string | null> | null;
        length?: number | null;
        studyFrom?: FlashcardStudyFrom | null;
        studiedFlashcards?: Array<string | null> | null;
        order?: Array<string | null> | null;
        shuffled?: boolean | null;
        lastViewedFlashcard?: string | null;
        answerSide?: FlashcardSide | null;
        reviewMode?: FlashcardReviewMode | null;
    } | null;
};

export type GetQuizletFlashcardsQueryVariables = {
    input: QuizletParseInput;
};

export type GetQuizletFlashcardsQuery = {
    getQuizletFlashcards?: {
        __typename?: "QuizletFlashcards";
        data?: string | null;
        title?: string | null;
    } | null;
};

export type RequestAICompletionQueryVariables = {
    input: AICompletionInput;
};

export type RequestAICompletionQuery = {
    requestAICompletion?: {
        __typename?: "AICompletion";
        userId: string;
        timestamp?: string | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        flashcardId?: string | null;
        itemId?: string | null;
        itemType?: ItemType | null;
        id?: string | null;
        input?: string | null;
        messages?: Array<{
            __typename?: "AIMessage";
            role?: string | null;
            content?: string | null;
        } | null> | null;
        output: string;
        inputTokens?: number | null;
        outputTokens?: number | null;
        subject?: string | null;
        topic?: string | null;
        type?: AICompletionType | null;
        timeTaken?: number | null;
        params?: string | null;
    } | null;
};

export type RequestAICompletionV2QueryVariables = {
    input: AICompletionInput;
};

export type RequestAICompletionV2Query = {
    requestAICompletionV2?: {
        __typename?: "AICompletionContext";
        item: {
            __typename?: "AICompletion";
            userId: string;
            timestamp?: string | null;
            noteId?: string | null;
            flashcardSetId?: string | null;
            flashcardId?: string | null;
            itemId?: string | null;
            itemType?: ItemType | null;
            id?: string | null;
            input?: string | null;
            messages?: Array<{
                __typename?: "AIMessage";
                role?: string | null;
                content?: string | null;
            } | null> | null;
            output: string;
            inputTokens?: number | null;
            outputTokens?: number | null;
            subject?: string | null;
            topic?: string | null;
            type?: AICompletionType | null;
            timeTaken?: number | null;
            params?: string | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type GetExamQueryVariables = {
    input: GetExamInput;
};

export type GetExamQuery = {
    getExam?: {
        __typename?: "Exam";
        type: string;
        name: string;
        description?: string | null;
        unitLabel: string;
        sectionLabel: string;
        baseLink: string;
        image: string;
        category?: string | null;
        color?: string | null;
        date?: string | null;
        time?: number | null;
        index?: boolean | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        discord?: Array<{
            __typename?: "ExamResourceType";
            name: string;
            link?: string | null;
            image?: string | null;
        } | null> | null;
        youtube?: Array<{
            __typename?: "ExamResourceType";
            name: string;
            link?: string | null;
            image?: string | null;
        } | null> | null;
        units?: Array<{
            __typename?: "ExamUnit";
            name: string;
            image?: string | null;
            noteId?: string | null;
            flashcardSetId?: string | null;
            sections?: Array<string | null> | null;
        } | null> | null;
        crams?: Array<{
            __typename?: "ExamUnit";
            name: string;
            image?: string | null;
            noteId?: string | null;
            flashcardSetId?: string | null;
            sections?: Array<string | null> | null;
        } | null> | null;
    } | null;
};

export type GetExamMCQQueryVariables = {
    input: GetExamMCQInput;
};

export type GetExamMCQQuery = {
    getExamMCQ?: {
        __typename?: "ExamMCQ";
        exam: string;
        questionId: string;
        difficulty: ExamDifficulty;
        choices: Array<string>;
        question: string;
        reasons: Array<string>;
        rating: number;
        ratingCount: number;
        examUnit: string;
        examSection?: string | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        subject?: string | null;
        topic?: string | null;
        created: string;
        updated: string;
        url?: string | null;
        prompt: ExamPromptType;
        numCorrect: number;
        numQuestions: number;
        stimuli?: Array<string | null> | null;
        stimulusGroupId?: string | null;
        questionGroupId?: string | null;
        replaced?: boolean | null;
        calc?: number | null;
    } | null;
};

export type GetExamFRQQueryVariables = {
    input: GetExamFRQInput;
};

export type GetExamFRQQuery = {
    getExamFRQ?: {
        __typename?: "FullExamFRQ";
        exam: string;
        questionId: string;
        difficulty: ExamDifficulty;
        question: string;
        rating: number;
        ratingCount: number;
        examUnit: string;
        examSection?: string | null;
        noteId?: string | null;
        flashcardSetId?: string | null;
        subject?: string | null;
        topic?: string | null;
        created: string;
        updated: string;
        url?: string | null;
        prompt: ExamPromptType;
        stimuli?: Array<{
            __typename?: "ExamStimulus";
            questionId: string;
            stimulusId: string;
            content: string;
            stimulusType: ExamStimulusType;
            schema?: string | null;
        }> | null;
        name: string;
        parts: Array<{
            __typename?: "FullExamFRQPart";
            question: string;
            rating: number;
            ratingCount: number;
            stimuli?: Array<{
                __typename?: "ExamStimulus";
                questionId: string;
                stimulusId: string;
                content: string;
                stimulusType: ExamStimulusType;
                schema?: string | null;
            }> | null;
            prefill?: string | null;
        }>;
        rubric: Array<{
            __typename?: "ExamFRQRubricParts";
            items: Array<{
                __typename?: "ExamFRQRubricItems";
                guidelines: string;
                points: number;
            }>;
        }>;
        starred: boolean;
        calc?: number | null;
        frqType?: ExamFRQType | null;
    } | null;
};

export type GetSubjectQueryVariables = {
    input: GetSubjectInput;
};

export type GetSubjectQuery = {
    getSubject?: {
        __typename?: "Subject";
        subject?: string | null;
        picture?: string | null;
        color?: string | null;
        category?: string | null;
        order?: number | null;
        topics?: string | null;
    } | null;
};

export type GetBlogQueryVariables = {
    input: GetBlogInput;
};

export type GetBlogQuery = {
    getBlog?: {
        __typename?: "Blog";
        userId: string;
        blogId: string;
        title: string;
        slug: string;
        content: string;
        summary?: string | null;
        created: string;
        updated: string;
        tags?: string | null;
        coverImage?: {
            __typename?: "BlogCoverImage";
            url?: string | null;
            width?: number | null;
            height?: number | null;
        } | null;
        metadata?: {
            __typename?: "BlogSEOMetadata";
            title?: string | null;
            description?: string | null;
        } | null;
        draft?: boolean | null;
        trash?: boolean | null;
        type?: string | null;
        pinned?: number | null;
    } | null;
};

export type GetBlogByIdQueryVariables = {
    input: GetBlogByIdInput;
};

export type GetBlogByIdQuery = {
    getBlogById?: {
        __typename?: "Blog";
        userId: string;
        blogId: string;
        title: string;
        slug: string;
        content: string;
        summary?: string | null;
        created: string;
        updated: string;
        tags?: string | null;
        coverImage?: {
            __typename?: "BlogCoverImage";
            url?: string | null;
            width?: number | null;
            height?: number | null;
        } | null;
        metadata?: {
            __typename?: "BlogSEOMetadata";
            title?: string | null;
            description?: string | null;
        } | null;
        draft?: boolean | null;
        trash?: boolean | null;
        type?: string | null;
        pinned?: number | null;
    } | null;
};

export type GetRecommendedBlogsQueryVariables = {
    input: GetRecommendedBlogInput;
};

export type GetRecommendedBlogsQuery = {
    getRecommendedBlogs?: {
        __typename?: "BlogConnection";
        items: Array<{
            __typename?: "Blog";
            userId: string;
            blogId: string;
            title: string;
            slug: string;
            content: string;
            summary?: string | null;
            created: string;
            updated: string;
            tags?: string | null;
            coverImage?: {
                __typename?: "BlogCoverImage";
                url?: string | null;
                width?: number | null;
                height?: number | null;
            } | null;
            metadata?: {
                __typename?: "BlogSEOMetadata";
                title?: string | null;
                description?: string | null;
            } | null;
            draft?: boolean | null;
            trash?: boolean | null;
            type?: string | null;
            pinned?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListBlogsQueryVariables = {
    input: ListBlogsInput;
};

export type ListBlogsQuery = {
    listBlogs?: {
        __typename?: "BlogConnection";
        items: Array<{
            __typename?: "Blog";
            userId: string;
            blogId: string;
            title: string;
            slug: string;
            content: string;
            summary?: string | null;
            created: string;
            updated: string;
            tags?: string | null;
            coverImage?: {
                __typename?: "BlogCoverImage";
                url?: string | null;
                width?: number | null;
                height?: number | null;
            } | null;
            metadata?: {
                __typename?: "BlogSEOMetadata";
                title?: string | null;
                description?: string | null;
            } | null;
            draft?: boolean | null;
            trash?: boolean | null;
            type?: string | null;
            pinned?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListBlogsByUserQueryVariables = {
    input: ListBlogsByUserInput;
};

export type ListBlogsByUserQuery = {
    listBlogsByUser?: {
        __typename?: "BlogConnection";
        items: Array<{
            __typename?: "Blog";
            userId: string;
            blogId: string;
            title: string;
            slug: string;
            content: string;
            summary?: string | null;
            created: string;
            updated: string;
            tags?: string | null;
            coverImage?: {
                __typename?: "BlogCoverImage";
                url?: string | null;
                width?: number | null;
                height?: number | null;
            } | null;
            metadata?: {
                __typename?: "BlogSEOMetadata";
                title?: string | null;
                description?: string | null;
            } | null;
            draft?: boolean | null;
            trash?: boolean | null;
            type?: string | null;
            pinned?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetStudySessionQueryVariables = {
    input: GetStudySessionInput;
};

export type GetStudySessionQuery = {
    getStudySession?: {
        __typename?: "StudySession";
        userId: string;
        itemId: string;
        type: ItemType;
        created: string;
        updated: string;
        examDate?: string | null;
        unmigrated?: boolean | null;
        newCards: number;
        nextDue: string;
        nextNewDue: string;
        settings: {
            __typename?: "StudySessionSettings";
            LEARN?: {
                __typename?: "LearnStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                reType: boolean;
                length?: number | null;
                override?: boolean | null;
            } | null;
            TEST?: {
                __typename?: "TestStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                length: number;
            } | null;
            SPACED?: {
                __typename?: "SpacedStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                cardsPerSession: number;
                intervals?: Array<number> | null;
                override?: boolean | null;
            } | null;
            MATCH?: {
                __typename?: "MatchStudySessionSetting";
                grid: boolean;
                starred: boolean;
                length: number;
            } | null;
            REVIEW?: {
                __typename?: "ReviewStudySessionSetting";
                questionTypes: Array<QuestionType>;
                answerSide: FlashcardSide;
                starred: boolean;
                shuffled: boolean;
                fuzzy: boolean;
                reType: boolean;
                sorting?: boolean | null;
            } | null;
        };
        progress: {
            __typename?: "StudySessionProgress";
            NEW: number;
            LEARNING: number;
            REVIEWING: number;
            MASTERED: number;
        };
        practiceTests?: Array<{
            __typename?: "PracticeTestProgress";
            timeTaken: number;
            timestamp: number;
            score: string;
        }> | null;
        syncTime: string;
        lastView?: string | null;
        title?: string | null;
    } | null;
};

export type ListStudySessionsQueryVariables = {
    input: ListStudySessionsInput;
};

export type ListStudySessionsQuery = {
    listStudySessions?: {
        __typename?: "StudySessionConnection";
        items: Array<{
            __typename?: "StudySession";
            userId: string;
            itemId: string;
            type: ItemType;
            created: string;
            updated: string;
            examDate?: string | null;
            unmigrated?: boolean | null;
            newCards: number;
            nextDue: string;
            nextNewDue: string;
            settings: {
                __typename?: "StudySessionSettings";
                LEARN?: {
                    __typename?: "LearnStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                    override?: boolean | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchStudySessionSetting";
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    sorting?: boolean | null;
                } | null;
            };
            progress: {
                __typename?: "StudySessionProgress";
                NEW: number;
                LEARNING: number;
                REVIEWING: number;
                MASTERED: number;
            };
            practiceTests?: Array<{
                __typename?: "PracticeTestProgress";
                timeTaken: number;
                timestamp: number;
                score: string;
            }> | null;
            syncTime: string;
            lastView?: string | null;
            title?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListRecentStudySessionsQueryVariables = {
    input: ListRecentStudySessionsInput;
};

export type ListRecentStudySessionsQuery = {
    listRecentStudySessions?: {
        __typename?: "StudySessionConnection";
        items: Array<{
            __typename?: "StudySession";
            userId: string;
            itemId: string;
            type: ItemType;
            created: string;
            updated: string;
            examDate?: string | null;
            unmigrated?: boolean | null;
            newCards: number;
            nextDue: string;
            nextNewDue: string;
            settings: {
                __typename?: "StudySessionSettings";
                LEARN?: {
                    __typename?: "LearnStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                    override?: boolean | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchStudySessionSetting";
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    sorting?: boolean | null;
                } | null;
            };
            progress: {
                __typename?: "StudySessionProgress";
                NEW: number;
                LEARNING: number;
                REVIEWING: number;
                MASTERED: number;
            };
            practiceTests?: Array<{
                __typename?: "PracticeTestProgress";
                timeTaken: number;
                timestamp: number;
                score: string;
            }> | null;
            syncTime: string;
            lastView?: string | null;
            title?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFlashcardStudyStatesQueryVariables = {
    input: ListFlashcardStudyStatesInput;
};

export type ListFlashcardStudyStatesQuery = {
    listFlashcardStudyStates?: {
        __typename?: "FlashcardStudyStateConnection";
        items: Array<{
            __typename?: "FlashcardStudyState";
            userId: string;
            flashcardId: string;
            flashcardSetId: string;
            created: string;
            updated: string;
            distractors: Array<string>;
            mastery: number;
            lastStudied: string;
            blank?: boolean | null;
            sort?: FlashcardSortingEnum | null;
            nextDue: string;
            difficulty?: number | null;
            interval: number;
            eFactor: number;
            progress: StudySessionProgressEnum;
            history: Array<{
                __typename?: "FlashcardStudyStateHistory";
                timestamp: number;
                timeTaken: number;
                mode: StudySessionType;
                questionType: QuestionType;
                selectedFlashcardId?: string | null;
                side: FlashcardSide;
                button?: SpacedRepetitionButton | null;
                isCorrect: boolean;
                answer: string;
            }>;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListItemAIChatsQueryVariables = {
    input: ListItemAIChatsInput;
};

export type ListItemAIChatsQuery = {
    listItemAIChats?: {
        __typename?: "AICompletionConnection";
        items: Array<{
            __typename?: "AICompletion";
            userId: string;
            timestamp?: string | null;
            noteId?: string | null;
            flashcardSetId?: string | null;
            flashcardId?: string | null;
            itemId?: string | null;
            itemType?: ItemType | null;
            id?: string | null;
            input?: string | null;
            messages?: Array<{
                __typename?: "AIMessage";
                role?: string | null;
                content?: string | null;
            } | null> | null;
            output: string;
            inputTokens?: number | null;
            outputTokens?: number | null;
            subject?: string | null;
            topic?: string | null;
            type?: AICompletionType | null;
            timeTaken?: number | null;
            params?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetClassQueryVariables = {
    input: GetClassInput;
};

export type GetClassQuery = {
    getClass?: {
        __typename?: "Class";
        userId: string;
        classId: string;
        sections: Array<{
            __typename?: "ClassSection";
            name: string;
            id: string;
            color: string;
            timings?: Array<{
                __typename?: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            } | null> | null;
        }>;
        members: Array<{
            __typename?: "ClassMemberWithDetails";
            userId: string;
            role: ClassRole;
            sections: Array<string>;
            pending?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            dpa?: boolean | null;
            lastLogIn?: number | null;
        }>;
        memberCount?: number | null;
        pinned: Array<string>;
        created: string;
        updated: string;
        name: string;
        description?: string | null;
        timings?: Array<{
            __typename?: "ClassTimings";
            start?: string | null;
            end?: string | null;
            days?: Array<string | null> | null;
        }> | null;
        timezone?: string | null;
        org?: string | null;
        schoolId?: string | null;
        grade?: string | null;
        subject?: string | null;
        topic?: string | null;
        exam_v2?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
        icon?: {
            __typename?: "EmojiIcon";
            emoji?: string | null;
            skinTone?: string | null;
        } | null;
        color: string;
        cover?: string | null;
        googleClassId?: string | null;
        fileCount?: number | null;
        partial?: boolean | null;
        flashcardStudySettings?: {
            __typename?: "FlashcardStudySettings";
            blockedStudyModes: Array<StudySessionType>;
            settings: {
                __typename?: "FlashcardsSetLockedStudySettings";
                LEARN?: {
                    __typename?: "LearnLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchLockedSettings";
                    locked: Array<string>;
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewLockedSettings";
                    locked: Array<string>;
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    position?: number | null;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                } | null;
            };
        } | null;
    } | null;
};

export type ListClassesQueryVariables = {
    input: ListClassesInput;
};

export type ListClassesQuery = {
    listClasses?: {
        __typename?: "ClassConnection";
        items: Array<{
            __typename?: "Class";
            userId: string;
            classId: string;
            sections: Array<{
                __typename?: "ClassSection";
                name: string;
                id: string;
                color: string;
                timings?: Array<{
                    __typename?: "ClassTimings";
                    start?: string | null;
                    end?: string | null;
                    days?: Array<string | null> | null;
                } | null> | null;
            }>;
            members: Array<{
                __typename?: "ClassMemberWithDetails";
                userId: string;
                role: ClassRole;
                sections: Array<string>;
                pending?: boolean | null;
                Name?: string | null;
                pictureUrl?: string | null;
                username?: string | null;
                dpa?: boolean | null;
                lastLogIn?: number | null;
            }>;
            memberCount?: number | null;
            pinned: Array<string>;
            created: string;
            updated: string;
            name: string;
            description?: string | null;
            timings?: Array<{
                __typename?: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            }> | null;
            timezone?: string | null;
            org?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            color: string;
            cover?: string | null;
            googleClassId?: string | null;
            fileCount?: number | null;
            partial?: boolean | null;
            flashcardStudySettings?: {
                __typename?: "FlashcardStudySettings";
                blockedStudyModes: Array<StudySessionType>;
                settings: {
                    __typename?: "FlashcardsSetLockedStudySettings";
                    LEARN?: {
                        __typename?: "LearnLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                        length?: number | null;
                        override?: boolean | null;
                    } | null;
                    TEST?: {
                        __typename?: "TestLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        length: number;
                    } | null;
                    SPACED?: {
                        __typename?: "SpacedLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        cardsPerSession: number;
                        intervals?: Array<number> | null;
                    } | null;
                    MATCH?: {
                        __typename?: "MatchLockedSettings";
                        locked: Array<string>;
                        grid: boolean;
                        starred: boolean;
                        length: number;
                    } | null;
                    REVIEW?: {
                        __typename?: "ReviewLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        position?: number | null;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                    } | null;
                };
            } | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListClassStudySessionsQueryVariables = {
    input: ListClassStudySessionsInput;
};

export type ListClassStudySessionsQuery = {
    listClassStudySessions?: {
        __typename?: "StudySessionConnection";
        items: Array<{
            __typename?: "StudySession";
            userId: string;
            itemId: string;
            type: ItemType;
            created: string;
            updated: string;
            examDate?: string | null;
            unmigrated?: boolean | null;
            newCards: number;
            nextDue: string;
            nextNewDue: string;
            settings: {
                __typename?: "StudySessionSettings";
                LEARN?: {
                    __typename?: "LearnStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    length?: number | null;
                    override?: boolean | null;
                } | null;
                TEST?: {
                    __typename?: "TestStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    length: number;
                } | null;
                SPACED?: {
                    __typename?: "SpacedStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    cardsPerSession: number;
                    intervals?: Array<number> | null;
                    override?: boolean | null;
                } | null;
                MATCH?: {
                    __typename?: "MatchStudySessionSetting";
                    grid: boolean;
                    starred: boolean;
                    length: number;
                } | null;
                REVIEW?: {
                    __typename?: "ReviewStudySessionSetting";
                    questionTypes: Array<QuestionType>;
                    answerSide: FlashcardSide;
                    starred: boolean;
                    shuffled: boolean;
                    fuzzy: boolean;
                    reType: boolean;
                    sorting?: boolean | null;
                } | null;
            };
            progress: {
                __typename?: "StudySessionProgress";
                NEW: number;
                LEARNING: number;
                REVIEWING: number;
                MASTERED: number;
            };
            practiceTests?: Array<{
                __typename?: "PracticeTestProgress";
                timeTaken: number;
                timestamp: number;
                score: string;
            }> | null;
            syncTime: string;
            lastView?: string | null;
            title?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListClassFlashcardStudyStatesQueryVariables = {
    input: ListClassFlashcardStudyStatesInput;
};

export type ListClassFlashcardStudyStatesQuery = {
    listClassFlashcardStudyStates?: {
        __typename?: "FlashcardStudyStateConnection";
        items: Array<{
            __typename?: "FlashcardStudyState";
            userId: string;
            flashcardId: string;
            flashcardSetId: string;
            created: string;
            updated: string;
            distractors: Array<string>;
            mastery: number;
            lastStudied: string;
            blank?: boolean | null;
            sort?: FlashcardSortingEnum | null;
            nextDue: string;
            difficulty?: number | null;
            interval: number;
            eFactor: number;
            progress: StudySessionProgressEnum;
            history: Array<{
                __typename?: "FlashcardStudyStateHistory";
                timestamp: number;
                timeTaken: number;
                mode: StudySessionType;
                questionType: QuestionType;
                selectedFlashcardId?: string | null;
                side: FlashcardSide;
                button?: SpacedRepetitionButton | null;
                isCorrect: boolean;
                answer: string;
            }>;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListNotesByClassQueryVariables = {
    input: ListNotesByClassInput;
};

export type ListNotesByClassQuery = {
    listNotesByClass?: {
        __typename?: "ModelNoteConnection";
        items: Array<{
            __typename?: "Note";
            userId: string;
            noteId: string;
            content?: string | null;
            hash?: string | null;
            public?: boolean | null;
            draft?: boolean | null;
            password?: string | null;
            summary?: string | null;
            title?: string | null;
            trash?: boolean | null;
            created?: string | null;
            updated?: string | null;
            noteCode?: string | null;
            file?: string | null;
            folderId?: string | null;
            flashcardSetId?: string | null;
            mediaId?: string | null;
            importType?: ImportType | null;
            subject?: string | null;
            views?: number | null;
            verified?: boolean | null;
            classPublic?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            lock?: boolean | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            isAI?: boolean | null;
            aiTool?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            neverPublic?: boolean | null;
            deadline?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFlashcardSetsByClassQueryVariables = {
    input: ListFlashcardSetsByClassInput;
};

export type ListFlashcardSetsByClassQuery = {
    listFlashcardSetsByClass?: {
        __typename?: "FlashcardSetConnection";
        items: Array<{
            __typename?: "FlashcardSet";
            userId: string;
            flashcardSetId: string;
            sort?: number | null;
            noteId?: string | null;
            mediaId?: string | null;
            position?: number | null;
            title?: string | null;
            description?: string | null;
            public?: boolean | null;
            password?: string | null;
            trash?: boolean | null;
            draft?: boolean | null;
            starred?: Array<string | null> | null;
            size?: number | null;
            studyFrom?: FlashcardStudyFrom | null;
            created?: number | null;
            updated?: number | null;
            quizletUrl?: string | null;
            answerSide?: FlashcardSide | null;
            shuffled?: boolean | null;
            flashcards?: Array<{
                __typename?: "Flashcard";
                flashcardId: string;
                flashcardSetId?: string | null;
                userId?: string | null;
                term?: string | null;
                termAudio?: string | null;
                definition?: string | null;
                definitionAudio?: string | null;
                distractorIds?: Array<string | null> | null;
                questionType?: QuestionType | null;
                isCorrect?: boolean | null;
                draft?: boolean | null;
                trash?: boolean | null;
                disabled?: boolean | null;
                image?: string | null;
                secondaryImage?: string | null;
                edited?: boolean | null;
                created?: string | null;
                updated?: string | null;
                twoSided?: boolean | null;
                schedule?: string | null;
                quality?: number | null;
                termRecordedAudio?: string | null;
                definitionRecordedAudio?: string | null;
                mcOptions?: Array<string | null> | null;
                termCustomHint?: string | null;
                definitionCustomHint?: string | null;
            } | null> | null;
            termLanguage?: Language | null;
            definitionLanguage?: Language | null;
            relatedNotes?: Array<string | null> | null;
            order?: Array<number | null> | null;
            flashcardSetCode?: string | null;
            views?: number | null;
            classPublic?: boolean | null;
            subject?: string | null;
            verified?: boolean | null;
            schoolId?: string | null;
            grade?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            topic?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            folderId?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            lock?: boolean | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            studySettings?: {
                __typename?: "FlashcardStudySettings";
                blockedStudyModes: Array<StudySessionType>;
                settings: {
                    __typename?: "FlashcardsSetLockedStudySettings";
                    LEARN?: {
                        __typename?: "LearnLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                        length?: number | null;
                        override?: boolean | null;
                    } | null;
                    TEST?: {
                        __typename?: "TestLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        length: number;
                    } | null;
                    SPACED?: {
                        __typename?: "SpacedLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        cardsPerSession: number;
                        intervals?: Array<number> | null;
                    } | null;
                    MATCH?: {
                        __typename?: "MatchLockedSettings";
                        locked: Array<string>;
                        grid: boolean;
                        starred: boolean;
                        length: number;
                    } | null;
                    REVIEW?: {
                        __typename?: "ReviewLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        position?: number | null;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                    } | null;
                };
            } | null;
            isAI?: boolean | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            deadline?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListMediasByClassQueryVariables = {
    input: ListMediasByClassInput;
};

export type ListMediasByClassQuery = {
    listMediasByClass?: {
        __typename?: "MediaConnection";
        items: Array<{
            __typename?: "Media";
            userId: string;
            mediaId: string;
            created: string;
            updated: string;
            title?: string | null;
            description?: string | null;
            password?: string | null;
            public: boolean;
            noteId?: string | null;
            flashcardSetId?: string | null;
            folderId?: string | null;
            trash?: boolean | null;
            type: MediaType;
            fileType: FileType;
            pages?: number | null;
            length?: number | null;
            bucket: string;
            embedUrl?: string | null;
            embedCommands?: Array<string | null> | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            tags?: Array<string | null> | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            rating?: number | null;
            ratingCount?: number | null;
            verified?: boolean | null;
            transcript?: TranscriptEnum | null;
            schoolId?: string | null;
            grade?: string | null;
            views?: number | null;
            jwt?: string | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
            xpTracked?: string | null;
            isAI?: boolean | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListFoldersByClassQueryVariables = {
    input: ListFoldersByClassInput;
};

export type ListFoldersByClassQuery = {
    listFoldersByClass?: {
        __typename?: "FolderConnection";
        items: Array<{
            __typename?: "Folder";
            userId: string;
            folderId: string;
            parentId?: string | null;
            name?: string | null;
            color?: string | null;
            description?: string | null;
            created?: string | null;
            updated?: string | null;
            notesCount?: number | null;
            trash?: boolean | null;
            public?: boolean | null;
            password?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListAllAccessibleFoldersQueryVariables = {};

export type ListAllAccessibleFoldersQuery = {
    listAllAccessibleFolders?: {
        __typename?: "FolderConnection";
        items: Array<{
            __typename?: "Folder";
            userId: string;
            folderId: string;
            parentId?: string | null;
            name?: string | null;
            color?: string | null;
            description?: string | null;
            created?: string | null;
            updated?: string | null;
            notesCount?: number | null;
            trash?: boolean | null;
            public?: boolean | null;
            password?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            classId?: string | null;
            addedAt?: string | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type AdminListOrgMembersQueryVariables = {
    input: AdminListOrgInput;
};

export type AdminListOrgMembersQuery = {
    adminListOrgMembers?: {
        __typename?: "OrgUserDetailsConnection";
        items: Array<{
            __typename?: "OrgUserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            birthday?: string | null;
            created?: number | null;
            lastLogIn?: number | null;
            ref_v2?: string | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            schoolId?: string | null;
            grade?: string | null;
            subscriptionType?: SubscriptionType | null;
            isTrialing?: boolean | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            classCount?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type AdminListOrgClassesQueryVariables = {
    input: AdminListOrgInput;
};

export type AdminListOrgClassesQuery = {
    adminListOrgClasses?: {
        __typename?: "ClassConnection";
        items: Array<{
            __typename?: "Class";
            userId: string;
            classId: string;
            sections: Array<{
                __typename?: "ClassSection";
                name: string;
                id: string;
                color: string;
                timings?: Array<{
                    __typename?: "ClassTimings";
                    start?: string | null;
                    end?: string | null;
                    days?: Array<string | null> | null;
                } | null> | null;
            }>;
            members: Array<{
                __typename?: "ClassMemberWithDetails";
                userId: string;
                role: ClassRole;
                sections: Array<string>;
                pending?: boolean | null;
                Name?: string | null;
                pictureUrl?: string | null;
                username?: string | null;
                dpa?: boolean | null;
                lastLogIn?: number | null;
            }>;
            memberCount?: number | null;
            pinned: Array<string>;
            created: string;
            updated: string;
            name: string;
            description?: string | null;
            timings?: Array<{
                __typename?: "ClassTimings";
                start?: string | null;
                end?: string | null;
                days?: Array<string | null> | null;
            }> | null;
            timezone?: string | null;
            org?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            subject?: string | null;
            topic?: string | null;
            exam_v2?: string | null;
            examUnit?: string | null;
            examSection?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            color: string;
            cover?: string | null;
            googleClassId?: string | null;
            fileCount?: number | null;
            partial?: boolean | null;
            flashcardStudySettings?: {
                __typename?: "FlashcardStudySettings";
                blockedStudyModes: Array<StudySessionType>;
                settings: {
                    __typename?: "FlashcardsSetLockedStudySettings";
                    LEARN?: {
                        __typename?: "LearnLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                        length?: number | null;
                        override?: boolean | null;
                    } | null;
                    TEST?: {
                        __typename?: "TestLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        length: number;
                    } | null;
                    SPACED?: {
                        __typename?: "SpacedLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        shuffled: boolean;
                        fuzzy: boolean;
                        cardsPerSession: number;
                        intervals?: Array<number> | null;
                    } | null;
                    MATCH?: {
                        __typename?: "MatchLockedSettings";
                        locked: Array<string>;
                        grid: boolean;
                        starred: boolean;
                        length: number;
                    } | null;
                    REVIEW?: {
                        __typename?: "ReviewLockedSettings";
                        locked: Array<string>;
                        questionTypes: Array<QuestionType>;
                        answerSide: FlashcardSide;
                        starred: boolean;
                        position?: number | null;
                        shuffled: boolean;
                        fuzzy: boolean;
                        reType: boolean;
                    } | null;
                };
            } | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetOrganizationQueryVariables = {
    input: GetOrganizationInput;
};

export type GetOrganizationQuery = {
    getOrganization?: {
        __typename?: "Organization";
        org: string;
        schoolId: string;
        created: string;
        updated: string;
        name?: string | null;
        pictureUrl?: string | null;
        country?: string | null;
        state?: string | null;
        files?: Array<{
            __typename?: "OrganizationFile";
            url: string;
            name: string;
        }> | null;
        students: number;
        teachers: number;
        syncTimestamp?: number | null;
        isDirty?: boolean | null;
        paywall?: {
            __typename?: "OrganizationPaywallInfo";
            count: number;
            lastSent: number;
        } | null;
        aliases?: Array<string> | null;
        stage: OrganizationStage;
        referrals: Array<{
            __typename?: "OrganizationReferral";
            userId: string;
            created: string;
            sent: boolean;
            type: string;
            email: string;
        }>;
        dpa?: {
            __typename?: "OrganizationDPA";
            name?: string | null;
            email?: string | null;
            link?: string | null;
            type: DPAType;
        } | null;
        admins?: Array<{
            __typename?: "OrganizationAdmin";
            userId: string;
            schoolId: string;
        }> | null;
        pendingAdmins?: Array<{
            __typename?: "PendingOrganizationAdmin";
            Email: string;
            schoolId: string;
        }> | null;
        settings?: {
            __typename?: "OrganizationSettings";
            schoolId?: string | null;
            sharing: OrganizationSharing;
            subsAllowed: boolean;
            ai?: boolean | null;
        } | null;
        schoolsSettings?: Array<{
            __typename?: "OrganizationSettings";
            schoolId?: string | null;
            sharing: OrganizationSharing;
            subsAllowed: boolean;
            ai?: boolean | null;
        }> | null;
        verifiedSchools?: boolean | null;
        subscriptions?: Array<{
            __typename?: "OrganizationStripeConnection";
            schoolId: string;
            created: number;
            current_period_end: number;
            studentPlan: SubscriptionType;
            teacherPlan: SubscriptionType;
            studentsPaidFor: number;
            teachersPaidFor: number;
            paidStudents: number;
            paidTeachers: number;
            autoSubsStudent: boolean;
            autoSubsTeacher: boolean;
        }> | null;
        customerId?: string | null;
        provider?: EdLinkProvider | null;
        elDistrictId?: string | null;
        elIntegrationId?: string | null;
    } | null;
};

export type ListAllOrganizationsQueryVariables = {};

export type ListAllOrganizationsQuery = {
    listAllOrganizations?: {
        __typename?: "OrganizationConnection";
        items: Array<{
            __typename?: "Organization";
            org: string;
            schoolId: string;
            created: string;
            updated: string;
            name?: string | null;
            pictureUrl?: string | null;
            country?: string | null;
            state?: string | null;
            files?: Array<{
                __typename?: "OrganizationFile";
                url: string;
                name: string;
            }> | null;
            students: number;
            teachers: number;
            syncTimestamp?: number | null;
            isDirty?: boolean | null;
            paywall?: {
                __typename?: "OrganizationPaywallInfo";
                count: number;
                lastSent: number;
            } | null;
            aliases?: Array<string> | null;
            stage: OrganizationStage;
            referrals: Array<{
                __typename?: "OrganizationReferral";
                userId: string;
                created: string;
                sent: boolean;
                type: string;
                email: string;
            }>;
            dpa?: {
                __typename?: "OrganizationDPA";
                name?: string | null;
                email?: string | null;
                link?: string | null;
                type: DPAType;
            } | null;
            admins?: Array<{
                __typename?: "OrganizationAdmin";
                userId: string;
                schoolId: string;
            }> | null;
            pendingAdmins?: Array<{
                __typename?: "PendingOrganizationAdmin";
                Email: string;
                schoolId: string;
            }> | null;
            settings?: {
                __typename?: "OrganizationSettings";
                schoolId?: string | null;
                sharing: OrganizationSharing;
                subsAllowed: boolean;
                ai?: boolean | null;
            } | null;
            schoolsSettings?: Array<{
                __typename?: "OrganizationSettings";
                schoolId?: string | null;
                sharing: OrganizationSharing;
                subsAllowed: boolean;
                ai?: boolean | null;
            }> | null;
            verifiedSchools?: boolean | null;
            subscriptions?: Array<{
                __typename?: "OrganizationStripeConnection";
                schoolId: string;
                created: number;
                current_period_end: number;
                studentPlan: SubscriptionType;
                teacherPlan: SubscriptionType;
                studentsPaidFor: number;
                teachersPaidFor: number;
                paidStudents: number;
                paidTeachers: number;
                autoSubsStudent: boolean;
                autoSubsTeacher: boolean;
            }> | null;
            customerId?: string | null;
            provider?: EdLinkProvider | null;
            elDistrictId?: string | null;
            elIntegrationId?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListStoreProductMetadataQueryVariables = {
    input?: ListStoreProductMetadataInput | null;
};

export type ListStoreProductMetadataQuery = {
    listStoreProductMetadata?: {
        __typename?: "StoreProductMetadataConnection";
        items: Array<{
            __typename?: "StoreProductMetadata";
            category: StoreCategoryEnum;
            storeProductId: StoreProductEnum;
            name: string;
            description: string;
            link?: string | null;
            feature: boolean;
            price: number;
            discount?: number | null;
            max: number;
            total: number;
            group?: StoreGroupEnum | null;
            color: string;
            planType?: SubscriptionType | null;
        }>;
    } | null;
};

export type ListStoreProductHistoryQueryVariables = {
    input?: ListStoreProductHistoryInput | null;
};

export type ListStoreProductHistoryQuery = {
    listStoreProductHistory?: {
        __typename?: "StoreProductHistoryConnection";
        items: Array<{
            __typename?: "StoreProductHistory";
            userId: string;
            storeProductId: StoreProductEnum;
            current: number;
            bought: number;
            price?: number | null;
            code?: string | null;
            discount?: number | null;
            expiryTime?: number | null;
        }>;
    } | null;
};

export type ListBadgeProgressQueryVariables = {
    input: ListBadgeProgressInput;
};

export type ListBadgeProgressQuery = {
    listBadgeProgress?: {
        __typename?: "BadgeProgressConnection";
        items: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
    } | null;
};

export type ListDailyActivityQueryVariables = {
    input: ListDailyActivityInput;
};

export type ListDailyActivityQuery = {
    listDailyActivity?: {
        __typename?: "DailyActivityConnection";
        items: Array<{
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListBadgesMetadataQueryVariables = {
    input?: ListBadgesMetadataInput | null;
};

export type ListBadgesMetadataQuery = {
    listBadgesMetadata?: {
        __typename?: "BadgeMetadataConnection";
        items: Array<{
            __typename?: "BadgeMetadata";
            group: BadgeGroupEnum;
            badge: BadgeEnum;
            name: string;
            description: string;
            success: string;
            failure: string;
            tiers: Array<{
                __typename?: "BadgeTier";
                xpReward: number;
                coinReward: number;
                required: number;
            }>;
        }>;
    } | null;
};

export type GetUserByRefV2QueryVariables = {
    input: GetUserByRefV2Input;
};

export type GetUserByRefV2Query = {
    getUserByRefV2?: {
        __typename?: "OrgUserDetails";
        Email: string;
        org?: string | null;
        accountType?: AccountType | null;
        ID?: string | null;
        elID?: string | null;
        clID?: string | null;
        isSSOChecked?: boolean | null;
        Name?: string | null;
        pictureUrl?: string | null;
        username?: string | null;
        birthday?: string | null;
        created?: number | null;
        lastLogIn?: number | null;
        ref_v2?: string | null;
        numFollowers?: number | null;
        numFollowing?: number | null;
        schoolId?: string | null;
        grade?: string | null;
        subscriptionType?: SubscriptionType | null;
        isTrialing?: boolean | null;
        signInTypes?: Array<SignInType> | null;
        orgPlanType?: SubscriptionType | null;
        orgPlanCreated?: number | null;
        lastOrgPlanUpdated?: number | null;
        orgRequests?: Array<UpgradeEvent> | null;
        orgReqTime?: string | null;
        classCount?: number | null;
    } | null;
};

export type GetIntercomUserHashQueryVariables = {
    input?: getIntercomUserHashInput | null;
};

export type GetIntercomUserHashQuery = {
    getIntercomUserHash?: string | null;
};

export type GetAssignmentQueryVariables = {
    input: GetAssignmentInput;
};

export type GetAssignmentQuery = {
    getAssignment?: {
        __typename?: "AssignmentContext";
        item: {
            __typename?: "Assignment";
            userId: string;
            assignmentId: string;
            classId?: string | null;
            addedAt?: string | null;
            config?: string | null;
            files?: Array<{
                __typename?: "AssignmentFile";
                itemId: string;
                itemType: ItemType;
            }> | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
            status: AssignmentStatus;
            deadline?: number | null;
            type: AssignmentType;
            settings: {
                __typename?: "AssignmentSettings";
                timeLimit?: number | null;
                standards?: Array<string> | null;
                grade: string;
                maxSubmissions?: number | null;
                language: Language;
                inputMethods: Array<AssignmentMethodEnum>;
                outputMethods: Array<AssignmentMethodEnum>;
                autoGrade: boolean;
                rubric: {
                    __typename?: "AssignmentRubric";
                    A?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    B?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    C?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    D?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    F?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                };
                helpfulness: HelpfulnessEnum;
                length?: AssignmentLengthEnum | null;
                priorKnowledge: Array<string>;
                rules: Array<string>;
                suggestions: Array<string>;
                initialPrompt: string;
            };
            created: string;
            updated: string;
            feedback?: {
                __typename?: "AssignmentFeedback";
                strength: string;
                improvement: string;
                followUp: string;
                comments?: string | null;
            } | null;
            title: string;
            description: string;
            instructions: string;
            image?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            public: boolean;
            password?: string | null;
            trash?: boolean | null;
            folderId?: string | null;
            subject?: string | null;
            topic?: string | null;
            numCompleted: number;
            views?: number | null;
        };
        user: {
            __typename?: "UserDetails";
            Email: string;
            org?: string | null;
            accountType?: AccountType | null;
            ID?: string | null;
            elID?: string | null;
            clID?: string | null;
            isSSOChecked?: boolean | null;
            timeZone?: string | null;
            referral?: string | null;
            Name?: string | null;
            pictureUrl?: string | null;
            username?: string | null;
            views?: number | null;
            birthday?: string | null;
            phone?: string | null;
            hideSensitiveInfo?: boolean | null;
            noteSidebarWidth?: number | null;
            created?: number | null;
            lastLogIn?: number | null;
            lastNotified?: number | null;
            answerSide?: FlashcardSide | null;
            numFollowers?: number | null;
            numFollowing?: number | null;
            ref_v2?: string | null;
            refFrom_v2?: string | null;
            refList_v2?: Array<string> | null;
            verified?: boolean | null;
            bio?: string | null;
            socials?: {
                __typename?: "Socials";
                ig?: string | null;
                twitter?: string | null;
                tiktok?: string | null;
                in?: string | null;
                link?: string | null;
                spotify?: string | null;
            } | null;
            cover?: string | null;
            schoolId?: string | null;
            grade?: string | null;
            rating?: number | null;
            ratingCount?: number | null;
            customerId?: string | null;
            subscriptions?: Array<{
                __typename?: "StripeConnection";
                subscriptionId: string;
                customerId: string;
                priceId: string;
                productId: string;
                created?: number | null;
                current_period_end?: number | null;
                cancel_at_period_end?: boolean | null;
                resumes_at?: number | null;
                interval?: string | null;
                status?: string | null;
                platform?: PlatformEnum | null;
            }> | null;
            alerts?: string | null;
            subscriptionType?: SubscriptionType | null;
            stripeVerifyTime?: number | null;
            stripeTrialTime?: number | null;
            cancelPromoTime?: number | null;
            deleteReq?: boolean | null;
            ai?: {
                __typename?: "UserAIStats";
                curPrompts?: number | null;
                curPeriodEnd?: number | null;
                curTokens?: number | null;
                videos?: number | null;
                vNotes?: number | null;
                vSets?: number | null;
                mins?: number | null;
                pdfs?: number | null;
                pNotes?: number | null;
                pSets?: number | null;
                pages?: number | null;
                nTests?: number | null;
                nSets?: number | null;
                chats?: number | null;
                explain?: number | null;
                frq?: number | null;
                cost?: number | null;
                mSets?: number | null;
                mNotes?: number | null;
                tools?: number | null;
                assignments?: number | null;
                scans?: number | null;
                aiV?: number | null;
                history?: Array<{
                    __typename?: "UserAIStatsHistory";
                    periodEnd?: number | null;
                    prompts?: number | null;
                    tokens?: number | null;
                    videos?: number | null;
                    vNotes?: number | null;
                    vSets?: number | null;
                    mins?: number | null;
                    pdfs?: number | null;
                    pNotes?: number | null;
                    pSets?: number | null;
                    pages?: number | null;
                    nTests?: number | null;
                    nSets?: number | null;
                    chats?: number | null;
                    explain?: number | null;
                    frq?: number | null;
                    cost?: number | null;
                    mSets?: number | null;
                    mNotes?: number | null;
                    tools?: number | null;
                    assignments?: number | null;
                    scans?: number | null;
                    aiV?: number | null;
                } | null> | null;
            } | null;
            profileColor?: string | null;
            privacyAck?: boolean | null;
            blogger?: boolean | null;
            examHistory?: string | null;
            usernameChange?: number | null;
            signInType?: SignInType | null;
            invites?: number | null;
            classes?: {
                __typename?: "ClassInfo";
                enrolled: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
                teacher: Array<string>;
                invited: Array<{
                    __typename?: "ClassEnrollment";
                    teacherId: string;
                    classId: string;
                    role: ClassRole;
                    sections: Array<string>;
                }>;
            } | null;
            migKey?: string | null;
            signInTypes?: Array<SignInType> | null;
            orgPlanType?: SubscriptionType | null;
            orgPlanCreated?: number | null;
            lastOrgPlanUpdated?: number | null;
            favTools?: Array<string> | null;
            lastStreakDate?: string | null;
            xpSyncDate?: string | null;
            xp?: number | null;
            level?: number | null;
            streak?: number | null;
            longPauseEnd?: string | null;
            coins?: number | null;
            featuredBadges?: Array<string> | null;
            records?: {
                __typename?: "GamifyRecords";
                xpEarned: string;
                longestStreak: string;
                badgesEarned: string;
                coinsEarned: string;
            } | null;
            inventory?: {
                __typename?: "GamifyInventory";
                freezes: number;
                revivals: number;
                pauses: number;
                icon: StoreProductEnum;
                features: Array<StoreProductEnum>;
                gamifySubExpiry?: number | null;
                gamifySubStoreProduct?: StoreProductEnum | null;
            } | null;
            gameBlock?: string | null;
            lastAdWatch?: string | null;
            lastTimeZoneChange?: number | null;
            notifSettings?: {
                __typename?: "NotificationSettings";
                emailSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                pushSettings: {
                    __typename?: "BaseNotificationSettings";
                    disabled: Array<NotificationCategory>;
                    tokens?: Array<string> | null;
                    reminderTime?: string | null;
                };
                gamifySettings?: {
                    __typename?: "GamificationSettings";
                    level: boolean;
                    streak: boolean;
                    badges: boolean;
                } | null;
            } | null;
            stoppedNotifications?: boolean | null;
            inactiveUser?: boolean | null;
            promoCodes?: Array<{
                __typename?: "PromoCode";
                code: string;
                category: string;
            }> | null;
            sentPromotions?: string | null;
            autocompleteSettings?: {
                __typename?: "AutocompleteSettings";
                language?: Language | null;
                disabled?: boolean | null;
                btnDisabled?: boolean | null;
                speed?: number | null;
                length?: string | null;
                grade?: string | null;
                format?: string | null;
                style?: string | null;
            } | null;
            orgRequests?: Array<UpgradeEvent> | null;
            orgReqTime?: string | null;
            emailsSent?: number | null;
            lastSent?: number | null;
            isSidebarExpanded?: boolean | null;
        };
        badges: Array<{
            __typename?: "BadgeProgress";
            userId: string;
            badge: BadgeEnum;
            current: number;
            claimed: Array<number>;
            unclaimed: Array<number>;
            earnedAt: Array<string>;
            newlyEarned?: boolean | null;
        }>;
        dailyActivity: {
            __typename?: "DailyActivity";
            userId: string;
            activityDate: string;
            xp: number;
            items: Array<{
                __typename?: "DailyActivityItem";
                itemId: string;
                xp: number;
                type: ItemType;
            }>;
            streakAction?: ActionEnum | null;
            badgesEarned: number;
            coinsEarned: number;
            asyncActions: Array<string>;
            actionMap?: string | null;
        };
        level?: number | null;
    } | null;
};

export type GetAssignmentSessionQueryVariables = {
    input: GetAssignmentSessionInput;
};

export type GetAssignmentSessionQuery = {
    getAssignmentSession?: {
        __typename?: "AssignmentSessionAndMessages";
        session: {
            __typename?: "AssignmentSession";
            assignmentId: string;
            sessionId: string;
            userId: string;
            status: AssignmentStatus;
            created: string;
            updated: string;
            submitted?: string | null;
            duration?: number | null;
            score?: number | null;
            rubric?: Array<string> | null;
            feedback?: {
                __typename?: "AssignmentFeedback";
                strength: string;
                improvement: string;
                followUp: string;
                comments?: string | null;
            } | null;
        };
        messages: Array<{
            __typename?: "AssignmentMessage";
            sessionId: string;
            messageId: string;
            created: string;
            content: string;
            contentFlag?: ContentFlagEnum | null;
            sender: Sender;
        }>;
    } | null;
};

export type ListAssignmentsQueryVariables = {
    input: ListAssignmentsInput;
};

export type ListAssignmentsQuery = {
    listAssignments?: {
        __typename?: "AssignmentConnection";
        items: Array<{
            __typename?: "Assignment";
            userId: string;
            assignmentId: string;
            classId?: string | null;
            addedAt?: string | null;
            config?: string | null;
            files?: Array<{
                __typename?: "AssignmentFile";
                itemId: string;
                itemType: ItemType;
            }> | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
            status: AssignmentStatus;
            deadline?: number | null;
            type: AssignmentType;
            settings: {
                __typename?: "AssignmentSettings";
                timeLimit?: number | null;
                standards?: Array<string> | null;
                grade: string;
                maxSubmissions?: number | null;
                language: Language;
                inputMethods: Array<AssignmentMethodEnum>;
                outputMethods: Array<AssignmentMethodEnum>;
                autoGrade: boolean;
                rubric: {
                    __typename?: "AssignmentRubric";
                    A?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    B?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    C?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    D?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    F?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                };
                helpfulness: HelpfulnessEnum;
                length?: AssignmentLengthEnum | null;
                priorKnowledge: Array<string>;
                rules: Array<string>;
                suggestions: Array<string>;
                initialPrompt: string;
            };
            created: string;
            updated: string;
            feedback?: {
                __typename?: "AssignmentFeedback";
                strength: string;
                improvement: string;
                followUp: string;
                comments?: string | null;
            } | null;
            title: string;
            description: string;
            instructions: string;
            image?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            public: boolean;
            password?: string | null;
            trash?: boolean | null;
            folderId?: string | null;
            subject?: string | null;
            topic?: string | null;
            numCompleted: number;
            views?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListAssignmentsByFolderQueryVariables = {
    input: ListAssignmentsByFolderInput;
};

export type ListAssignmentsByFolderQuery = {
    listAssignmentsByFolder?: {
        __typename?: "AssignmentConnection";
        items: Array<{
            __typename?: "Assignment";
            userId: string;
            assignmentId: string;
            classId?: string | null;
            addedAt?: string | null;
            config?: string | null;
            files?: Array<{
                __typename?: "AssignmentFile";
                itemId: string;
                itemType: ItemType;
            }> | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
            status: AssignmentStatus;
            deadline?: number | null;
            type: AssignmentType;
            settings: {
                __typename?: "AssignmentSettings";
                timeLimit?: number | null;
                standards?: Array<string> | null;
                grade: string;
                maxSubmissions?: number | null;
                language: Language;
                inputMethods: Array<AssignmentMethodEnum>;
                outputMethods: Array<AssignmentMethodEnum>;
                autoGrade: boolean;
                rubric: {
                    __typename?: "AssignmentRubric";
                    A?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    B?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    C?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    D?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    F?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                };
                helpfulness: HelpfulnessEnum;
                length?: AssignmentLengthEnum | null;
                priorKnowledge: Array<string>;
                rules: Array<string>;
                suggestions: Array<string>;
                initialPrompt: string;
            };
            created: string;
            updated: string;
            feedback?: {
                __typename?: "AssignmentFeedback";
                strength: string;
                improvement: string;
                followUp: string;
                comments?: string | null;
            } | null;
            title: string;
            description: string;
            instructions: string;
            image?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            public: boolean;
            password?: string | null;
            trash?: boolean | null;
            folderId?: string | null;
            subject?: string | null;
            topic?: string | null;
            numCompleted: number;
            views?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListAssignmentsByClassQueryVariables = {
    input: ListAssignmentsByClassInput;
};

export type ListAssignmentsByClassQuery = {
    listAssignmentsByClass?: {
        __typename?: "AssignmentConnection";
        items: Array<{
            __typename?: "Assignment";
            userId: string;
            assignmentId: string;
            classId?: string | null;
            addedAt?: string | null;
            config?: string | null;
            files?: Array<{
                __typename?: "AssignmentFile";
                itemId: string;
                itemType: ItemType;
            }> | null;
            sections?: Array<string> | null;
            partial?: boolean | null;
            status: AssignmentStatus;
            deadline?: number | null;
            type: AssignmentType;
            settings: {
                __typename?: "AssignmentSettings";
                timeLimit?: number | null;
                standards?: Array<string> | null;
                grade: string;
                maxSubmissions?: number | null;
                language: Language;
                inputMethods: Array<AssignmentMethodEnum>;
                outputMethods: Array<AssignmentMethodEnum>;
                autoGrade: boolean;
                rubric: {
                    __typename?: "AssignmentRubric";
                    A?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    B?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    C?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    D?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                    F?: {
                        __typename?: "AssignmentRubricGrade";
                        score?: number | null;
                        condition?: string | null;
                    } | null;
                };
                helpfulness: HelpfulnessEnum;
                length?: AssignmentLengthEnum | null;
                priorKnowledge: Array<string>;
                rules: Array<string>;
                suggestions: Array<string>;
                initialPrompt: string;
            };
            created: string;
            updated: string;
            feedback?: {
                __typename?: "AssignmentFeedback";
                strength: string;
                improvement: string;
                followUp: string;
                comments?: string | null;
            } | null;
            title: string;
            description: string;
            instructions: string;
            image?: string | null;
            icon?: {
                __typename?: "EmojiIcon";
                emoji?: string | null;
                skinTone?: string | null;
            } | null;
            public: boolean;
            password?: string | null;
            trash?: boolean | null;
            folderId?: string | null;
            subject?: string | null;
            topic?: string | null;
            numCompleted: number;
            views?: number | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListAssignmentSessionsQueryVariables = {
    input: ListAssignmentSessionsInput;
};

export type ListAssignmentSessionsQuery = {
    listAssignmentSessions?: {
        __typename?: "AssignmentSessionConnection";
        items: Array<{
            __typename?: "AssignmentSession";
            assignmentId: string;
            sessionId: string;
            userId: string;
            status: AssignmentStatus;
            created: string;
            updated: string;
            submitted?: string | null;
            duration?: number | null;
            score?: number | null;
            rubric?: Array<string> | null;
            feedback?: {
                __typename?: "AssignmentFeedback";
                strength: string;
                improvement: string;
                followUp: string;
                comments?: string | null;
            } | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListAssignmentSessionsByUserQueryVariables = {
    input: ListAssignmentSessionsByUserInput;
};

export type ListAssignmentSessionsByUserQuery = {
    listAssignmentSessionsByUser?: {
        __typename?: "AssignmentSessionConnection";
        items: Array<{
            __typename?: "AssignmentSession";
            assignmentId: string;
            sessionId: string;
            userId: string;
            status: AssignmentStatus;
            created: string;
            updated: string;
            submitted?: string | null;
            duration?: number | null;
            score?: number | null;
            rubric?: Array<string> | null;
            feedback?: {
                __typename?: "AssignmentFeedback";
                strength: string;
                improvement: string;
                followUp: string;
                comments?: string | null;
            } | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListAssignmentMessagesQueryVariables = {
    input: ListAssignmentMessagesInput;
};

export type ListAssignmentMessagesQuery = {
    listAssignmentMessages?: {
        __typename?: "AssignmentMessageConnection";
        items: Array<{
            __typename?: "AssignmentMessage";
            sessionId: string;
            messageId: string;
            created: string;
            content: string;
            contentFlag?: ContentFlagEnum | null;
            sender: Sender;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListScanHistoryQueryVariables = {
    input?: ListScanHistoryInput | null;
};

export type ListScanHistoryQuery = {
    listScanHistory?: {
        __typename?: "ScanHistoryConnection";
        items: Array<{
            __typename?: "ScanHistory";
            userId: string;
            scanId: string;
            created: string;
            updated: string;
            subject?: string | null;
            topic?: string | null;
            context?: string | null;
            imageDescription?: string | null;
            relatedTopics?: Array<string | null> | null;
            questions: Array<{
                __typename?: "ScanHistoryQuestion";
                text: string;
                type: QuestionTypeEnum;
                choices: Array<string>;
                answer: {
                    __typename?: "ScanHistoryAnswer";
                    text: string;
                    steps: Array<string>;
                };
            }>;
            pinned?: boolean | null;
            summary?: string | null;
            videoResult?: string | null;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetScanHistoryQueryVariables = {
    input: GetScanHistoryInput;
};

export type GetScanHistoryQuery = {
    getScanHistory: {
        __typename?: "ScanHistory";
        userId: string;
        scanId: string;
        created: string;
        updated: string;
        subject?: string | null;
        topic?: string | null;
        context?: string | null;
        imageDescription?: string | null;
        relatedTopics?: Array<string | null> | null;
        questions: Array<{
            __typename?: "ScanHistoryQuestion";
            text: string;
            type: QuestionTypeEnum;
            choices: Array<string>;
            answer: {
                __typename?: "ScanHistoryAnswer";
                text: string;
                steps: Array<string>;
            };
        }>;
        pinned?: boolean | null;
        summary?: string | null;
        videoResult?: string | null;
    };
};

export type ListExamHistoryQueryVariables = {
    input?: ListExamHistoryInput | null;
};

export type ListExamHistoryQuery = {
    listExamHistory?: {
        __typename?: "ExamHistoryConnection";
        items: Array<{
            __typename?: "ExamHistory";
            userId: string;
            historyId: string;
            exam: string;
            type: ExamTypeEnum;
            sections: Array<{
                __typename?: "ExamHistorySection";
                name: string;
                time: number;
                sectionPoints: number;
                sectionTotal: number;
                breakdown: Array<{
                    __typename?: "ExamHistorySectionBreakdown";
                    name: string;
                    type: ExamTypeEnum;
                    points: number;
                    total: number;
                    mcqResult?: {
                        __typename?: "ExamHistoryMCQResult";
                        items?: Array<{
                            __typename?: "ExamHistoryMCQResultItems";
                            questionId: string;
                            selectedAnswer?: number | null;
                            isCorrect?: boolean | null;
                        }> | null;
                    } | null;
                    frqResult?: {
                        __typename?: "ExamHistoryFRQResult";
                        items?: Array<{
                            __typename?: "ExamHistoryFRQResultItems";
                            questionId: string;
                            partAnswers?: Array<{
                                __typename?: "ExamHistoryFRQPartAnswers";
                                answer: string;
                                earnedScore?: number | null;
                                possibleScore?: number | null;
                                feedback?: string | null;
                                attachments?: Array<{
                                    __typename?: "Attachment";
                                    url: string;
                                    parsed?: string | null;
                                    type: MediaType;
                                }> | null;
                            }> | null;
                        }> | null;
                    } | null;
                }>;
            }>;
            mcqMetrics?: Array<string> | null;
            frqMetrics?: Array<string> | null;
            score: number;
            created: string;
            updated: string;
        }>;
        nextToken?: string | null;
    } | null;
};

export type ListExamHistoryByExamQueryVariables = {
    input: ListExamHistoryByExamInput;
};

export type ListExamHistoryByExamQuery = {
    listExamHistoryByExam?: {
        __typename?: "ExamHistoryConnection";
        items: Array<{
            __typename?: "ExamHistory";
            userId: string;
            historyId: string;
            exam: string;
            type: ExamTypeEnum;
            sections: Array<{
                __typename?: "ExamHistorySection";
                name: string;
                time: number;
                sectionPoints: number;
                sectionTotal: number;
                breakdown: Array<{
                    __typename?: "ExamHistorySectionBreakdown";
                    name: string;
                    type: ExamTypeEnum;
                    points: number;
                    total: number;
                    mcqResult?: {
                        __typename?: "ExamHistoryMCQResult";
                        items?: Array<{
                            __typename?: "ExamHistoryMCQResultItems";
                            questionId: string;
                            selectedAnswer?: number | null;
                            isCorrect?: boolean | null;
                        }> | null;
                    } | null;
                    frqResult?: {
                        __typename?: "ExamHistoryFRQResult";
                        items?: Array<{
                            __typename?: "ExamHistoryFRQResultItems";
                            questionId: string;
                            partAnswers?: Array<{
                                __typename?: "ExamHistoryFRQPartAnswers";
                                answer: string;
                                earnedScore?: number | null;
                                possibleScore?: number | null;
                                feedback?: string | null;
                                attachments?: Array<{
                                    __typename?: "Attachment";
                                    url: string;
                                    parsed?: string | null;
                                    type: MediaType;
                                }> | null;
                            }> | null;
                        }> | null;
                    } | null;
                }>;
            }>;
            mcqMetrics?: Array<string> | null;
            frqMetrics?: Array<string> | null;
            score: number;
            created: string;
            updated: string;
        }>;
        nextToken?: string | null;
    } | null;
};

export type GetExamHistoryQueryVariables = {
    input: GetExamHistoryInput;
};

export type GetExamHistoryQuery = {
    getExamHistory?: {
        __typename?: "ExamHistory";
        userId: string;
        historyId: string;
        exam: string;
        type: ExamTypeEnum;
        sections: Array<{
            __typename?: "ExamHistorySection";
            name: string;
            time: number;
            sectionPoints: number;
            sectionTotal: number;
            breakdown: Array<{
                __typename?: "ExamHistorySectionBreakdown";
                name: string;
                type: ExamTypeEnum;
                points: number;
                total: number;
                mcqResult?: {
                    __typename?: "ExamHistoryMCQResult";
                    items?: Array<{
                        __typename?: "ExamHistoryMCQResultItems";
                        questionId: string;
                        selectedAnswer?: number | null;
                        isCorrect?: boolean | null;
                    }> | null;
                } | null;
                frqResult?: {
                    __typename?: "ExamHistoryFRQResult";
                    items?: Array<{
                        __typename?: "ExamHistoryFRQResultItems";
                        questionId: string;
                        partAnswers?: Array<{
                            __typename?: "ExamHistoryFRQPartAnswers";
                            answer: string;
                            earnedScore?: number | null;
                            possibleScore?: number | null;
                            feedback?: string | null;
                            attachments?: Array<{
                                __typename?: "Attachment";
                                url: string;
                                parsed?: string | null;
                                type: MediaType;
                            }> | null;
                        }> | null;
                    }> | null;
                } | null;
            }>;
        }>;
        mcqMetrics?: Array<string> | null;
        frqMetrics?: Array<string> | null;
        score: number;
        created: string;
        updated: string;
    } | null;
};
